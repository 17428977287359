@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$asset-detail-pagination-height: 60px;

.asset-detail-view {
  @media screen and (min-width: $bp-lg) {
    max-height: calc(100vh - #{($modal-inner-padding * 2)} - #{($modal-dialog-margin * 2)});
  }

  .asset-container {
    align-items: center;
    background-color: $background-gray-light-1;
    display: flex;
    justify-content: center;
    max-height: inherit;

    .asset {
      height: auto;
      max-height: inherit;
      max-width: 100%;
      width: auto;
    }
  }

  .asset-info {
    background-color: white;
    max-height: inherit;
    padding-bottom: $asset-detail-pagination-height;
    position: relative;

    .info-container {
      max-height: 100%;
      overflow-y: auto;
    }

    .info-section {
      border-top: 1px solid $border-color-light;
      padding-bottom: $spacer;
      padding-top: $spacer;

      @include gutter-sizing([ 'padding-left', 'padding-right' ]);

      &:first-of-type {
        border-top: 0;
      }

      &.info-header {
        @include gutter-sizing('padding-bottom');
      }
    }
  }

  .breadcrumb-item {
    color: $primary;

    a {
      opacity: 1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .asset-actions {
    display: flex;
    justify-content: flex-start;

    .btn {
      margin-bottom: $spacer / 2;
    }
  }

  .asset-detail-pagination {
    background-color: inherit;
    border-top: 1px solid $border-color-light;
    bottom: 0;
    height: $asset-detail-pagination-height;
    position: absolute;
    width: 100%;

    .pagination {
      display: flex;
      width: 100%;
    }

    .page-item {
      align-items: center;
      display: flex;
      font-weight: $font-weight-light;
      height: $asset-detail-pagination-height;
      justify-content: center;
    }

    .current-position {
      align-self: stretch;
      flex: 100%;
      text-align: center;
    }

    .page-link {
      border: 0;
      color: $text-color;
      font-size: rem(20);
      height: $asset-detail-pagination-height;
      padding: 0;
      width: $asset-detail-pagination-height;

      &[aria-label='Next'] {
        border-left: 1px solid $border-color-light;
      }

      &[aria-label='Previous'] {
        border-right: 1px solid $border-color-light;
      }
    }
  }
}

.modal .asset-detail-modal {
  .modal-body {
    padding: 0;
  }
}
