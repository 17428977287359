.daily-summary {
  @include gutter-sizing([ 'margin-left', 'margin-right' ], -1);
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  overflow-x: auto;
  overflow-y: visible;

  &:after { // Add space to the right when scrolling
    @include gutter-sizing(['margin-left'], 0.9);
    color: transparent;
    content: '\00A0';
    display: block;
    font-size: 0.1px;
    height: 1px;
    margin-right: -3px;
    opacity: 0;
    visibility: hidden;
    width: 0;
  }

  .story-summary {
    @include gutter-sizing('margin-left');
    margin-bottom: 10px;
  }
}

.schedule-heading {
  border-top: 1px solid $border-color-light;
  color: $text-color-medium;
  margin: 0;
  padding: 16px 0;
}
