@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.panel {
  @include gutter-sizing('margin-bottom');

  .card-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &.gradient {
      color: rgba($white, 0.9);
    }

    .stat {
      height: 31px;

      .name {
        font-size: 14px;
        margin-right: 4px;
      }

      .number,
      .percentage {
        font-size: 24px;
        line-height: (32/24);
      }

      .number {
        font-weight: $font-weight-bold;
      }

      .percentage {
        font-weight: $font-weight-light;

        &:before {
          content: ' • ';
        }
      }
    }
  }

  &.chart-container .card-body {
    @include gutter-sizing(['padding-bottom'], 0.4);
  }
}
