@import url(https://rsms.me/inter/inter.css);
body {
  font-feature-settings: 'tnum', 'ss01', 'cv08', 'calt', 'liga', 'kern'; }

@font-face {
  font-family: Linearicons;
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/Linearicons.412a5dd4.woff);
  src: url(/static/media/Linearicons.0348140e.eot#iefix) format(embedded-opentype), url(/static/media/Linearicons.56550e46.ttf) format(truetype), url(/static/media/Linearicons.0348140e.eot) format(eot), url(/static/media/Linearicons.e583ccbc.svg#Linearicons) format(svg); }

[class^='lnr-'],
[class*=' lnr-'] {
  font-family: 'Linearicons' !important;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lnr-home:before {
  content: '\E600'; }

.lnr-home2:before {
  content: '\E601'; }

.lnr-home3:before {
  content: '\E602'; }

.lnr-home4:before {
  content: '\E603'; }

.lnr-home5:before {
  content: '\E604'; }

.lnr-home6:before {
  content: '\E605'; }

.lnr-bathtub:before {
  content: '\E606'; }

.lnr-toothbrush:before {
  content: '\E607'; }

.lnr-bed:before {
  content: '\E608'; }

.lnr-couch:before {
  content: '\E609'; }

.lnr-chair:before {
  content: '\E60A'; }

.lnr-city:before {
  content: '\E60B'; }

.lnr-apartment:before {
  content: '\E60C'; }

.lnr-pencil:before {
  content: '\E60D'; }

.lnr-pencil2:before {
  content: '\E60E'; }

.lnr-pen:before {
  content: '\E60F'; }

.lnr-pencil3:before {
  content: '\E610'; }

.lnr-eraser:before {
  content: '\E611'; }

.lnr-pencil4:before {
  content: '\E612'; }

.lnr-pencil5:before {
  content: '\E613'; }

.lnr-feather:before {
  content: '\E614'; }

.lnr-feather2:before {
  content: '\E615'; }

.lnr-feather3:before {
  content: '\E616'; }

.lnr-pen2:before {
  content: '\E617'; }

.lnr-pen-add:before {
  content: '\E618'; }

.lnr-pen-remove:before {
  content: '\E619'; }

.lnr-vector:before {
  content: '\E61A'; }

.lnr-pen3:before {
  content: '\E61B'; }

.lnr-blog:before {
  content: '\E61C'; }

.lnr-brush:before {
  content: '\E61D'; }

.lnr-brush2:before {
  content: '\E61E'; }

.lnr-spray:before {
  content: '\E61F'; }

.lnr-paint-roller:before {
  content: '\E620'; }

.lnr-stamp:before {
  content: '\E621'; }

.lnr-tape:before {
  content: '\E622'; }

.lnr-desk-tape:before {
  content: '\E623'; }

.lnr-texture:before {
  content: '\E624'; }

.lnr-eye-dropper:before {
  content: '\E625'; }

.lnr-palette:before {
  content: '\E626'; }

.lnr-color-sampler:before {
  content: '\E627'; }

.lnr-bucket:before {
  content: '\E628'; }

.lnr-gradient:before {
  content: '\E629'; }

.lnr-gradient2:before {
  content: '\E62A'; }

.lnr-magic-wand:before {
  content: '\E62B'; }

.lnr-magnet:before {
  content: '\E62C'; }

.lnr-pencil-ruler:before {
  content: '\E62D'; }

.lnr-pencil-ruler2:before {
  content: '\E62E'; }

.lnr-compass:before {
  content: '\E62F'; }

.lnr-aim:before {
  content: '\E630'; }

.lnr-gun:before {
  content: '\E631'; }

.lnr-bottle:before {
  content: '\E632'; }

.lnr-drop:before {
  content: '\E633'; }

.lnr-drop-crossed:before {
  content: '\E634'; }

.lnr-drop2:before {
  content: '\E635'; }

.lnr-snow:before {
  content: '\E636'; }

.lnr-snow2:before {
  content: '\E637'; }

.lnr-fire:before {
  content: '\E638'; }

.lnr-lighter:before {
  content: '\E639'; }

.lnr-knife:before {
  content: '\E63A'; }

.lnr-dagger:before {
  content: '\E63B'; }

.lnr-tissue:before {
  content: '\E63C'; }

.lnr-toilet-paper:before {
  content: '\E63D'; }

.lnr-poop:before {
  content: '\E63E'; }

.lnr-umbrella:before {
  content: '\E63F'; }

.lnr-umbrella2:before {
  content: '\E640'; }

.lnr-rain:before {
  content: '\E641'; }

.lnr-tornado:before {
  content: '\E642'; }

.lnr-wind:before {
  content: '\E643'; }

.lnr-fan:before {
  content: '\E644'; }

.lnr-contrast:before {
  content: '\E645'; }

.lnr-sun-small:before {
  content: '\E646'; }

.lnr-sun:before {
  content: '\E647'; }

.lnr-sun2:before {
  content: '\E648'; }

.lnr-moon:before {
  content: '\E649'; }

.lnr-cloud:before {
  content: '\E64A'; }

.lnr-cloud-upload:before {
  content: '\E64B'; }

.lnr-cloud-download:before {
  content: '\E64C'; }

.lnr-cloud-rain:before {
  content: '\E64D'; }

.lnr-cloud-hailstones:before {
  content: '\E64E'; }

.lnr-cloud-snow:before {
  content: '\E64F'; }

.lnr-cloud-windy:before {
  content: '\E650'; }

.lnr-sun-wind:before {
  content: '\E651'; }

.lnr-cloud-fog:before {
  content: '\E652'; }

.lnr-cloud-sun:before {
  content: '\E653'; }

.lnr-cloud-lightning:before {
  content: '\E654'; }

.lnr-cloud-sync:before {
  content: '\E655'; }

.lnr-cloud-lock:before {
  content: '\E656'; }

.lnr-cloud-gear:before {
  content: '\E657'; }

.lnr-cloud-alert:before {
  content: '\E658'; }

.lnr-cloud-check:before {
  content: '\E659'; }

.lnr-cloud-cross:before {
  content: '\E65A'; }

.lnr-cloud-crossed:before {
  content: '\E65B'; }

.lnr-cloud-database:before {
  content: '\E65C'; }

.lnr-database:before {
  content: '\E65D'; }

.lnr-database-add:before {
  content: '\E65E'; }

.lnr-database-remove:before {
  content: '\E65F'; }

.lnr-database-lock:before {
  content: '\E660'; }

.lnr-database-refresh:before {
  content: '\E661'; }

.lnr-database-check:before {
  content: '\E662'; }

.lnr-database-history:before {
  content: '\E663'; }

.lnr-database-upload:before {
  content: '\E664'; }

.lnr-database-download:before {
  content: '\E665'; }

.lnr-server:before {
  content: '\E666'; }

.lnr-shield:before {
  content: '\E667'; }

.lnr-shield-check:before {
  content: '\E668'; }

.lnr-shield-alert:before {
  content: '\E669'; }

.lnr-shield-cross:before {
  content: '\E66A'; }

.lnr-lock:before {
  content: '\E66B'; }

.lnr-rotation-lock:before {
  content: '\E66C'; }

.lnr-unlock:before {
  content: '\E66D'; }

.lnr-key:before {
  content: '\E66E'; }

.lnr-key-hole:before {
  content: '\E66F'; }

.lnr-toggle-off:before {
  content: '\E670'; }

.lnr-toggle-on:before {
  content: '\E671'; }

.lnr-cog:before {
  content: '\E672'; }

.lnr-cog2:before {
  content: '\E673'; }

.lnr-wrench:before {
  content: '\E674'; }

.lnr-screwdriver:before {
  content: '\E675'; }

.lnr-hammer-wrench:before {
  content: '\E676'; }

.lnr-hammer:before {
  content: '\E677'; }

.lnr-saw:before {
  content: '\E678'; }

.lnr-axe:before {
  content: '\E679'; }

.lnr-axe2:before {
  content: '\E67A'; }

.lnr-shovel:before {
  content: '\E67B'; }

.lnr-pickaxe:before {
  content: '\E67C'; }

.lnr-factory:before {
  content: '\E67D'; }

.lnr-factory2:before {
  content: '\E67E'; }

.lnr-recycle:before {
  content: '\E67F'; }

.lnr-trash:before {
  content: '\E680'; }

.lnr-trash2:before {
  content: '\E681'; }

.lnr-trash3:before {
  content: '\E682'; }

.lnr-broom:before {
  content: '\E683'; }

.lnr-game:before {
  content: '\E684'; }

.lnr-gamepad:before {
  content: '\E685'; }

.lnr-joystick:before {
  content: '\E686'; }

.lnr-dice:before {
  content: '\E687'; }

.lnr-spades:before {
  content: '\E688'; }

.lnr-diamonds:before {
  content: '\E689'; }

.lnr-clubs:before {
  content: '\E68A'; }

.lnr-hearts:before {
  content: '\E68B'; }

.lnr-heart:before {
  content: '\E68C'; }

.lnr-star:before {
  content: '\E68D'; }

.lnr-star-half:before {
  content: '\E68E'; }

.lnr-star-empty:before {
  content: '\E68F'; }

.lnr-flag:before {
  content: '\E690'; }

.lnr-flag2:before {
  content: '\E691'; }

.lnr-flag3:before {
  content: '\E692'; }

.lnr-mailbox-full:before {
  content: '\E693'; }

.lnr-mailbox-empty:before {
  content: '\E694'; }

.lnr-at-sign:before {
  content: '\E695'; }

.lnr-envelope:before {
  content: '\E696'; }

.lnr-envelope-open:before {
  content: '\E697'; }

.lnr-paperclip:before {
  content: '\E698'; }

.lnr-paper-plane:before {
  content: '\E699'; }

.lnr-reply:before {
  content: '\E69A'; }

.lnr-reply-all:before {
  content: '\E69B'; }

.lnr-inbox:before {
  content: '\E69C'; }

.lnr-inbox2:before {
  content: '\E69D'; }

.lnr-outbox:before {
  content: '\E69E'; }

.lnr-box:before {
  content: '\E69F'; }

.lnr-archive:before {
  content: '\E6A0'; }

.lnr-archive2:before {
  content: '\E6A1'; }

.lnr-drawers:before {
  content: '\E6A2'; }

.lnr-drawers2:before {
  content: '\E6A3'; }

.lnr-drawers3:before {
  content: '\E6A4'; }

.lnr-eye:before {
  content: '\E6A5'; }

.lnr-eye-crossed:before {
  content: '\E6A6'; }

.lnr-eye-plus:before {
  content: '\E6A7'; }

.lnr-eye-minus:before {
  content: '\E6A8'; }

.lnr-binoculars:before {
  content: '\E6A9'; }

.lnr-binoculars2:before {
  content: '\E6AA'; }

.lnr-hdd:before {
  content: '\E6AB'; }

.lnr-hdd-down:before {
  content: '\E6AC'; }

.lnr-hdd-up:before {
  content: '\E6AD'; }

.lnr-floppy-disk:before {
  content: '\E6AE'; }

.lnr-disc:before {
  content: '\E6AF'; }

.lnr-tape2:before {
  content: '\E6B0'; }

.lnr-printer:before {
  content: '\E6B1'; }

.lnr-shredder:before {
  content: '\E6B2'; }

.lnr-file-empty:before {
  content: '\E6B3'; }

.lnr-file-add:before {
  content: '\E6B4'; }

.lnr-file-check:before {
  content: '\E6B5'; }

.lnr-file-lock:before {
  content: '\E6B6'; }

.lnr-files:before {
  content: '\E6B7'; }

.lnr-copy:before {
  content: '\E6B8'; }

.lnr-compare:before {
  content: '\E6B9'; }

.lnr-folder:before {
  content: '\E6BA'; }

.lnr-folder-search:before {
  content: '\E6BB'; }

.lnr-folder-plus:before {
  content: '\E6BC'; }

.lnr-folder-minus:before {
  content: '\E6BD'; }

.lnr-folder-download:before {
  content: '\E6BE'; }

.lnr-folder-upload:before {
  content: '\E6BF'; }

.lnr-folder-star:before {
  content: '\E6C0'; }

.lnr-folder-heart:before {
  content: '\E6C1'; }

.lnr-folder-user:before {
  content: '\E6C2'; }

.lnr-folder-shared:before {
  content: '\E6C3'; }

.lnr-folder-music:before {
  content: '\E6C4'; }

.lnr-folder-picture:before {
  content: '\E6C5'; }

.lnr-folder-film:before {
  content: '\E6C6'; }

.lnr-scissors:before {
  content: '\E6C7'; }

.lnr-paste:before {
  content: '\E6C8'; }

.lnr-clipboard-empty:before {
  content: '\E6C9'; }

.lnr-clipboard-pencil:before {
  content: '\E6CA'; }

.lnr-clipboard-text:before {
  content: '\E6CB'; }

.lnr-clipboard-check:before {
  content: '\E6CC'; }

.lnr-clipboard-down:before {
  content: '\E6CD'; }

.lnr-clipboard-left:before {
  content: '\E6CE'; }

.lnr-clipboard-alert:before {
  content: '\E6CF'; }

.lnr-clipboard-user:before {
  content: '\E6D0'; }

.lnr-register:before {
  content: '\E6D1'; }

.lnr-enter:before {
  content: '\E6D2'; }

.lnr-exit:before {
  content: '\E6D3'; }

.lnr-papers:before {
  content: '\E6D4'; }

.lnr-news:before {
  content: '\E6D5'; }

.lnr-reading:before {
  content: '\E6D6'; }

.lnr-typewriter:before {
  content: '\E6D7'; }

.lnr-document:before {
  content: '\E6D8'; }

.lnr-document2:before {
  content: '\E6D9'; }

.lnr-graduation-hat:before {
  content: '\E6DA'; }

.lnr-license:before {
  content: '\E6DB'; }

.lnr-license2:before {
  content: '\E6DC'; }

.lnr-medal-empty:before {
  content: '\E6DD'; }

.lnr-medal-first:before {
  content: '\E6DE'; }

.lnr-medal-second:before {
  content: '\E6DF'; }

.lnr-medal-third:before {
  content: '\E6E0'; }

.lnr-podium:before {
  content: '\E6E1'; }

.lnr-trophy:before {
  content: '\E6E2'; }

.lnr-trophy2:before {
  content: '\E6E3'; }

.lnr-music-note:before {
  content: '\E6E4'; }

.lnr-music-note2:before {
  content: '\E6E5'; }

.lnr-music-note3:before {
  content: '\E6E6'; }

.lnr-playlist:before {
  content: '\E6E7'; }

.lnr-playlist-add:before {
  content: '\E6E8'; }

.lnr-guitar:before {
  content: '\E6E9'; }

.lnr-trumpet:before {
  content: '\E6EA'; }

.lnr-album:before {
  content: '\E6EB'; }

.lnr-shuffle:before {
  content: '\E6EC'; }

.lnr-repeat-one:before {
  content: '\E6ED'; }

.lnr-repeat:before {
  content: '\E6EE'; }

.lnr-headphones:before {
  content: '\E6EF'; }

.lnr-headset:before {
  content: '\E6F0'; }

.lnr-loudspeaker:before {
  content: '\E6F1'; }

.lnr-equalizer:before {
  content: '\E6F2'; }

.lnr-theater:before {
  content: '\E6F3'; }

.lnr-3d-glasses:before {
  content: '\E6F4'; }

.lnr-ticket:before {
  content: '\E6F5'; }

.lnr-presentation:before {
  content: '\E6F6'; }

.lnr-play:before {
  content: '\E6F7'; }

.lnr-film-play:before {
  content: '\E6F8'; }

.lnr-clapboard-play:before {
  content: '\E6F9'; }

.lnr-media:before {
  content: '\E6FA'; }

.lnr-film:before {
  content: '\E6FB'; }

.lnr-film2:before {
  content: '\E6FC'; }

.lnr-surveillance:before {
  content: '\E6FD'; }

.lnr-surveillance2:before {
  content: '\E6FE'; }

.lnr-camera:before {
  content: '\E6FF'; }

.lnr-camera-crossed:before {
  content: '\E700'; }

.lnr-camera-play:before {
  content: '\E701'; }

.lnr-time-lapse:before {
  content: '\E702'; }

.lnr-record:before {
  content: '\E703'; }

.lnr-camera2:before {
  content: '\E704'; }

.lnr-camera-flip:before {
  content: '\E705'; }

.lnr-panorama:before {
  content: '\E706'; }

.lnr-time-lapse2:before {
  content: '\E707'; }

.lnr-shutter:before {
  content: '\E708'; }

.lnr-shutter2:before {
  content: '\E709'; }

.lnr-face-detection:before {
  content: '\E70A'; }

.lnr-flare:before {
  content: '\E70B'; }

.lnr-convex:before {
  content: '\E70C'; }

.lnr-concave:before {
  content: '\E70D'; }

.lnr-picture:before {
  content: '\E70E'; }

.lnr-picture2:before {
  content: '\E70F'; }

.lnr-picture3:before {
  content: '\E710'; }

.lnr-pictures:before {
  content: '\E711'; }

.lnr-book:before {
  content: '\E712'; }

.lnr-audio-book:before {
  content: '\E713'; }

.lnr-book2:before {
  content: '\E714'; }

.lnr-bookmark:before {
  content: '\E715'; }

.lnr-bookmark2:before {
  content: '\E716'; }

.lnr-label:before {
  content: '\E717'; }

.lnr-library:before {
  content: '\E718'; }

.lnr-library2:before {
  content: '\E719'; }

.lnr-contacts:before {
  content: '\E71A'; }

.lnr-profile:before {
  content: '\E71B'; }

.lnr-portrait:before {
  content: '\E71C'; }

.lnr-portrait2:before {
  content: '\E71D'; }

.lnr-user:before {
  content: '\E71E'; }

.lnr-user-plus:before {
  content: '\E71F'; }

.lnr-user-minus:before {
  content: '\E720'; }

.lnr-user-lock:before {
  content: '\E721'; }

.lnr-users:before {
  content: '\E722'; }

.lnr-users2:before {
  content: '\E723'; }

.lnr-users-plus:before {
  content: '\E724'; }

.lnr-users-minus:before {
  content: '\E725'; }

.lnr-group-work:before {
  content: '\E726'; }

.lnr-woman:before {
  content: '\E727'; }

.lnr-man:before {
  content: '\E728'; }

.lnr-baby:before {
  content: '\E729'; }

.lnr-baby2:before {
  content: '\E72A'; }

.lnr-baby3:before {
  content: '\E72B'; }

.lnr-baby-bottle:before {
  content: '\E72C'; }

.lnr-walk:before {
  content: '\E72D'; }

.lnr-hand-waving:before {
  content: '\E72E'; }

.lnr-jump:before {
  content: '\E72F'; }

.lnr-run:before {
  content: '\E730'; }

.lnr-woman2:before {
  content: '\E731'; }

.lnr-man2:before {
  content: '\E732'; }

.lnr-man-woman:before {
  content: '\E733'; }

.lnr-height:before {
  content: '\E734'; }

.lnr-weight:before {
  content: '\E735'; }

.lnr-scale:before {
  content: '\E736'; }

.lnr-button:before {
  content: '\E737'; }

.lnr-bow-tie:before {
  content: '\E738'; }

.lnr-tie:before {
  content: '\E739'; }

.lnr-socks:before {
  content: '\E73A'; }

.lnr-shoe:before {
  content: '\E73B'; }

.lnr-shoes:before {
  content: '\E73C'; }

.lnr-hat:before {
  content: '\E73D'; }

.lnr-pants:before {
  content: '\E73E'; }

.lnr-shorts:before {
  content: '\E73F'; }

.lnr-flip-flops:before {
  content: '\E740'; }

.lnr-shirt:before {
  content: '\E741'; }

.lnr-hanger:before {
  content: '\E742'; }

.lnr-laundry:before {
  content: '\E743'; }

.lnr-store:before {
  content: '\E744'; }

.lnr-haircut:before {
  content: '\E745'; }

.lnr-store-24:before {
  content: '\E746'; }

.lnr-barcode:before {
  content: '\E747'; }

.lnr-barcode2:before {
  content: '\E748'; }

.lnr-barcode3:before {
  content: '\E749'; }

.lnr-cashier:before {
  content: '\E74A'; }

.lnr-bag:before {
  content: '\E74B'; }

.lnr-bag2:before {
  content: '\E74C'; }

.lnr-cart:before {
  content: '\E74D'; }

.lnr-cart-empty:before {
  content: '\E74E'; }

.lnr-cart-full:before {
  content: '\E74F'; }

.lnr-cart-plus:before {
  content: '\E750'; }

.lnr-cart-plus2:before {
  content: '\E751'; }

.lnr-cart-add:before {
  content: '\E752'; }

.lnr-cart-remove:before {
  content: '\E753'; }

.lnr-cart-exchange:before {
  content: '\E754'; }

.lnr-tag:before {
  content: '\E755'; }

.lnr-tags:before {
  content: '\E756'; }

.lnr-receipt:before {
  content: '\E757'; }

.lnr-wallet:before {
  content: '\E758'; }

.lnr-credit-card:before {
  content: '\E759'; }

.lnr-cash-dollar:before {
  content: '\E75A'; }

.lnr-cash-euro:before {
  content: '\E75B'; }

.lnr-cash-pound:before {
  content: '\E75C'; }

.lnr-cash-yen:before {
  content: '\E75D'; }

.lnr-bag-dollar:before {
  content: '\E75E'; }

.lnr-bag-euro:before {
  content: '\E75F'; }

.lnr-bag-pound:before {
  content: '\E760'; }

.lnr-bag-yen:before {
  content: '\E761'; }

.lnr-coin-dollar:before {
  content: '\E762'; }

.lnr-coin-euro:before {
  content: '\E763'; }

.lnr-coin-pound:before {
  content: '\E764'; }

.lnr-coin-yen:before {
  content: '\E765'; }

.lnr-calculator:before {
  content: '\E766'; }

.lnr-calculator2:before {
  content: '\E767'; }

.lnr-abacus:before {
  content: '\E768'; }

.lnr-vault:before {
  content: '\E769'; }

.lnr-telephone:before {
  content: '\E76A'; }

.lnr-phone-lock:before {
  content: '\E76B'; }

.lnr-phone-wave:before {
  content: '\E76C'; }

.lnr-phone-pause:before {
  content: '\E76D'; }

.lnr-phone-outgoing:before {
  content: '\E76E'; }

.lnr-phone-incoming:before {
  content: '\E76F'; }

.lnr-phone-in-out:before {
  content: '\E770'; }

.lnr-phone-error:before {
  content: '\E771'; }

.lnr-phone-sip:before {
  content: '\E772'; }

.lnr-phone-plus:before {
  content: '\E773'; }

.lnr-phone-minus:before {
  content: '\E774'; }

.lnr-voicemail:before {
  content: '\E775'; }

.lnr-dial:before {
  content: '\E776'; }

.lnr-telephone2:before {
  content: '\E777'; }

.lnr-pushpin:before {
  content: '\E778'; }

.lnr-pushpin2:before {
  content: '\E779'; }

.lnr-map-marker:before {
  content: '\E77A'; }

.lnr-map-marker-user:before {
  content: '\E77B'; }

.lnr-map-marker-down:before {
  content: '\E77C'; }

.lnr-map-marker-check:before {
  content: '\E77D'; }

.lnr-map-marker-crossed:before {
  content: '\E77E'; }

.lnr-radar:before {
  content: '\E77F'; }

.lnr-compass2:before {
  content: '\E780'; }

.lnr-map:before {
  content: '\E781'; }

.lnr-map2:before {
  content: '\E782'; }

.lnr-location:before {
  content: '\E783'; }

.lnr-road-sign:before {
  content: '\E784'; }

.lnr-calendar-empty:before {
  content: '\E785'; }

.lnr-calendar-check:before {
  content: '\E786'; }

.lnr-calendar-cross:before {
  content: '\E787'; }

.lnr-calendar-31:before {
  content: '\E788'; }

.lnr-calendar-full:before {
  content: '\E789'; }

.lnr-calendar-insert:before {
  content: '\E78A'; }

.lnr-calendar-text:before {
  content: '\E78B'; }

.lnr-calendar-user:before {
  content: '\E78C'; }

.lnr-mouse:before {
  content: '\E78D'; }

.lnr-mouse-left:before {
  content: '\E78E'; }

.lnr-mouse-right:before {
  content: '\E78F'; }

.lnr-mouse-both:before {
  content: '\E790'; }

.lnr-keyboard:before {
  content: '\E791'; }

.lnr-keyboard-up:before {
  content: '\E792'; }

.lnr-keyboard-down:before {
  content: '\E793'; }

.lnr-delete:before {
  content: '\E794'; }

.lnr-spell-check:before {
  content: '\E795'; }

.lnr-escape:before {
  content: '\E796'; }

.lnr-enter2:before {
  content: '\E797'; }

.lnr-screen:before {
  content: '\E798'; }

.lnr-aspect-ratio:before {
  content: '\E799'; }

.lnr-signal:before {
  content: '\E79A'; }

.lnr-signal-lock:before {
  content: '\E79B'; }

.lnr-signal-80:before {
  content: '\E79C'; }

.lnr-signal-60:before {
  content: '\E79D'; }

.lnr-signal-40:before {
  content: '\E79E'; }

.lnr-signal-20:before {
  content: '\E79F'; }

.lnr-signal-0:before {
  content: '\E7A0'; }

.lnr-signal-blocked:before {
  content: '\E7A1'; }

.lnr-sim:before {
  content: '\E7A2'; }

.lnr-flash-memory:before {
  content: '\E7A3'; }

.lnr-usb-drive:before {
  content: '\E7A4'; }

.lnr-phone:before {
  content: '\E7A5'; }

.lnr-smartphone:before {
  content: '\E7A6'; }

.lnr-smartphone-notification:before {
  content: '\E7A7'; }

.lnr-smartphone-vibration:before {
  content: '\E7A8'; }

.lnr-smartphone-embed:before {
  content: '\E7A9'; }

.lnr-smartphone-waves:before {
  content: '\E7AA'; }

.lnr-tablet:before {
  content: '\E7AB'; }

.lnr-tablet2:before {
  content: '\E7AC'; }

.lnr-laptop:before {
  content: '\E7AD'; }

.lnr-laptop-phone:before {
  content: '\E7AE'; }

.lnr-desktop:before {
  content: '\E7AF'; }

.lnr-launch:before {
  content: '\E7B0'; }

.lnr-new-tab:before {
  content: '\E7B1'; }

.lnr-window:before {
  content: '\E7B2'; }

.lnr-cable:before {
  content: '\E7B3'; }

.lnr-cable2:before {
  content: '\E7B4'; }

.lnr-tv:before {
  content: '\E7B5'; }

.lnr-radio:before {
  content: '\E7B6'; }

.lnr-remote-control:before {
  content: '\E7B7'; }

.lnr-power-switch:before {
  content: '\E7B8'; }

.lnr-power:before {
  content: '\E7B9'; }

.lnr-power-crossed:before {
  content: '\E7BA'; }

.lnr-flash-auto:before {
  content: '\E7BB'; }

.lnr-lamp:before {
  content: '\E7BC'; }

.lnr-flashlight:before {
  content: '\E7BD'; }

.lnr-lampshade:before {
  content: '\E7BE'; }

.lnr-cord:before {
  content: '\E7BF'; }

.lnr-outlet:before {
  content: '\E7C0'; }

.lnr-battery-power:before {
  content: '\E7C1'; }

.lnr-battery-empty:before {
  content: '\E7C2'; }

.lnr-battery-alert:before {
  content: '\E7C3'; }

.lnr-battery-error:before {
  content: '\E7C4'; }

.lnr-battery-low1:before {
  content: '\E7C5'; }

.lnr-battery-low2:before {
  content: '\E7C6'; }

.lnr-battery-low3:before {
  content: '\E7C7'; }

.lnr-battery-mid1:before {
  content: '\E7C8'; }

.lnr-battery-mid2:before {
  content: '\E7C9'; }

.lnr-battery-mid3:before {
  content: '\E7CA'; }

.lnr-battery-full:before {
  content: '\E7CB'; }

.lnr-battery-charging:before {
  content: '\E7CC'; }

.lnr-battery-charging2:before {
  content: '\E7CD'; }

.lnr-battery-charging3:before {
  content: '\E7CE'; }

.lnr-battery-charging4:before {
  content: '\E7CF'; }

.lnr-battery-charging5:before {
  content: '\E7D0'; }

.lnr-battery-charging6:before {
  content: '\E7D1'; }

.lnr-battery-charging7:before {
  content: '\E7D2'; }

.lnr-chip:before {
  content: '\E7D3'; }

.lnr-chip-x64:before {
  content: '\E7D4'; }

.lnr-chip-x86:before {
  content: '\E7D5'; }

.lnr-bubble:before {
  content: '\E7D6'; }

.lnr-bubbles:before {
  content: '\E7D7'; }

.lnr-bubble-dots:before {
  content: '\E7D8'; }

.lnr-bubble-alert:before {
  content: '\E7D9'; }

.lnr-bubble-question:before {
  content: '\E7DA'; }

.lnr-bubble-text:before {
  content: '\E7DB'; }

.lnr-bubble-pencil:before {
  content: '\E7DC'; }

.lnr-bubble-picture:before {
  content: '\E7DD'; }

.lnr-bubble-video:before {
  content: '\E7DE'; }

.lnr-bubble-user:before {
  content: '\E7DF'; }

.lnr-bubble-quote:before {
  content: '\E7E0'; }

.lnr-bubble-heart:before {
  content: '\E7E1'; }

.lnr-bubble-emoticon:before {
  content: '\E7E2'; }

.lnr-bubble-attachment:before {
  content: '\E7E3'; }

.lnr-phone-bubble:before {
  content: '\E7E4'; }

.lnr-quote-open:before {
  content: '\E7E5'; }

.lnr-quote-close:before {
  content: '\E7E6'; }

.lnr-dna:before {
  content: '\E7E7'; }

.lnr-heart-pulse:before {
  content: '\E7E8'; }

.lnr-pulse:before {
  content: '\E7E9'; }

.lnr-syringe:before {
  content: '\E7EA'; }

.lnr-pills:before {
  content: '\E7EB'; }

.lnr-first-aid:before {
  content: '\E7EC'; }

.lnr-lifebuoy:before {
  content: '\E7ED'; }

.lnr-bandage:before {
  content: '\E7EE'; }

.lnr-bandages:before {
  content: '\E7EF'; }

.lnr-thermometer:before {
  content: '\E7F0'; }

.lnr-microscope:before {
  content: '\E7F1'; }

.lnr-brain:before {
  content: '\E7F2'; }

.lnr-beaker:before {
  content: '\E7F3'; }

.lnr-skull:before {
  content: '\E7F4'; }

.lnr-bone:before {
  content: '\E7F5'; }

.lnr-construction:before {
  content: '\E7F6'; }

.lnr-construction-cone:before {
  content: '\E7F7'; }

.lnr-pie-chart:before {
  content: '\E7F8'; }

.lnr-pie-chart2:before {
  content: '\E7F9'; }

.lnr-graph:before {
  content: '\E7FA'; }

.lnr-chart-growth:before {
  content: '\E7FB'; }

.lnr-chart-bars:before {
  content: '\E7FC'; }

.lnr-chart-settings:before {
  content: '\E7FD'; }

.lnr-cake:before {
  content: '\E7FE'; }

.lnr-gift:before {
  content: '\E7FF'; }

.lnr-balloon:before {
  content: '\E800'; }

.lnr-rank:before {
  content: '\E801'; }

.lnr-rank2:before {
  content: '\E802'; }

.lnr-rank3:before {
  content: '\E803'; }

.lnr-crown:before {
  content: '\E804'; }

.lnr-lotus:before {
  content: '\E805'; }

.lnr-diamond:before {
  content: '\E806'; }

.lnr-diamond2:before {
  content: '\E807'; }

.lnr-diamond3:before {
  content: '\E808'; }

.lnr-diamond4:before {
  content: '\E809'; }

.lnr-linearicons:before {
  content: '\E80A'; }

.lnr-teacup:before {
  content: '\E80B'; }

.lnr-teapot:before {
  content: '\E80C'; }

.lnr-glass:before {
  content: '\E80D'; }

.lnr-bottle2:before {
  content: '\E80E'; }

.lnr-glass-cocktail:before {
  content: '\E80F'; }

.lnr-glass2:before {
  content: '\E810'; }

.lnr-dinner:before {
  content: '\E811'; }

.lnr-dinner2:before {
  content: '\E812'; }

.lnr-chef:before {
  content: '\E813'; }

.lnr-scale2:before {
  content: '\E814'; }

.lnr-egg:before {
  content: '\E815'; }

.lnr-egg2:before {
  content: '\E816'; }

.lnr-eggs:before {
  content: '\E817'; }

.lnr-platter:before {
  content: '\E818'; }

.lnr-steak:before {
  content: '\E819'; }

.lnr-hamburger:before {
  content: '\E81A'; }

.lnr-hotdog:before {
  content: '\E81B'; }

.lnr-pizza:before {
  content: '\E81C'; }

.lnr-sausage:before {
  content: '\E81D'; }

.lnr-chicken:before {
  content: '\E81E'; }

.lnr-fish:before {
  content: '\E81F'; }

.lnr-carrot:before {
  content: '\E820'; }

.lnr-cheese:before {
  content: '\E821'; }

.lnr-bread:before {
  content: '\E822'; }

.lnr-ice-cream:before {
  content: '\E823'; }

.lnr-ice-cream2:before {
  content: '\E824'; }

.lnr-candy:before {
  content: '\E825'; }

.lnr-lollipop:before {
  content: '\E826'; }

.lnr-coffee-bean:before {
  content: '\E827'; }

.lnr-coffee-cup:before {
  content: '\E828'; }

.lnr-cherry:before {
  content: '\E829'; }

.lnr-grapes:before {
  content: '\E82A'; }

.lnr-citrus:before {
  content: '\E82B'; }

.lnr-apple:before {
  content: '\E82C'; }

.lnr-leaf:before {
  content: '\E82D'; }

.lnr-landscape:before {
  content: '\E82E'; }

.lnr-pine-tree:before {
  content: '\E82F'; }

.lnr-tree:before {
  content: '\E830'; }

.lnr-cactus:before {
  content: '\E831'; }

.lnr-paw:before {
  content: '\E832'; }

.lnr-footprint:before {
  content: '\E833'; }

.lnr-speed-slow:before {
  content: '\E834'; }

.lnr-speed-medium:before {
  content: '\E835'; }

.lnr-speed-fast:before {
  content: '\E836'; }

.lnr-rocket:before {
  content: '\E837'; }

.lnr-hammer2:before {
  content: '\E838'; }

.lnr-balance:before {
  content: '\E839'; }

.lnr-briefcase:before {
  content: '\E83A'; }

.lnr-luggage-weight:before {
  content: '\E83B'; }

.lnr-dolly:before {
  content: '\E83C'; }

.lnr-plane:before {
  content: '\E83D'; }

.lnr-plane-crossed:before {
  content: '\E83E'; }

.lnr-helicopter:before {
  content: '\E83F'; }

.lnr-traffic-lights:before {
  content: '\E840'; }

.lnr-siren:before {
  content: '\E841'; }

.lnr-road:before {
  content: '\E842'; }

.lnr-engine:before {
  content: '\E843'; }

.lnr-oil-pressure:before {
  content: '\E844'; }

.lnr-coolant-temperature:before {
  content: '\E845'; }

.lnr-car-battery:before {
  content: '\E846'; }

.lnr-gas:before {
  content: '\E847'; }

.lnr-gallon:before {
  content: '\E848'; }

.lnr-transmission:before {
  content: '\E849'; }

.lnr-car:before {
  content: '\E84A'; }

.lnr-car-wash:before {
  content: '\E84B'; }

.lnr-car-wash2:before {
  content: '\E84C'; }

.lnr-bus:before {
  content: '\E84D'; }

.lnr-bus2:before {
  content: '\E84E'; }

.lnr-car2:before {
  content: '\E84F'; }

.lnr-parking:before {
  content: '\E850'; }

.lnr-car-lock:before {
  content: '\E851'; }

.lnr-taxi:before {
  content: '\E852'; }

.lnr-car-siren:before {
  content: '\E853'; }

.lnr-car-wash3:before {
  content: '\E854'; }

.lnr-car-wash4:before {
  content: '\E855'; }

.lnr-ambulance:before {
  content: '\E856'; }

.lnr-truck:before {
  content: '\E857'; }

.lnr-trailer:before {
  content: '\E858'; }

.lnr-scale-truck:before {
  content: '\E859'; }

.lnr-train:before {
  content: '\E85A'; }

.lnr-ship:before {
  content: '\E85B'; }

.lnr-ship2:before {
  content: '\E85C'; }

.lnr-anchor:before {
  content: '\E85D'; }

.lnr-boat:before {
  content: '\E85E'; }

.lnr-bicycle:before {
  content: '\E85F'; }

.lnr-bicycle2:before {
  content: '\E860'; }

.lnr-dumbbell:before {
  content: '\E861'; }

.lnr-bench-press:before {
  content: '\E862'; }

.lnr-swim:before {
  content: '\E863'; }

.lnr-football:before {
  content: '\E864'; }

.lnr-baseball-bat:before {
  content: '\E865'; }

.lnr-baseball:before {
  content: '\E866'; }

.lnr-tennis:before {
  content: '\E867'; }

.lnr-tennis2:before {
  content: '\E868'; }

.lnr-ping-pong:before {
  content: '\E869'; }

.lnr-hockey:before {
  content: '\E86A'; }

.lnr-8ball:before {
  content: '\E86B'; }

.lnr-bowling:before {
  content: '\E86C'; }

.lnr-bowling-pins:before {
  content: '\E86D'; }

.lnr-golf:before {
  content: '\E86E'; }

.lnr-golf2:before {
  content: '\E86F'; }

.lnr-archery:before {
  content: '\E870'; }

.lnr-slingshot:before {
  content: '\E871'; }

.lnr-soccer:before {
  content: '\E872'; }

.lnr-basketball:before {
  content: '\E873'; }

.lnr-cube:before {
  content: '\E874'; }

.lnr-3d-rotate:before {
  content: '\E875'; }

.lnr-puzzle:before {
  content: '\E876'; }

.lnr-glasses:before {
  content: '\E877'; }

.lnr-glasses2:before {
  content: '\E878'; }

.lnr-accessibility:before {
  content: '\E879'; }

.lnr-wheelchair:before {
  content: '\E87A'; }

.lnr-wall:before {
  content: '\E87B'; }

.lnr-fence:before {
  content: '\E87C'; }

.lnr-wall2:before {
  content: '\E87D'; }

.lnr-icons:before {
  content: '\E87E'; }

.lnr-resize-handle:before {
  content: '\E87F'; }

.lnr-icons2:before {
  content: '\E880'; }

.lnr-select:before {
  content: '\E881'; }

.lnr-select2:before {
  content: '\E882'; }

.lnr-site-map:before {
  content: '\E883'; }

.lnr-earth:before {
  content: '\E884'; }

.lnr-earth-lock:before {
  content: '\E885'; }

.lnr-network:before {
  content: '\E886'; }

.lnr-network-lock:before {
  content: '\E887'; }

.lnr-planet:before {
  content: '\E888'; }

.lnr-happy:before {
  content: '\E889'; }

.lnr-smile:before {
  content: '\E88A'; }

.lnr-grin:before {
  content: '\E88B'; }

.lnr-tongue:before {
  content: '\E88C'; }

.lnr-sad:before {
  content: '\E88D'; }

.lnr-wink:before {
  content: '\E88E'; }

.lnr-dream:before {
  content: '\E88F'; }

.lnr-shocked:before {
  content: '\E890'; }

.lnr-shocked2:before {
  content: '\E891'; }

.lnr-tongue2:before {
  content: '\E892'; }

.lnr-neutral:before {
  content: '\E893'; }

.lnr-happy-grin:before {
  content: '\E894'; }

.lnr-cool:before {
  content: '\E895'; }

.lnr-mad:before {
  content: '\E896'; }

.lnr-grin-evil:before {
  content: '\E897'; }

.lnr-evil:before {
  content: '\E898'; }

.lnr-wow:before {
  content: '\E899'; }

.lnr-annoyed:before {
  content: '\E89A'; }

.lnr-wondering:before {
  content: '\E89B'; }

.lnr-confused:before {
  content: '\E89C'; }

.lnr-zipped:before {
  content: '\E89D'; }

.lnr-grumpy:before {
  content: '\E89E'; }

.lnr-mustache:before {
  content: '\E89F'; }

.lnr-tombstone-hipster:before {
  content: '\E8A0'; }

.lnr-tombstone:before {
  content: '\E8A1'; }

.lnr-ghost:before {
  content: '\E8A2'; }

.lnr-ghost-hipster:before {
  content: '\E8A3'; }

.lnr-halloween:before {
  content: '\E8A4'; }

.lnr-christmas:before {
  content: '\E8A5'; }

.lnr-easter-egg:before {
  content: '\E8A6'; }

.lnr-mustache2:before {
  content: '\E8A7'; }

.lnr-mustache-glasses:before {
  content: '\E8A8'; }

.lnr-pipe:before {
  content: '\E8A9'; }

.lnr-alarm:before {
  content: '\E8AA'; }

.lnr-alarm-add:before {
  content: '\E8AB'; }

.lnr-alarm-snooze:before {
  content: '\E8AC'; }

.lnr-alarm-ringing:before {
  content: '\E8AD'; }

.lnr-bullhorn:before {
  content: '\E8AE'; }

.lnr-hearing:before {
  content: '\E8AF'; }

.lnr-volume-high:before {
  content: '\E8B0'; }

.lnr-volume-medium:before {
  content: '\E8B1'; }

.lnr-volume-low:before {
  content: '\E8B2'; }

.lnr-volume:before {
  content: '\E8B3'; }

.lnr-mute:before {
  content: '\E8B4'; }

.lnr-lan:before {
  content: '\E8B5'; }

.lnr-lan2:before {
  content: '\E8B6'; }

.lnr-wifi:before {
  content: '\E8B7'; }

.lnr-wifi-lock:before {
  content: '\E8B8'; }

.lnr-wifi-blocked:before {
  content: '\E8B9'; }

.lnr-wifi-mid:before {
  content: '\E8BA'; }

.lnr-wifi-low:before {
  content: '\E8BB'; }

.lnr-wifi-low2:before {
  content: '\E8BC'; }

.lnr-wifi-alert:before {
  content: '\E8BD'; }

.lnr-wifi-alert-mid:before {
  content: '\E8BE'; }

.lnr-wifi-alert-low:before {
  content: '\E8BF'; }

.lnr-wifi-alert-low2:before {
  content: '\E8C0'; }

.lnr-stream:before {
  content: '\E8C1'; }

.lnr-stream-check:before {
  content: '\E8C2'; }

.lnr-stream-error:before {
  content: '\E8C3'; }

.lnr-stream-alert:before {
  content: '\E8C4'; }

.lnr-communication:before {
  content: '\E8C5'; }

.lnr-communication-crossed:before {
  content: '\E8C6'; }

.lnr-broadcast:before {
  content: '\E8C7'; }

.lnr-antenna:before {
  content: '\E8C8'; }

.lnr-satellite:before {
  content: '\E8C9'; }

.lnr-satellite2:before {
  content: '\E8CA'; }

.lnr-mic:before {
  content: '\E8CB'; }

.lnr-mic-mute:before {
  content: '\E8CC'; }

.lnr-mic2:before {
  content: '\E8CD'; }

.lnr-spotlights:before {
  content: '\E8CE'; }

.lnr-hourglass:before {
  content: '\E8CF'; }

.lnr-loading:before {
  content: '\E8D0'; }

.lnr-loading2:before {
  content: '\E8D1'; }

.lnr-loading3:before {
  content: '\E8D2'; }

.lnr-refresh:before {
  content: '\E8D3'; }

.lnr-refresh2:before {
  content: '\E8D4'; }

.lnr-undo:before {
  content: '\E8D5'; }

.lnr-redo:before {
  content: '\E8D6'; }

.lnr-jump2:before {
  content: '\E8D7'; }

.lnr-undo2:before {
  content: '\E8D8'; }

.lnr-redo2:before {
  content: '\E8D9'; }

.lnr-sync:before {
  content: '\E8DA'; }

.lnr-repeat-one2:before {
  content: '\E8DB'; }

.lnr-sync-crossed:before {
  content: '\E8DC'; }

.lnr-sync2:before {
  content: '\E8DD'; }

.lnr-repeat-one3:before {
  content: '\E8DE'; }

.lnr-sync-crossed2:before {
  content: '\E8DF'; }

.lnr-return:before {
  content: '\E8E0'; }

.lnr-return2:before {
  content: '\E8E1'; }

.lnr-refund:before {
  content: '\E8E2'; }

.lnr-history:before {
  content: '\E8E3'; }

.lnr-history2:before {
  content: '\E8E4'; }

.lnr-self-timer:before {
  content: '\E8E5'; }

.lnr-clock:before {
  content: '\E8E6'; }

.lnr-clock2:before {
  content: '\E8E7'; }

.lnr-clock3:before {
  content: '\E8E8'; }

.lnr-watch:before {
  content: '\E8E9'; }

.lnr-alarm2:before {
  content: '\E8EA'; }

.lnr-alarm-add2:before {
  content: '\E8EB'; }

.lnr-alarm-remove:before {
  content: '\E8EC'; }

.lnr-alarm-check:before {
  content: '\E8ED'; }

.lnr-alarm-error:before {
  content: '\E8EE'; }

.lnr-timer:before {
  content: '\E8EF'; }

.lnr-timer-crossed:before {
  content: '\E8F0'; }

.lnr-timer2:before {
  content: '\E8F1'; }

.lnr-timer-crossed2:before {
  content: '\E8F2'; }

.lnr-download:before {
  content: '\E8F3'; }

.lnr-upload:before {
  content: '\E8F4'; }

.lnr-download2:before {
  content: '\E8F5'; }

.lnr-upload2:before {
  content: '\E8F6'; }

.lnr-enter-up:before {
  content: '\E8F7'; }

.lnr-enter-down:before {
  content: '\E8F8'; }

.lnr-enter-left:before {
  content: '\E8F9'; }

.lnr-enter-right:before {
  content: '\E8FA'; }

.lnr-exit-up:before {
  content: '\E8FB'; }

.lnr-exit-down:before {
  content: '\E8FC'; }

.lnr-exit-left:before {
  content: '\E8FD'; }

.lnr-exit-right:before {
  content: '\E8FE'; }

.lnr-enter-up2:before {
  content: '\E8FF'; }

.lnr-enter-down2:before {
  content: '\E900'; }

.lnr-enter-vertical:before {
  content: '\E901'; }

.lnr-enter-left2:before {
  content: '\E902'; }

.lnr-enter-right2:before {
  content: '\E903'; }

.lnr-enter-horizontal:before {
  content: '\E904'; }

.lnr-exit-up2:before {
  content: '\E905'; }

.lnr-exit-down2:before {
  content: '\E906'; }

.lnr-exit-left2:before {
  content: '\E907'; }

.lnr-exit-right2:before {
  content: '\E908'; }

.lnr-cli:before {
  content: '\E909'; }

.lnr-bug:before {
  content: '\E90A'; }

.lnr-code:before {
  content: '\E90B'; }

.lnr-file-code:before {
  content: '\E90C'; }

.lnr-file-image:before {
  content: '\E90D'; }

.lnr-file-zip:before {
  content: '\E90E'; }

.lnr-file-audio:before {
  content: '\E90F'; }

.lnr-file-video:before {
  content: '\E910'; }

.lnr-file-preview:before {
  content: '\E911'; }

.lnr-file-charts:before {
  content: '\E912'; }

.lnr-file-stats:before {
  content: '\E913'; }

.lnr-file-spreadsheet:before {
  content: '\E914'; }

.lnr-link:before {
  content: '\E915'; }

.lnr-unlink:before {
  content: '\E916'; }

.lnr-link2:before {
  content: '\E917'; }

.lnr-unlink2:before {
  content: '\E918'; }

.lnr-thumbs-up:before {
  content: '\E919'; }

.lnr-thumbs-down:before {
  content: '\E91A'; }

.lnr-thumbs-up2:before {
  content: '\E91B'; }

.lnr-thumbs-down2:before {
  content: '\E91C'; }

.lnr-thumbs-up3:before {
  content: '\E91D'; }

.lnr-thumbs-down3:before {
  content: '\E91E'; }

.lnr-share:before {
  content: '\E91F'; }

.lnr-share2:before {
  content: '\E920'; }

.lnr-share3:before {
  content: '\E921'; }

.lnr-magnifier:before {
  content: '\E922'; }

.lnr-file-search:before {
  content: '\E923'; }

.lnr-find-replace:before {
  content: '\E924'; }

.lnr-zoom-in:before {
  content: '\E925'; }

.lnr-zoom-out:before {
  content: '\E926'; }

.lnr-loupe:before {
  content: '\E927'; }

.lnr-loupe-zoom-in:before {
  content: '\E928'; }

.lnr-loupe-zoom-out:before {
  content: '\E929'; }

.lnr-cross:before {
  content: '\E92A'; }

.lnr-menu:before {
  content: '\E92B'; }

.lnr-list:before {
  content: '\E92C'; }

.lnr-list2:before {
  content: '\E92D'; }

.lnr-list3:before {
  content: '\E92E'; }

.lnr-menu2:before {
  content: '\E92F'; }

.lnr-list4:before {
  content: '\E930'; }

.lnr-menu3:before {
  content: '\E931'; }

.lnr-exclamation:before {
  content: '\E932'; }

.lnr-question:before {
  content: '\E933'; }

.lnr-check:before {
  content: '\E934'; }

.lnr-cross2:before {
  content: '\E935'; }

.lnr-plus:before {
  content: '\E936'; }

.lnr-minus:before {
  content: '\E937'; }

.lnr-percent:before {
  content: '\E938'; }

.lnr-chevron-up:before {
  content: '\E939'; }

.lnr-chevron-down:before {
  content: '\E93A'; }

.lnr-chevron-left:before {
  content: '\E93B'; }

.lnr-chevron-right:before {
  content: '\E93C'; }

.lnr-chevrons-expand-vertical:before {
  content: '\E93D'; }

.lnr-chevrons-expand-horizontal:before {
  content: '\E93E'; }

.lnr-chevrons-contract-vertical:before {
  content: '\E93F'; }

.lnr-chevrons-contract-horizontal:before {
  content: '\E940'; }

.lnr-arrow-up:before {
  content: '\E941'; }

.lnr-arrow-down:before {
  content: '\E942'; }

.lnr-arrow-left:before {
  content: '\E943'; }

.lnr-arrow-right:before {
  content: '\E944'; }

.lnr-arrow-up-right:before {
  content: '\E945'; }

.lnr-arrows-merge:before {
  content: '\E946'; }

.lnr-arrows-split:before {
  content: '\E947'; }

.lnr-arrow-divert:before {
  content: '\E948'; }

.lnr-arrow-return:before {
  content: '\E949'; }

.lnr-expand:before {
  content: '\E94A'; }

.lnr-contract:before {
  content: '\E94B'; }

.lnr-expand2:before {
  content: '\E94C'; }

.lnr-contract2:before {
  content: '\E94D'; }

.lnr-move:before {
  content: '\E94E'; }

.lnr-tab:before {
  content: '\E94F'; }

.lnr-arrow-wave:before {
  content: '\E950'; }

.lnr-expand3:before {
  content: '\E951'; }

.lnr-expand4:before {
  content: '\E952'; }

.lnr-contract3:before {
  content: '\E953'; }

.lnr-notification:before {
  content: '\E954'; }

.lnr-warning:before {
  content: '\E955'; }

.lnr-notification-circle:before {
  content: '\E956'; }

.lnr-question-circle:before {
  content: '\E957'; }

.lnr-menu-circle:before {
  content: '\E958'; }

.lnr-checkmark-circle:before {
  content: '\E959'; }

.lnr-cross-circle:before {
  content: '\E95A'; }

.lnr-plus-circle:before {
  content: '\E95B'; }

.lnr-circle-minus:before {
  content: '\E95C'; }

.lnr-percent-circle:before {
  content: '\E95D'; }

.lnr-arrow-up-circle:before {
  content: '\E95E'; }

.lnr-arrow-down-circle:before {
  content: '\E95F'; }

.lnr-arrow-left-circle:before {
  content: '\E960'; }

.lnr-arrow-right-circle:before {
  content: '\E961'; }

.lnr-chevron-up-circle:before {
  content: '\E962'; }

.lnr-chevron-down-circle:before {
  content: '\E963'; }

.lnr-chevron-left-circle:before {
  content: '\E964'; }

.lnr-chevron-right-circle:before {
  content: '\E965'; }

.lnr-backward-circle:before {
  content: '\E966'; }

.lnr-first-circle:before {
  content: '\E967'; }

.lnr-previous-circle:before {
  content: '\E968'; }

.lnr-stop-circle:before {
  content: '\E969'; }

.lnr-play-circle:before {
  content: '\E96A'; }

.lnr-pause-circle:before {
  content: '\E96B'; }

.lnr-next-circle:before {
  content: '\E96C'; }

.lnr-last-circle:before {
  content: '\E96D'; }

.lnr-forward-circle:before {
  content: '\E96E'; }

.lnr-eject-circle:before {
  content: '\E96F'; }

.lnr-crop:before {
  content: '\E970'; }

.lnr-frame-expand:before {
  content: '\E971'; }

.lnr-frame-contract:before {
  content: '\E972'; }

.lnr-focus:before {
  content: '\E973'; }

.lnr-transform:before {
  content: '\E974'; }

.lnr-grid:before {
  content: '\E975'; }

.lnr-grid-crossed:before {
  content: '\E976'; }

.lnr-layers:before {
  content: '\E977'; }

.lnr-layers-crossed:before {
  content: '\E978'; }

.lnr-toggle:before {
  content: '\E979'; }

.lnr-rulers:before {
  content: '\E97A'; }

.lnr-ruler:before {
  content: '\E97B'; }

.lnr-funnel:before {
  content: '\E97C'; }

.lnr-flip-horizontal:before {
  content: '\E97D'; }

.lnr-flip-vertical:before {
  content: '\E97E'; }

.lnr-flip-horizontal2:before {
  content: '\E97F'; }

.lnr-flip-vertical2:before {
  content: '\E980'; }

.lnr-angle:before {
  content: '\E981'; }

.lnr-angle2:before {
  content: '\E982'; }

.lnr-subtract:before {
  content: '\E983'; }

.lnr-combine:before {
  content: '\E984'; }

.lnr-intersect:before {
  content: '\E985'; }

.lnr-exclude:before {
  content: '\E986'; }

.lnr-align-center-vertical:before {
  content: '\E987'; }

.lnr-align-right:before {
  content: '\E988'; }

.lnr-align-bottom:before {
  content: '\E989'; }

.lnr-align-left:before {
  content: '\E98A'; }

.lnr-align-center-horizontal:before {
  content: '\E98B'; }

.lnr-align-top:before {
  content: '\E98C'; }

.lnr-square:before {
  content: '\E98D'; }

.lnr-plus-square:before {
  content: '\E98E'; }

.lnr-minus-square:before {
  content: '\E98F'; }

.lnr-percent-square:before {
  content: '\E990'; }

.lnr-arrow-up-square:before {
  content: '\E991'; }

.lnr-arrow-down-square:before {
  content: '\E992'; }

.lnr-arrow-left-square:before {
  content: '\E993'; }

.lnr-arrow-right-square:before {
  content: '\E994'; }

.lnr-chevron-up-square:before {
  content: '\E995'; }

.lnr-chevron-down-square:before {
  content: '\E996'; }

.lnr-chevron-left-square:before {
  content: '\E997'; }

.lnr-chevron-right-square:before {
  content: '\E998'; }

.lnr-check-square:before {
  content: '\E999'; }

.lnr-cross-square:before {
  content: '\E99A'; }

.lnr-menu-square:before {
  content: '\E99B'; }

.lnr-prohibited:before {
  content: '\E99C'; }

.lnr-circle:before {
  content: '\E99D'; }

.lnr-radio-button:before {
  content: '\E99E'; }

.lnr-ligature:before {
  content: '\E99F'; }

.lnr-text-format:before {
  content: '\E9A0'; }

.lnr-text-format-remove:before {
  content: '\E9A1'; }

.lnr-text-size:before {
  content: '\E9A2'; }

.lnr-bold:before {
  content: '\E9A3'; }

.lnr-italic:before {
  content: '\E9A4'; }

.lnr-underline:before {
  content: '\E9A5'; }

.lnr-strikethrough:before {
  content: '\E9A6'; }

.lnr-highlight:before {
  content: '\E9A7'; }

.lnr-text-align-left:before {
  content: '\E9A8'; }

.lnr-text-align-center:before {
  content: '\E9A9'; }

.lnr-text-align-right:before {
  content: '\E9AA'; }

.lnr-text-align-justify:before {
  content: '\E9AB'; }

.lnr-line-spacing:before {
  content: '\E9AC'; }

.lnr-indent-increase:before {
  content: '\E9AD'; }

.lnr-indent-decrease:before {
  content: '\E9AE'; }

.lnr-text-wrap:before {
  content: '\E9AF'; }

.lnr-pilcrow:before {
  content: '\E9B0'; }

.lnr-direction-ltr:before {
  content: '\E9B1'; }

.lnr-direction-rtl:before {
  content: '\E9B2'; }

.lnr-page-break:before {
  content: '\E9B3'; }

.lnr-page-break2:before {
  content: '\E9B4'; }

.lnr-sort-alpha-asc:before {
  content: '\E9B5'; }

.lnr-sort-alpha-desc:before {
  content: '\E9B6'; }

.lnr-sort-numeric-asc:before {
  content: '\E9B7'; }

.lnr-sort-numeric-desc:before {
  content: '\E9B8'; }

.lnr-sort-amount-asc:before {
  content: '\E9B9'; }

.lnr-sort-amount-desc:before {
  content: '\E9BA'; }

.lnr-sort-time-asc:before {
  content: '\E9BB'; }

.lnr-sort-time-desc:before {
  content: '\E9BC'; }

.lnr-sigma:before {
  content: '\E9BD'; }

.lnr-pencil-line:before {
  content: '\E9BE'; }

.lnr-hand:before {
  content: '\E9BF'; }

.lnr-pointer-up:before {
  content: '\E9C0'; }

.lnr-pointer-right:before {
  content: '\E9C1'; }

.lnr-pointer-down:before {
  content: '\E9C2'; }

.lnr-pointer-left:before {
  content: '\E9C3'; }

.lnr-finger-tap:before {
  content: '\E9C4'; }

.lnr-fingers-tap:before {
  content: '\E9C5'; }

.lnr-reminder:before {
  content: '\E9C6'; }

.lnr-fingers-crossed:before {
  content: '\E9C7'; }

.lnr-fingers-victory:before {
  content: '\E9C8'; }

.lnr-gesture-zoom:before {
  content: '\E9C9'; }

.lnr-gesture-pinch:before {
  content: '\E9CA'; }

.lnr-fingers-scroll-horizontal:before {
  content: '\E9CB'; }

.lnr-fingers-scroll-vertical:before {
  content: '\E9CC'; }

.lnr-fingers-scroll-left:before {
  content: '\E9CD'; }

.lnr-fingers-scroll-right:before {
  content: '\E9CE'; }

.lnr-hand2:before {
  content: '\E9CF'; }

.lnr-pointer-up2:before {
  content: '\E9D0'; }

.lnr-pointer-right2:before {
  content: '\E9D1'; }

.lnr-pointer-down2:before {
  content: '\E9D2'; }

.lnr-pointer-left2:before {
  content: '\E9D3'; }

.lnr-finger-tap2:before {
  content: '\E9D4'; }

.lnr-fingers-tap2:before {
  content: '\E9D5'; }

.lnr-reminder2:before {
  content: '\E9D6'; }

.lnr-gesture-zoom2:before {
  content: '\E9D7'; }

.lnr-gesture-pinch2:before {
  content: '\E9D8'; }

.lnr-fingers-scroll-horizontal2:before {
  content: '\E9D9'; }

.lnr-fingers-scroll-vertical2:before {
  content: '\E9DA'; }

.lnr-fingers-scroll-left2:before {
  content: '\E9DB'; }

.lnr-fingers-scroll-right2:before {
  content: '\E9DC'; }

.lnr-fingers-scroll-vertical3:before {
  content: '\E9DD'; }

.lnr-border-style:before {
  content: '\E9DE'; }

.lnr-border-all:before {
  content: '\E9DF'; }

.lnr-border-outer:before {
  content: '\E9E0'; }

.lnr-border-inner:before {
  content: '\E9E1'; }

.lnr-border-top:before {
  content: '\E9E2'; }

.lnr-border-horizontal:before {
  content: '\E9E3'; }

.lnr-border-bottom:before {
  content: '\E9E4'; }

.lnr-border-left:before {
  content: '\E9E5'; }

.lnr-border-vertical:before {
  content: '\E9E6'; }

.lnr-border-right:before {
  content: '\E9E7'; }

.lnr-border-none:before {
  content: '\E9E8'; }

.lnr-ellipsis:before {
  content: '\E9E9'; }

body {
  font-size: 14px; }

.hr-separator {
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 1;
  padding: 7px 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  color: #676A6C; }
  .hr-separator:after {
    border-bottom: 1px solid #A3A3A3;
    content: '';
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%; }
  .hr-separator .label {
    padding: 0 0.75em;
    position: relative;
    z-index: 2; }

.thumbnail-container {
  position: relative; }
  .thumbnail-container.blank-slate {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 0.5;
    padding: 4px;
    text-align: center; }
  .thumbnail-container .duration {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    bottom: 2px;
    color: white;
    font-size: 11px;
    left: 2px;
    line-height: 1;
    padding: 2px 6px;
    position: absolute; }

.modal-image {
  margin: auto;
  max-width: 600px; }

button.modal-close {
  background: #FFF;
  border-radius: 50px;
  height: 32px;
  right: 10px;
  position: absolute;
  top: 10px;
  width: 32px; }
  button.modal-close span {
    color: #000;
    position: relative;
    top: -2px; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.btn,
body,
.form-control,
input,
.custom-select,
.custom-file-label {
  font-feature-settings: 'tnum', 'ss01', 'cv08', 'calt', 'liga', 'kern';
  letter-spacing: -0.01em; }
  @supports (font-variation-settings: normal) {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    .btn,
    body,
    .form-control,
    input,
    .custom-select,
    .custom-file-label {
      font-family: 'Inter var', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, 'Helvetica Neue', arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; } }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0; }
  p .subhead,
  h1 .subhead,
  h2 .subhead,
  h3 .subhead,
  h4 .subhead,
  h5 .subhead,
  h6 .subhead {
    font-size: 0.8em; }
  p:last-child,
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child {
    margin-bottom: 0; }

h1,
h2,
h3,
h4 {
  letter-spacing: 0; }

.typography-card h1,
.typography-card h2,
.typography-card h3,
.typography-card h4,
.typography-card h5,
.typography-card h6 {
  margin-bottom: 10px; }

p {
  font-size: 1rem;
  line-height: 1.5; }
  @media screen and (min-width: 760px) {
    p {
      font-size: 0.875rem;
      line-height: 1.42857; } }

pre {
  margin: 0; }
  pre .react-syntax-highlighter-line-number {
    opacity: 0.5; }

.highlight {
  background-color: #5BA462;
  color: #FFF; }

.page-subhead {
  margin: 0 0 20px; }
  .page-subhead.subhead {
    font-size: 14px;
    color: #999; }
  .page-subhead:last-child {
    margin-bottom: 20px; }

.page-heading {
  margin-bottom: 16px; }
  @media screen and (min-width: 760px) {
    .page-heading {
      margin-bottom: 26px; } }

.section-heading {
  border-bottom: 1px solid #A3A3A3;
  margin: 0 0 20px;
  padding-bottom: 6px; }

.account-page {
  background-color: #F3F7FC; }

.account {
  min-height: 100vh;
  display: flex;
  overflow: visible; }
  .account.account-photo {
    background-size: cover; }
    .account.account-photo .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #FFF; }
    .account.account-photo .form__form-group-icon,
    .account.account-photo .form__form-group-button {
      background: transparent; }
    .account.account-photo .form__form-group-icon,
    .account.account-photo input:not(:last-child) {
      border-right: none; }
    .account.account-photo .form__form-group-button,
    .account.account-photo input {
      border-left: none; }
    .account.account-photo .form__form-group-button.active {
      border-color: #EFF1F5; }
      .account.account-photo .form__form-group-button.active svg {
        fill: #5BA462; }
    .account.account-photo input {
      color: #FFF; }
      .account.account-photo input:focus {
        border-color: #EFF1F5; }
    .account.account-photo p {
      color: #FFF; }
    .account.account-photo .account__title {
      color: #FFF; }
  .account .account__wrapper {
    margin: 20px auto;
    position: relative;
    z-index: 1; }
  .account .account__head,
  .account .account-form {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 16px;
    padding-top: 16px; }
    @media screen and (min-width: 760px) {
      .account .account__head,
      .account .account-form {
        padding-left: 40px;
        padding-right: 40px; } }
    @media screen and (min-width: 1200px) {
      .account .account__head,
      .account .account-form {
        padding-left: 60px;
        padding-right: 60px; } }
    @media screen and (min-width: 760px) {
      .account .account__head,
      .account .account-form {
        padding-bottom: 20px;
        padding-top: 20px; } }
    @media screen and (min-width: 1200px) {
      .account .account__head,
      .account .account-form {
        padding-bottom: 30px;
        padding-top: 30px; } }
  .account .account__card {
    background-color: #F6F6F6;
    box-shadow: 0 80px 200px rgba(0, 0, 0, 0.1), 0 4px 32px rgba(0, 0, 0, 0.04);
    margin: auto;
    max-width: 520px;
    width: 100%; }
  .account .account__head {
    background-color: #333; }
    .account .account__head .logo {
      display: block;
      margin: 0 auto;
      max-width: 290px;
      width: 100%; }
  .account .account__logo-accent {
    color: #404DC1; }
  .account .account__have-account {
    text-align: center;
    margin-top: 20px; }
    .account .account__have-account a {
      color: #404DC1;
      transition: all 0.3s; }
      .account .account__have-account a:hover {
        color: #3540a3;
        text-decoration: none; }
  .account .artwork-container {
    position: relative;
    z-index: 0; }
    .account .artwork-container .artwork {
      margin: 7% auto 0;
      max-height: 90vh; }

.btn-toolbar {
  margin-top: 1rem; }
  .btn-toolbar > .btn {
    margin-right: 1rem;
    margin-bottom: 1rem; }
    .btn-toolbar > .btn:last-child {
      margin-right: 0; }
  .btn-toolbar:first-child {
    margin-top: 0; }
  .btn-toolbar.btn-toolbar--center > * {
    margin-right: auto;
    margin-left: auto; }

.theme-light .btn-group {
  background-color: #F9F9F9; }

.theme-dark .btn-group {
  background-color: #1C1C21; }

.theme-lightondark .btn-group {
  background-color: white; }

.btn-group .btn {
  margin-right: 0; }

.btn-group.justified {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .btn-group.justified .btn {
    width: 100%; }

.btn-group.icons .btn {
  padding: 7px 8px;
  line-height: 14px; }

.btn-group.open .dropdown-toggle {
  box-shadow: none; }

.unstyled-button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  font-size: 1em;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left; }
  .unstyled-button:hover, .unstyled-button:focus, .unstyled-button:active {
    background: none;
    color: inherit; }

@media screen and (min-width: 760px) {
  input,
  .form-control,
  input {
    font-size: 0.875rem; } }

input[readonly]:not([disabled]),
.form-control[readonly]:not([disabled]),
input[readonly]:not([disabled]) {
  background: #FFF;
  border-color: #A3A3A3; }

select,
select.form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url(/static/media/select-input-icon.7378000f.svg);
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 8px 12px;
  padding: 0.375rem 1.5rem 0.375rem 0.65rem; }

select:focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.5); }

.form-control.minimal-select, input.minimal-select {
  -moz-appearance: none;
       appearance: none;
  background: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #444647;
  display: inline-block;
  height: auto;
  line-height: inherit;
  min-width: 0;
  padding: 0 1.5em 0 0;
  width: auto;
  -webkit-appearance: none; }
  .form-control.minimal-select + .fa-chevron-down, input.minimal-select + .fa-chevron-down {
    transform: translateX(-1em); }

@media screen and (min-width: 760px) {
  form .horizontal label,
  form .horizontal .label {
    text-align: right; } }

@media screen and (min-width: 760px) {
  form .horizontal .input-container {
    padding: 9px 0; } }

form .horizontal.toggle-left label,
form .horizontal.toggle-left .label {
  text-align: left; }

form input.input-icon-left,
form textarea.input-icon-left,
form .react-select .select__control.input-icon-left {
  border-radius: 0.2rem;
  padding-left: 44px; }

form input.input-icon-right, form input.form-control:not(:last-child), form input:not(:last-child), form input.custom-select:not(:last-child),
form textarea.input-icon-right,
form textarea.form-control:not(:last-child),
form textarea.custom-select:not(:last-child),
form .react-select .select__control.input-icon-right,
form .react-select .select__control.form-control:not(:last-child),
form .react-select input.select__control:not(:last-child),
form .react-select .select__control.custom-select:not(:last-child) {
  padding-right: 44px; }

form input:placeholder,
form textarea:placeholder,
form .react-select .select__control:placeholder {
  color: #A3A3A3; }

form input:focus, form input:active,
form textarea:focus,
form textarea:active,
form .react-select .select__control:focus,
form .react-select .select__control:active {
  outline: none;
  border-color: #5BA462; }

form textarea {
  min-height: 85px;
  resize: vertical; }
  form textarea.form-control::-webkit-scrollbar {
    box-shadow: -1px 1px 7px rgba(0, 0, 0, 0.15) inset;
    height: auto;
    height: initial;
    padding: 0;
    padding: initial;
    width: auto;
    width: initial; }
  form textarea.form-control::-webkit-scrollbar-thumb {
    border-radius: 0;
    border-radius: initial;
    background-color: rgba(0, 0, 0, 0.2); }

form input[type='radio'],
form input[type='checkbox'] {
  height: auto; }

form .action-group {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px; }

form .input-group-append,
form .input-group-prepend {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 5px 10px;
  position: absolute; }
  form .input-group-append .mdi-icon,
  form .input-group-prepend .mdi-icon {
    margin: 0; }
  form .input-group-append.btn-icon,
  form .input-group-prepend.btn-icon {
    height: 100%; }

form .input-group-prepend {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0;
  left: 0; }

form .input-group-append {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0.2rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0.2rem;
  right: 0; }

form.form--horizontal .form__form-group {
  display: flex;
  flex-wrap: wrap; }

form.form--horizontal .form__form-group-field {
  width: calc(100% - 80px);
  padding-left: 10px;
  margin-left: 80px; }

form.form--horizontal .form__form-group-label {
  width: 80px;
  margin: auto 0; }
  form.form--horizontal .form__form-group-label + .form__form-group-field {
    margin-left: 0; }

form.form--horizontal .form__form-group-description {
  margin-left: 90px; }

form.form--horizontal .form__button-toolbar {
  margin-left: 0; }

@media screen and (min-width: 480px) {
  form.form--horizontal .form__form-group-label {
    width: 120px; }
  form.form--horizontal .form__form-group-field {
    width: calc(100% - 120px);
    margin-left: 120px;
    padding-left: 20px; }
  form.form--horizontal .form__form-group-description,
  form.form--horizontal .form__button-toolbar {
    margin-left: 140px; } }

form .form--preview {
  display: flex; }
  form .form--preview > div:nth-child(2) {
    margin-right: 50px; }
  form .form--preview .form__form-group {
    margin-bottom: 10px;
    width: auto;
    min-height: 18px; }
  form .form--preview .form__select-color {
    display: flex;
    flex-wrap: wrap;
    max-width: 84px;
    margin-right: 40px; }
  form .form--preview p {
    margin-bottom: 10px; }

form.form--justify {
  display: flex;
  flex-wrap: wrap; }
  form.form--justify .form__form-group {
    width: 33.3333%; }
  form.form--justify .form__button-toolbar {
    width: 100%; }

form .form__form-group-select {
  width: 100%;
  height: 32px; }
  form .form__form-group-select .Select-control {
    height: 32px;
    border-radius: 0;
    transition: all 0.3s;
    background: transparent; }
    .theme-light form .form__form-group-select .Select-control {
      border: 1px solid #F2F4F7; }
    .theme-dark form .form__form-group-select .Select-control {
      border: 1px solid #D3DADE; }
    .theme-lightondark form .form__form-group-select .Select-control {
      border: 1px solid #A3A3A3; }
  form .form__form-group-select .Select-placeholder,
  form .form__form-group-select .Select-input {
    height: 30px; }
  form .form__form-group-select .Select-input {
    width: 100%; }
  form .form__form-group-select input {
    width: 100% !important;
    border: none;
    padding: 0; }
  form .form__form-group-select .Select-multi-value-wrapper .Select-input {
    width: inherit; }
  form .form__form-group-select .Select-multi-value-wrapper .Select-value {
    background: transparent;
    border-color: #404DC1; }
  .theme-light form .form__form-group-select .Select-multi-value-wrapper .Select-value-label {
    color: #A3A3A3 !important; }
  .theme-dark form .form__form-group-select .Select-multi-value-wrapper .Select-value-label {
    color: white !important; }
  .theme-lightondark form .form__form-group-select .Select-multi-value-wrapper .Select-value-label {
    color: #444647 !important; }
  form .form__form-group-select .Select-multi-value-wrapper .Select-value-icon {
    border-color: #404DC1; }
  form .form__form-group-select .Select-menu-outer {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    -webkit-animation: open 0.3s ease-in-out;
            animation: open 0.3s ease-in-out;
    overflow: hidden; }
    .theme-light form .form__form-group-select .Select-menu-outer {
      border: 1px solid #F9F9F9;
      background: #F9F9F9; }
    .theme-dark form .form__form-group-select .Select-menu-outer {
      border: 1px solid #EBEBEB;
      background: #1C1C21; }
    .theme-lightondark form .form__form-group-select .Select-menu-outer {
      border: 1px solid #A3A3A3;
      background: white; }

@-webkit-keyframes open {
  0% {
    max-height: 0; }
  100% {
    max-height: 200px; } }

@keyframes open {
  0% {
    max-height: 0; }
  100% {
    max-height: 200px; } }
  form .form__form-group-select .Select-option {
    transition: all 0.3s;
    border-radius: 0;
    display: flex; }
    .theme-light form .form__form-group-select .Select-option {
      background: #F9F9F9;
      color: #A3A3A3; }
    .theme-dark form .form__form-group-select .Select-option {
      background: #1C1C21;
      color: white; }
    .theme-lightondark form .form__form-group-select .Select-option {
      background: white;
      color: #444647; }
    .theme-light form .form__form-group-select .Select-option.is-focused {
      background: #F9F9F9; }
    .theme-dark form .form__form-group-select .Select-option.is-focused {
      background: #1C1C21; }
    .theme-lightondark form .form__form-group-select .Select-option.is-focused {
      background: white; }
  form .form__form-group-select.is-focused .Select-control, form .form__form-group-select.is-focused:not(.is-open) .Select-control {
    border-color: #5BA462;
    box-shadow: none;
    background: transparent; }
  form .form__form-group-select .form__form-group-select-color {
    display: block;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin: auto 0 auto 5px; }

.form__form-group {
  padding-bottom: 12px;
  padding-top: 7px;
  position: relative;
  width: 100%; }
  .theme-light .form__form-group {
    color: #A3A3A3;
    border-bottom: 1px solid #F2F4F7; }
  .theme-dark .form__form-group {
    color: white;
    border-bottom: 1px solid #D3DADE; }
  .theme-lightondark .form__form-group {
    color: #444647;
    border-bottom: 1px solid #A3A3A3; }
  @media screen and (min-width: 760px) {
    .form__form-group {
      padding-bottom: 9px;
      padding-top: 9px; }
      .form__form-group .align-items-top .form__form-group-label {
        padding-top: 16px; }
      .form__form-group.optional .align-items-top .form__form-group-label {
        padding-top: 7px; } }
  .form__form-group.stacked {
    padding-bottom: 0; }
  .form__form-group.toggle-container {
    padding-bottom: 7px; }
  .form__form-group.borderless, .form__form-group:last-of-type {
    border-bottom: 0; }

.form__form-group--address input:last-child {
  margin-left: 15px;
  width: 70%; }

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  position: relative; }
  .form__form-group-field .form-control, .form__form-group-field input,
  .form__form-group-field input {
    height: 36px; }

.form__form-group-label {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 6px;
  padding-top: 6px; }
  @media screen and (min-width: 760px) {
    .form__form-group-label {
      padding-bottom: 9px;
      padding-top: 9px; } }
  .theme-light .form__form-group-label {
    color: #A3A3A3; }
  .theme-dark .form__form-group-label {
    color: white; }
  .theme-lightondark .form__form-group-label {
    color: #444647; }
  .form__form-group-label .form__form-group-help-text,
  .form__form-group-label .form__form-group-optional-flag {
    color: #A3A3A3; }

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s; }
  .form__form-group-button svg {
    width: 18px;
    height: 18px;
    transition: all 0.3s; }
  .form__form-group-button.active {
    background: #5BA462;
    border: 1px solid #5BA462; }
    .form__form-group-button.active svg {
      fill: #FFF; }

.form__form-group-file label {
  border-radius: 2px;
  line-height: 18px;
  padding: 4px 20px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center; }
  .theme-light .form__form-group-file label {
    border: 1px solid #F2F4F7;
    color: #A3A3A3; }
  .theme-dark .form__form-group-file label {
    border: 1px solid #D3DADE;
    color: white; }
  .theme-lightondark .form__form-group-file label {
    border: 1px solid #A3A3A3;
    color: #444647; }
  .theme-light .form__form-group-file label:hover {
    background: #F2F4F7; }
  .theme-dark .form__form-group-file label:hover {
    background: #D3DADE; }
  .theme-lightondark .form__form-group-file label:hover {
    background: #A3A3A3; }

.form__form-group-file span {
  padding-left: 10px; }

.form__form-group-file input {
  display: none; }

.file-preview {
  align-content: center;
  border-radius: 0.25rem;
  border: 1px solid #A3A3A3;
  display: flex;
  height: 100px;
  justify-content: center;
  margin-bottom: 12px;
  margin-right: 12px;
  position: relative;
  width: 100px; }
  .file-preview:before {
    background-color: rgba(25, 25, 25, 0.6);
    bottom: 0;
    content: '';
    cursor: default;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.25s ease-in-out;
    width: 100%;
    z-index: 2; }
  .file-preview:hover:before {
    opacity: 1; }
  .file-preview:hover .remove,
  .file-preview:hover .file-name {
    opacity: 1; }
  .file-preview .image-component {
    border-radius: 0.1875rem; }
  .file-preview .file-thumbnail {
    overflow: hidden;
    z-index: 1; }
  .file-preview .file-name {
    color: white;
    display: block;
    margin: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.25s ease-in-out;
    width: 100%;
    z-index: 3; }
  .file-preview .remove {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    transition: opacity 0.25s ease-in-out;
    z-index: 3; }
  .file-preview .placeholder-icon {
    color: #A3A3A3;
    font-size: 48px;
    margin: auto;
    transform: translateY(-1px); }

.form__form-group-icon {
  height: 32px;
  padding: 6px;
  pointer-events: none; }
  .theme-light .form__form-group-icon {
    background: white;
    border: 1px solid #A3A3A3; }
  .theme-dark .form__form-group-icon {
    background: #232329;
    border: 1px solid #A3A3A3; }
  .theme-lightondark .form__form-group-icon {
    background: #F9F9F9;
    border: 1px solid #A3A3A3; }
  .form__form-group-icon svg {
    fill: #7d7d7d;
    width: 18px;
    height: 18px;
    transition: all 0.3s; }

.form__form-group-description {
  font-size: 10px;
  color: #EBEBEB;
  line-height: 13px;
  margin-top: 2px; }

.form__button-toolbar {
  margin-top: 10px; }

.form__form-group-input-wrap {
  width: 100%; }

.tab-pane .form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #AD4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 45px;
  -webkit-animation: slideInDown 0.3s forwards;
          animation: slideInDown 0.3s forwards; }
  .tab-pane .form__form-group-error.input-prepend {
    left: 36px; }

.form__form-group-error {
  position: absolute;
  -webkit-animation: slideInDown 0.3s forwards;
          animation: slideInDown 0.3s forwards; }

.form__form-group-date-cvc {
  display: flex;
  width: 100%; }
  .form__form-group-date-cvc .form__form-group-date {
    width: 100%;
    margin-right: 20px; }
  .form__form-group-date-cvc .form__form-group-cvc {
    max-width: 280px;
    width: 100%; }
  @media screen and (max-width: 767px) {
    .form__form-group-date-cvc {
      flex-wrap: wrap; }
      .form__form-group-date-cvc .form__form-group-date {
        margin-right: 0; }
      .form__form-group-date-cvc .form__form-group-cvc {
        max-width: 100%; } }

.form__form-group-id-category {
  width: 100%;
  display: flex; }
  .form__form-group-id-category .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .form__form-group-id-category {
      flex-wrap: wrap; }
      .form__form-group-id-category .form__form-group-id {
        margin-right: 0;
        width: 100%; } }

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px; }
  .form__form-group-price-discount > div {
    margin-bottom: 0; }
  .form__form-group-price-discount .form__form-group-price {
    margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .form__form-group-price-discount {
      flex-wrap: wrap; }
      .form__form-group-price-discount .form__form-group-price {
        margin-right: 0; } }

.radio-button-container .input-container {
  float: left; }

.was-validated .form-control:valid, .was-validated input:valid,
.was-validated input:valid,
.form-control.is-valid,
input.is-valid,
input.is-valid,
.was-validated .form-control:invalid,
.was-validated input:invalid,
.was-validated input:invalid,
.form-control.is-invalid,
input.is-invalid,
input.is-invalid,
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid,
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background-position: right 4px top 7px; }

.highlight-actions {
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -16px;
  margin-top: -16px; }
  @media screen and (min-width: 760px) {
    .highlight-actions {
      margin-left: -10px;
      margin-right: -10px; } }
  @media screen and (min-width: 1200px) {
    .highlight-actions {
      margin-left: -15px;
      margin-right: -15px; } }
  @media screen and (min-width: 760px) {
    .highlight-actions {
      margin-bottom: -20px;
      margin-top: -20px; } }
  @media screen and (min-width: 1200px) {
    .highlight-actions {
      margin-bottom: -30px;
      margin-top: -30px; } }
  .highlight-actions > .row > [class*='col-'] {
    padding-bottom: 16px;
    padding-top: 16px; }
    @media screen and (min-width: 760px) {
      .highlight-actions > .row > [class*='col-'] {
        padding-bottom: 20px;
        padding-top: 20px; } }
    @media screen and (min-width: 1200px) {
      .highlight-actions > .row > [class*='col-'] {
        padding-bottom: 30px;
        padding-top: 30px; } }
  .highlight-actions .actions-container {
    background-color: white;
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    min-height: calc(100vh - 90px); }
    @media screen and (min-width: 992px) {
      .highlight-actions .actions-container {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.4rem; } }

.help-text {
  color: #A3A3A3;
  transition: margin-bottom 0.3s ease-in-out;
  will-change: margin-bottom; }

.new-password-field-container {
  position: relative;
  z-index: 2; }

@-webkit-keyframes slideInDown {
  0% {
    transform: translateY(-25px); }
  70% {
    transform: translateY(0); } }

@keyframes slideInDown {
  0% {
    transform: translateY(-25px); }
  70% {
    transform: translateY(0); } }

.container__wrap {
  padding-left: 0;
  padding-top: 68px;
  padding-bottom: 16px;
  min-height: 100vh;
  transition: padding-left 0.3s; }
  .theme-light .container__wrap {
    background: white;
    color: #A3A3A3; }
  .theme-dark .container__wrap {
    background: #333;
    color: white; }
  .theme-lightondark .container__wrap {
    background: #333;
    color: white; }
  @media screen and (min-width: 570px) {
    .container__wrap {
      padding-left: 244px;
      padding-right: 8px; } }
  @media screen and (min-width: 760px) {
    .container__wrap {
      padding-left: 246px;
      padding-top: 73px; } }
  @media screen and (min-width: 1200px) {
    .container__wrap {
      padding-left: 251px; } }

.layout.layout--collapse + .container__wrap {
  padding-left: 0; }
  @media screen and (min-width: 570px) {
    .layout.layout--collapse + .container__wrap {
      padding-left: 68px; } }
  @media screen and (min-width: 760px) {
    .layout.layout--collapse + .container__wrap {
      padding-left: 70px; } }
  @media screen and (min-width: 1200px) {
    .layout.layout--collapse + .container__wrap {
      padding-left: 75px; } }

@media screen and (min-width: 570px) {
  .layout.layout--top-navigation + .container__wrap {
    padding-left: 0; } }

.not-found {
  text-align: center;
  height: 100vh;
  overflow: auto;
  display: flex;
  background-size: cover; }
  .not-found button {
    margin: 0; }

.not-found__content {
  margin: auto;
  padding: 10px; }

.not-found .not-found__info {
  color: #FFF;
  margin-bottom: 20px;
  margin-top: 90px; }

.not-found__image {
  max-width: 500px;
  width: 100%; }

.daily-summary {
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  overflow-x: auto;
  overflow-y: visible; }
  @media screen and (min-width: 760px) {
    .daily-summary {
      margin-left: -20px;
      margin-right: -20px; } }
  @media screen and (min-width: 1200px) {
    .daily-summary {
      margin-left: -30px;
      margin-right: -30px; } }
  .daily-summary:after {
    margin-left: 14.4px;
    color: transparent;
    content: '\A0';
    display: block;
    font-size: 0.1px;
    height: 1px;
    margin-right: -3px;
    opacity: 0;
    visibility: hidden;
    width: 0; }
    @media screen and (min-width: 760px) {
      .daily-summary:after {
        margin-left: 18px; } }
    @media screen and (min-width: 1200px) {
      .daily-summary:after {
        margin-left: 27px; } }
  .daily-summary .story-summary {
    margin-left: 16px;
    margin-bottom: 10px; }
    @media screen and (min-width: 760px) {
      .daily-summary .story-summary {
        margin-left: 20px; } }
    @media screen and (min-width: 1200px) {
      .daily-summary .story-summary {
        margin-left: 30px; } }

.schedule-heading {
  border-top: 1px solid #d1d1d1;
  color: #676A6C;
  margin: 0;
  padding: 16px 0; }

/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #404DC1;
  --indigo: #39147C;
  --purple: #AB6DC8;
  --pink: #D56FAC;
  --red: #DD3838;
  --orange: #FC723C;
  --yellow: #F8E169;
  --green: #5BA462;
  --teal: #64C9B7;
  --cyan: #38A0B9;
  --white: #FFF;
  --gray: #6C757D;
  --gray-dark: #343A40;
  --primary: #5BA462;
  --secondary: #A3A3A3;
  --success: #5BA462;
  --info: #38A0B9;
  --warning: #FC723C;
  --danger: #DD3838;
  --light: #F9F9F9;
  --dark: #333;
  --breakpoint-xs: 0;
  --breakpoint-sm: 570px;
  --breakpoint-md: 760px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1920px;
  --font-family-sans-serif: "Inter", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: "Fira Code", sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Inter", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFF; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1.5em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #008099;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #00b3d6;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: "Fira Code", sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #A3A3A3;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: calc(30 / 16 * 1rem); }

h2, .h2 {
  font-size: calc(26 / 16 * 1rem); }

h3, .h3 {
  font-size: calc(20 / 16 * 1rem); }

h4, .h4 {
  font-size: calc(16 / 16 * 1rem); }

h5, .h5 {
  font-size: calc(13 / 16 * 1rem); }

h6, .h6 {
  font-size: calc(11 / 16 * 1rem); }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #A3A3A3; }

small,
.small {
  font-size: 0.75rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #FCF8E3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.75rem;
  color: #A3A3A3; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFF;
  border: 1px solid #DEE2E6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6C757D; }

code {
  font-size: inherit;
  color: inherit;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: inherit;
  color: #FFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: inherit;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 570px) {
    .container {
      max-width: 1664px; } }

.container-fluid, .container-sm {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 570px) {
  .container, .container-sm {
    max-width: 1664px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 570px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 760px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #DEE2E6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #DEE2E6; }
  .table tbody + tbody {
    border-top: 2px solid #DEE2E6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #DEE2E6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #DEE2E6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d1e6d3; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #aad0ad; }

.table-hover .table-primary:hover {
  background-color: #c0ddc3; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c0ddc3; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e5e5e5; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #cfcfcf; }

.table-hover .table-secondary:hover {
  background-color: #d8d8d8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d8d8d8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d1e6d3; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #aad0ad; }

.table-hover .table-success:hover {
  background-color: #c0ddc3; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c0ddc3; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c7e4eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #98cedb; }

.table-hover .table-info:hover {
  background-color: #b4dbe4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b4dbe4; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fed8c8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fdb69a; }

.table-hover .table-warning:hover {
  background-color: #fec6af; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fec6af; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c7c7; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed9898; }

.table-hover .table-danger:hover {
  background-color: #f1b1b1; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b1b1; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #f0f0f0; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f0f0f0; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c6c6; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #959595; }

.table-hover .table-dark:hover {
  background-color: #b9b9b9; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9b9b9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFF;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #E9ECEF;
  border-color: #DEE2E6; }

.table-dark {
  color: #FFF;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 569.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 759.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1919.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }


.form-control,
input {
  display: block;
  width: 100%;
  height: calc(1.375 * 1em + 0.375rem * 2 + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #212529;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #A3A3A3;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    
    .form-control,
    input {
      transition: none; } }
  .form-control::-ms-expand, input::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, input:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }
  .form-control:focus, input:focus {
    color: #212529;
    background-color: #FFF;
    border-color: #add2b1;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }
  .form-control::-webkit-input-placeholder, input::-webkit-input-placeholder {
    color: #A3A3A3;
    opacity: 1; }
  .form-control::-ms-input-placeholder, input::-ms-input-placeholder {
    color: #A3A3A3;
    opacity: 1; }
  .form-control::placeholder, input::placeholder {
    color: #A3A3A3;
    opacity: 1; }
  .form-control:disabled, input:disabled, .form-control[readonly], input[readonly] {
    background-color: #E9ECEF;
    opacity: 1; }

input[type="date"].form-control, input[type="date"],
input[type="time"].form-control,
input[type="time"],
input[type="datetime-local"].form-control,
input[type="datetime-local"],
input[type="month"].form-control,
input[type="month"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #212529;
  background-color: #FFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.375; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.2rem + 1px);
  padding-bottom: calc(0.2rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.375;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.4rem + 2px);
  padding: 0.2rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #A3A3A3; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #5BA462; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #FFF;
  background-color: rgba(91, 164, 98, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated input:valid,
.form-control.is-valid,
input.is-valid {
  border-color: #5BA462;
  padding-right: 1.375;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235BA462' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.34375em + 0.1875rem) center;
  background-size: calc(0.6875em + 0.375rem) calc(0.6875em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated input:valid:focus, .form-control.is-valid:focus, input.is-valid:focus {
    border-color: #5BA462;
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }


.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: 1.375;
  background-position: top calc(0.34375em + 0.1875rem) right calc(0.34375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5BA462;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343A40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235BA462' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFF no-repeat center right 1.75rem/calc(0.6875em + 0.375rem) calc(0.6875em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #5BA462;
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5BA462; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5BA462; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #5BA462; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #7cb681;
  background-color: #7cb681; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5BA462; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5BA462; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5BA462;
  box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #D97059; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #FFF;
  background-color: rgba(217, 112, 89, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }


.was-validated .form-control:invalid,
.was-validated input:invalid,
.form-control.is-invalid,
input.is-invalid {
  border-color: #D97059;
  padding-right: 1.375;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D97059' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D97059' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.34375em + 0.1875rem) center;
  background-size: calc(0.6875em + 0.375rem) calc(0.6875em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated input:invalid:focus, .form-control.is-invalid:focus, input.is-invalid:focus {
    border-color: #D97059;
    box-shadow: 0 0 0 0.2rem rgba(217, 112, 89, 0.25); }


.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: 1.375;
  background-position: top calc(0.34375em + 0.1875rem) right calc(0.34375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #D97059;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343A40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D97059' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D97059' stroke='none'/%3e%3c/svg%3e") #FFF no-repeat center right 1.75rem/calc(0.6875em + 0.375rem) calc(0.6875em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #D97059;
    box-shadow: 0 0 0 0.2rem rgba(217, 112, 89, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #D97059; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #D97059; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #D97059; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e39483;
  background-color: #e39483; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 112, 89, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #D97059; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #D97059; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #D97059;
  box-shadow: 0 0 0 0.2rem rgba(217, 112, 89, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 570px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline input {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFF;
  background-color: #5BA462;
  border-color: #5BA462; }
  .btn-primary:hover {
    color: #FFF;
    background-color: #4d8b53;
    border-color: #49834e; }
  .btn-primary:focus, .btn-primary.focus {
    color: #FFF;
    background-color: #4d8b53;
    border-color: #49834e;
    box-shadow: 0 0 0 0.2rem rgba(116, 178, 122, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFF;
    background-color: #5BA462;
    border-color: #5BA462; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFF;
    background-color: #49834e;
    border-color: #447b4a; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 178, 122, 0.5); }

.btn-secondary {
  color: #FFF;
  background-color: #A3A3A3;
  border-color: #A3A3A3; }
  .btn-secondary:hover {
    color: #FFF;
    background-color: #909090;
    border-color: #8a8a8a; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #FFF;
    background-color: #909090;
    border-color: #8a8a8a;
    box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFF;
    background-color: #A3A3A3;
    border-color: #A3A3A3; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFF;
    background-color: #8a8a8a;
    border-color: #838383; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5); }

.btn-success {
  color: #FFF;
  background-color: #5BA462;
  border-color: #5BA462; }
  .btn-success:hover {
    color: #FFF;
    background-color: #4d8b53;
    border-color: #49834e; }
  .btn-success:focus, .btn-success.focus {
    color: #FFF;
    background-color: #4d8b53;
    border-color: #49834e;
    box-shadow: 0 0 0 0.2rem rgba(116, 178, 122, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFF;
    background-color: #5BA462;
    border-color: #5BA462; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFF;
    background-color: #49834e;
    border-color: #447b4a; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 178, 122, 0.5); }

.btn-info {
  color: #FFF;
  background-color: #38A0B9;
  border-color: #38A0B9; }
  .btn-info:hover {
    color: #FFF;
    background-color: #2f879c;
    border-color: #2c7e92; }
  .btn-info:focus, .btn-info.focus {
    color: #FFF;
    background-color: #2f879c;
    border-color: #2c7e92;
    box-shadow: 0 0 0 0.2rem rgba(86, 174, 196, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFF;
    background-color: #38A0B9;
    border-color: #38A0B9; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFF;
    background-color: #2c7e92;
    border-color: #297688; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(86, 174, 196, 0.5); }

.btn-warning {
  color: #FFF;
  background-color: #FC723C;
  border-color: #FC723C; }
  .btn-warning:hover {
    color: #FFF;
    background-color: #fb5716;
    border-color: #fb4e0a; }
  .btn-warning:focus, .btn-warning.focus {
    color: #FFF;
    background-color: #fb5716;
    border-color: #fb4e0a;
    box-shadow: 0 0 0 0.2rem rgba(252, 135, 89, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #FFF;
    background-color: #FC723C;
    border-color: #FC723C; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #FFF;
    background-color: #fb4e0a;
    border-color: #f44704; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 135, 89, 0.5); }

.btn-danger {
  color: #FFF;
  background-color: #DD3838;
  border-color: #DD3838; }
  .btn-danger:hover {
    color: #FFF;
    background-color: #cc2323;
    border-color: #c12121; }
  .btn-danger:focus, .btn-danger.focus {
    color: #FFF;
    background-color: #cc2323;
    border-color: #c12121;
    box-shadow: 0 0 0 0.2rem rgba(226, 86, 86, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFF;
    background-color: #DD3838;
    border-color: #DD3838; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFF;
    background-color: #c12121;
    border-color: #b61f1f; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(226, 86, 86, 0.5); }

.btn-light {
  color: #444647;
  background-color: #F9F9F9;
  border-color: #F9F9F9; }
  .btn-light:hover {
    color: #444647;
    background-color: #e6e6e6;
    border-color: #e0e0e0; }
  .btn-light:focus, .btn-light.focus {
    color: #444647;
    background-color: #e6e6e6;
    border-color: #e0e0e0;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #444647;
    background-color: #F9F9F9;
    border-color: #F9F9F9; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #444647;
    background-color: #e0e0e0;
    border-color: #d9d9d9; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }

.btn-dark {
  color: #FFF;
  background-color: #333;
  border-color: #333; }
  .btn-dark:hover {
    color: #FFF;
    background-color: #202020;
    border-color: #1a1a1a; }
  .btn-dark:focus, .btn-dark.focus {
    color: #FFF;
    background-color: #202020;
    border-color: #1a1a1a;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFF;
    background-color: #333;
    border-color: #333; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFF;
    background-color: #1a1a1a;
    border-color: #131313; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }

.btn-outline-primary {
  color: #5BA462;
  border-color: #5BA462; }
  .btn-outline-primary:hover {
    color: #FFF;
    background-color: #5BA462;
    border-color: #5BA462; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #5BA462;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFF;
    background-color: #5BA462;
    border-color: #5BA462; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.5); }

.btn-outline-secondary {
  color: #A3A3A3;
  border-color: #A3A3A3; }
  .btn-outline-secondary:hover {
    color: #FFF;
    background-color: #A3A3A3;
    border-color: #A3A3A3; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #A3A3A3;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFF;
    background-color: #A3A3A3;
    border-color: #A3A3A3; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5); }

.btn-outline-success {
  color: #5BA462;
  border-color: #5BA462; }
  .btn-outline-success:hover {
    color: #FFF;
    background-color: #5BA462;
    border-color: #5BA462; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #5BA462;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFF;
    background-color: #5BA462;
    border-color: #5BA462; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.5); }

.btn-outline-info {
  color: #38A0B9;
  border-color: #38A0B9; }
  .btn-outline-info:hover {
    color: #FFF;
    background-color: #38A0B9;
    border-color: #38A0B9; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(56, 160, 185, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #38A0B9;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFF;
    background-color: #38A0B9;
    border-color: #38A0B9; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(56, 160, 185, 0.5); }

.btn-outline-warning {
  color: #FC723C;
  border-color: #FC723C; }
  .btn-outline-warning:hover {
    color: #FFF;
    background-color: #FC723C;
    border-color: #FC723C; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 114, 60, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FC723C;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #FFF;
    background-color: #FC723C;
    border-color: #FC723C; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 114, 60, 0.5); }

.btn-outline-danger {
  color: #DD3838;
  border-color: #DD3838; }
  .btn-outline-danger:hover {
    color: #FFF;
    background-color: #DD3838;
    border-color: #DD3838; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 56, 56, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #DD3838;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFF;
    background-color: #DD3838;
    border-color: #DD3838; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 56, 56, 0.5); }

.btn-outline-light {
  color: #F9F9F9;
  border-color: #F9F9F9; }
  .btn-outline-light:hover {
    color: #444647;
    background-color: #F9F9F9;
    border-color: #F9F9F9; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #F9F9F9;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #444647;
    background-color: #F9F9F9;
    border-color: #F9F9F9; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.btn-outline-dark {
  color: #333;
  border-color: #333; }
  .btn-outline-dark:hover {
    color: #FFF;
    background-color: #333;
    border-color: #333; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #333;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFF;
    background-color: #333;
    border-color: #333; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.btn-link {
  font-weight: 400;
  color: #008099;
  text-decoration: none; }
  .btn-link:hover {
    color: #00b3d6;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6C757D;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.4rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.375rem 1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.25s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: none;
  float: left;
  min-width: 16.25rem;
  padding: 1.25rem 0;
  margin: -1px 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #2D2D2F;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 570px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 760px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1920px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: -1px; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: -1px; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid inherit; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 0.625rem;
  clear: both;
  font-weight: 400;
  color: inherit;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: inherit;
    text-decoration: none;
    background-color: #008099; }
  .dropdown-item.active, .dropdown-item:active {
    color: inherit;
    text-decoration: none;
    background-color: #5BA462; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #FFF;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 1.25rem 0.625rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 0.625rem;
  color: inherit; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .input-group > input,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .input-group > input + .form-control, .input-group > .form-control + input, .input-group > input + input,
    .input-group > .form-control + .custom-select,
    .input-group > input + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > input + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + input,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + input,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + input,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .input-group > input:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .input-group > input:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .input-group > input:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #E9ECEF;
  border: 1px solid #A3A3A3;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .input-group-lg > input:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .input-group-lg > input,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.input-group-sm > .form-control:not(textarea), .input-group-sm > input:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.4rem + 2px); }

.input-group-sm > .form-control, .input-group-sm > input,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.2rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFF;
    border-color: #5BA462;
    background-color: #5BA462; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #add2b1; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFF;
    background-color: #cee4d0;
    border-color: #cee4d0; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6C757D; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #E9ECEF; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #DEE2E6;
    border: #ADB5BD solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #5BA462;
  background-color: #5BA462; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFF' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(91, 164, 98, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(91, 164, 98, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFF'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(91, 164, 98, 0.5); }

.custom-switch {
  padding-left: 3.25rem; }
  .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-3.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #ADB5BD;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #DEE2E6;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(91, 164, 98, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.375 * 1em + 0.375rem * 2 + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #212529;
  vertical-align: middle;
  background: #FFF url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343A40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #A3A3A3;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #add2b1;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(173, 210, 177, 0.5); }
    .custom-select:focus::-ms-value {
      color: #212529;
      background-color: #FFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6C757D;
    background-color: #E9ECEF; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }

.custom-select-sm {
  height: calc(1.5em + 0.4rem + 2px);
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.75rem;
  font-size: 0.75rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.375 * 1em + 0.375rem * 2 + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.375 * 1em + 0.375rem * 2 + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #add2b1;
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #E9ECEF; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.375 * 1em + 0.375rem * 2 + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.375;
  color: #212529;
  background-color: #FFF;
  border: 1px solid #A3A3A3;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 1.375;
    padding: 0.375rem 0.75rem;
    line-height: 1.375;
    color: #212529;
    content: "Browse";
    background-color: #E9ECEF;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #5BA462;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #cee4d0; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #DEE2E6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #5BA462;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #cee4d0; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #DEE2E6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #5BA462;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #cee4d0; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #DEE2E6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #DEE2E6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #ADB5BD; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #ADB5BD; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #ADB5BD; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1.5rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6C757D;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #DEE2E6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #E9ECEF #E9ECEF #DEE2E6; }
    .nav-tabs .nav-link.disabled {
      color: #6C757D;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFF;
    border-color: #DEE2E6 #DEE2E6 #FFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #5BA462; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: calc(0.5 * (2.5rem - calc(1.25rem * 1.5)));
  padding-bottom: calc(0.5 * (2.5rem - calc(1.25rem * 1.5)));
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 569.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 570px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 759.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 760px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1919.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1920px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml !default;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: transparent; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml !default;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #F9F9F9;
  background-clip: border-box;
  border: 1px solid rgba(128, 128, 128, 0.075);
  border-radius: 0.4rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.4rem - 1px);
      border-top-right-radius: calc(0.4rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.4rem - 1px);
      border-bottom-left-radius: calc(0.4rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.75rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 0.75rem; }

.card-header {
  padding: 0.75rem 0.75rem;
  margin-bottom: 0;
  background-color: inherit;
  border-bottom: 1px solid rgba(128, 128, 128, 0.075); }
  .card-header:first-child {
    border-radius: calc(0.4rem - 1px) calc(0.4rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 0.75rem;
  background-color: inherit;
  border-top: 1px solid rgba(128, 128, 128, 0.075); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.4rem - 1px) calc(0.4rem - 1px); }

.card-header-tabs {
  margin-right: -0.375rem;
  margin-bottom: -0.75rem;
  margin-left: -0.375rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.375rem;
  margin-left: -0.375rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px); }

.card-deck .card {
  margin-bottom: 16px; }

@media (min-width: 570px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -16px;
    margin-left: -16px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 16px;
      margin-bottom: 0;
      margin-left: 16px; } }

.card-group > .card {
  margin-bottom: 16px; }

@media (min-width: 570px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 570px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0.25rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6C757D;
      content: none; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6C757D; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #008099;
  background-color: #FFF;
  border: 1px solid #DEE2E6; }
  .page-link:hover {
    z-index: 2;
    color: #00b3d6;
    text-decoration: none;
    background-color: #E9ECEF;
    border-color: #DEE2E6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #FFF;
  background-color: #5BA462;
  border-color: #5BA462; }

.page-item.disabled .page-link {
  color: #6C757D;
  pointer-events: none;
  cursor: auto;
  background-color: #FFF;
  border-color: #DEE2E6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.2em 0.6em;
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFF;
  background-color: #5BA462; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFF;
    background-color: #49834e; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.5); }

.badge-secondary {
  color: #FFF;
  background-color: #A3A3A3; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFF;
    background-color: #8a8a8a; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5); }

.badge-success {
  color: #FFF;
  background-color: #5BA462; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFF;
    background-color: #49834e; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.5); }

.badge-info {
  color: #FFF;
  background-color: #38A0B9; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFF;
    background-color: #2c7e92; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(56, 160, 185, 0.5); }

.badge-warning {
  color: #FFF;
  background-color: #FC723C; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #FFF;
    background-color: #fb4e0a; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 114, 60, 0.5); }

.badge-danger {
  color: #FFF;
  background-color: #DD3838; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFF;
    background-color: #c12121; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 56, 56, 0.5); }

.badge-light {
  color: #444647;
  background-color: #F9F9F9; }
  a.badge-light:hover, a.badge-light:focus {
    color: #444647;
    background-color: #e0e0e0; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.badge-dark {
  color: #FFF;
  background-color: #333; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFF;
    background-color: #1a1a1a; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #E9ECEF;
  border-radius: 0.4rem; }
  @media (min-width: 570px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.5rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1.5rem;
    color: inherit; }

.alert-primary {
  color: #122114;
  background-color: #c4dec6;
  border-color: #5ba462; }
  .alert-primary hr {
    border-top-color: #529458; }
  .alert-primary .alert-link {
    color: black; }

.alert-secondary {
  color: #212121;
  background-color: #dedede;
  border-color: #a3a3a3; }
  .alert-secondary hr {
    border-top-color: #969696; }
  .alert-secondary .alert-link {
    color: #080808; }

.alert-success {
  color: #122114;
  background-color: #c4dec6;
  border-color: #5ba462; }
  .alert-success hr {
    border-top-color: #529458; }
  .alert-success .alert-link {
    color: black; }

.alert-info {
  color: #0b2025;
  background-color: #b7dde6;
  border-color: #38a0b9; }
  .alert-info hr {
    border-top-color: #328fa5; }
  .alert-info .alert-link {
    color: black; }

.alert-warning {
  color: #32170c;
  background-color: #feccb9;
  border-color: #fc723c; }
  .alert-warning hr {
    border-top-color: #fc6023; }
  .alert-warning .alert-link {
    color: #090402; }

.alert-danger {
  color: #2c0b0b;
  background-color: #f3b7b7;
  border-color: #dd3838; }
  .alert-danger hr {
    border-top-color: #d72525; }
  .alert-danger .alert-link {
    color: #030101; }

.alert-light {
  color: #323232;
  background-color: #fdfdfd;
  border-color: #f9f9f9; }
  .alert-light hr {
    border-top-color: #ececec; }
  .alert-light .alert-link {
    color: #191919; }

.alert-dark {
  color: #0a0a0a;
  background-color: #b6b6b6;
  border-color: #333333; }
  .alert-dark hr {
    border-top-color: #262626; }
  .alert-dark .alert-link {
    color: black; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1.5em 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1.5em 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1.5em;
  overflow: hidden;
  line-height: 0;
  font-size: 1em;
  background-color: rgba(125, 125, 125, 0.15);
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #FFF;
  text-align: center;
  white-space: nowrap;
  background-color: #5BA462;
  transition: width 0.4s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.5em 1.5em; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #F8F9FA; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #E9ECEF; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  background-color: #FFF;
  border: 1px solid #d1d1d1; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6C757D;
    pointer-events: none;
    background-color: #FFF; }
  .list-group-item.active {
    z-index: 2;
    color: #444647;
    background-color: #FFF;
    border-color: #5BA462; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 570px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 760px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1920px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #2f5533;
  background-color: #d1e6d3; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2f5533;
    background-color: #c0ddc3; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    background-color: #2f5533;
    border-color: #2f5533; }

.list-group-item-secondary {
  color: #555555;
  background-color: #e5e5e5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #555555;
    background-color: #d8d8d8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFF;
    background-color: #555555;
    border-color: #555555; }

.list-group-item-success {
  color: #2f5533;
  background-color: #d1e6d3; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #2f5533;
    background-color: #c0ddc3; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFF;
    background-color: #2f5533;
    border-color: #2f5533; }

.list-group-item-info {
  color: #1d5360;
  background-color: #c7e4eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1d5360;
    background-color: #b4dbe4; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFF;
    background-color: #1d5360;
    border-color: #1d5360; }

.list-group-item-warning {
  color: #833b1f;
  background-color: #fed8c8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #833b1f;
    background-color: #fec6af; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFF;
    background-color: #833b1f;
    border-color: #833b1f; }

.list-group-item-danger {
  color: #731d1d;
  background-color: #f5c7c7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #731d1d;
    background-color: #f1b1b1; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFF;
    background-color: #731d1d;
    border-color: #731d1d; }

.list-group-item-light {
  color: #818181;
  background-color: #fdfdfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818181;
    background-color: #f0f0f0; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFF;
    background-color: #818181;
    border-color: #818181; }

.list-group-item-dark {
  color: #1b1b1b;
  background-color: #c6c6c6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1b1b;
    background-color: #b9b9b9; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #1b1b1b;
    border-color: #1b1b1b; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFF;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6C757D;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 2rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 4rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 4rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 4rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 4rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(209, 209, 209, 0.3);
  border-radius: 0.4rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100vw;
  height: 100vh;
  background-color: #232329; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.7; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid rgba(209, 209, 209, 0.3);
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px); }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid rgba(209, 209, 209, 0.3);
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 570px) {
  .modal-dialog {
    max-width: 838px;
    margin: 2rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 4rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 4rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 4rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 4rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 600px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1880px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 70;
  display: block;
  margin: 0;
  font-family: "Inter", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 60;
  display: block;
  max-width: 276px;
  font-family: "Inter", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.4rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.4rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.4rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #444647; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFF;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #5BA462 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #49834e !important; }

.bg-secondary {
  background-color: #A3A3A3 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #8a8a8a !important; }

.bg-success {
  background-color: #5BA462 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #49834e !important; }

.bg-info {
  background-color: #38A0B9 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2c7e92 !important; }

.bg-warning {
  background-color: #FC723C !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fb4e0a !important; }

.bg-danger {
  background-color: #DD3838 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c12121 !important; }

.bg-light {
  background-color: #F9F9F9 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e0e0e0 !important; }

.bg-dark {
  background-color: #333 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1a1a !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #A3A3A3 !important; }

.border-top {
  border-top: 1px solid #A3A3A3 !important; }

.border-right {
  border-right: 1px solid #A3A3A3 !important; }

.border-bottom {
  border-bottom: 1px solid #A3A3A3 !important; }

.border-left {
  border-left: 1px solid #A3A3A3 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5BA462 !important; }

.border-secondary {
  border-color: #A3A3A3 !important; }

.border-success {
  border-color: #5BA462 !important; }

.border-info {
  border-color: #38A0B9 !important; }

.border-warning {
  border-color: #FC723C !important; }

.border-danger {
  border-color: #DD3838 !important; }

.border-light {
  border-color: #F9F9F9 !important; }

.border-dark {
  border-color: #333 !important; }

.border-white {
  border-color: #FFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.4rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 570px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 760px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 570px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 760px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1920px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 570px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 760px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1920px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 30; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 20; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.15rem 0.4rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.03), 0 0 10px 0 rgba(0, 0, 1, 0.02) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 570px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 760px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1920px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: "Fira Code", sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 570px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 760px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1920px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFF !important; }

.text-primary {
  color: #5BA462 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #407345 !important; }

.text-secondary {
  color: #A3A3A3 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #7d7d7d !important; }

.text-success {
  color: #5BA462 !important; }

a.text-success:hover, a.text-success:focus {
  color: #407345 !important; }

.text-info {
  color: #38A0B9 !important; }

a.text-info:hover, a.text-info:focus {
  color: #266d7e !important; }

.text-warning {
  color: #FC723C !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #e84404 !important; }

.text-danger {
  color: #DD3838 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ab1d1d !important; }

.text-light {
  color: #F9F9F9 !important; }

a.text-light:hover, a.text-light:focus {
  color: lightgray !important; }

.text-dark {
  color: #333 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0d0d0d !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #A3A3A3 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #ADB5BD;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #DEE2E6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #DEE2E6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #DEE2E6; } }

html {
  background-color: #1C1C21;
  color: #FFF;
  font-size: 16px;
  line-height: 1.6; }

body {
  background-color: #1C1C21;
  overflow-x: hidden;
  overflow-y: scroll; }

main {
  padding: 0; }

ul,
ol {
  padding-left: 1em;
  margin-bottom: 0; }

a {
  transition: color 0.3s; }

img,
video {
  display: block;
  height: auto;
  max-width: 100%; }

::selection {
  background: rgba(156, 208, 220, 0.6); }

::-webkit-scrollbar {
  background-color: #232329;
  box-shadow: -1px 1px 7px rgba(0, 0, 0, 0.15) inset;
  height: 8px;
  padding: 1px;
  width: 8px; }

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2); }

.content-container ::-webkit-scrollbar {
  background-color: #F9F9F9;
  box-shadow: -1px 1px 7px rgba(0, 0, 0, 0.15) inset;
  height: 8px;
  padding: 1px;
  width: 8px; }

.content-container ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2); }

p {
  font-size: 1rem;
  line-height: 1.5; }
  @media screen and (min-width: 760px) {
    p {
      font-size: 0.875rem;
      line-height: calc(20/14); } }

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  letter-spacing: 0.01em; }

p:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child {
  margin-bottom: 0; }

.badge {
  display: inline; }
  h1 .badge,
  h2 .badge,
  h3 .badge,
  h4 .badge {
    font-size: 0.466667em;
    margin-left: 0.25em;
    vertical-align: middle; }

.card {
  border: 0;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.03), 0 0 10px 0 rgba(0, 0, 1, 0.02);
  color: #444647;
  overflow: visible; }
  .card .card {
    background-color: #FFF; }
  .card.top-border {
    border-top: 0.4rem solid; }

.card-img-overlay {
  padding: 0; }

@media screen and (min-width: 760px) {
  .card-body {
    padding: 20px; } }

@media screen and (min-width: 760px) {
  .card-header,
  .card-footer {
    padding: 12px 20px; } }

.dropdown-enter {
  opacity: 0; }

.dropdown-enter-active {
  opacity: 1;
  transition: opacity 0.2s linear; }

.dropdown-exit {
  opacity: 1; }
  .dropdown-exit.dropdown-menu {
    display: flex; }

.dropdown-exit-active {
  opacity: 0;
  transition: opacity 0.2s linear; }

.dropdown > .btn:first-child {
  z-index: 11; }

.dropdown-menu {
  flex-direction: column;
  box-shadow: -2px 3px 25px rgba(0, 0, 0, 0.15), -1px 6px 14px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.18);
  color: white;
  padding: 0.625rem; }
  .dropdown-menu.show {
    display: flex; }
  .dropdown-menu .dropdown-header,
  .dropdown-menu .dropdown-item {
    margin-bottom: 8px; }
    .dropdown-menu .dropdown-header:last-child,
    .dropdown-menu .dropdown-item:last-child {
      margin-bottom: 4px; }
      .dropdown-menu .dropdown-header:last-child:first-child,
      .dropdown-menu .dropdown-item:last-child:first-child {
        margin-bottom: 0; }
  .dropdown-menu .dropdown-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 11px;
    letter-spacing: 0.2em;
    line-height: calc(14/11);
    margin: 14px 10px 12px;
    padding: 0 0 0.3125rem;
    text-transform: uppercase; }
    .dropdown-menu .dropdown-header:first-child {
      margin-top: 10px; }
  .dropdown-menu .dropdown-item {
    border-radius: 0.2rem;
    display: block;
    font-size: 0.875rem;
    line-height: calc(20/14);
    padding-bottom: 0.3125rem;
    transition: background-color 0.1s ease-in-out; }
    .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
      background-color: #005e70; }

.chart-container {
  position: relative; }

.chart .recharts-text {
  fill: #A3A3A3;
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.7; }

.chart .recharts-cartesian-axis-line,
.chart .recharts-cartesian-axis-tick-line,
.chart .recharts-cartesian-grid-vertical line,
.chart .recharts-cartesian-grid-horizontal line {
  fill: #D3DADE;
  fill-opacity: 1;
  stroke: #D3DADE; }

.chart .recharts-tooltip-cursor {
  fill: #808080;
  fill-opacity: 0.075;
  stroke: rgba(128, 128, 128, 0.075); }

.chart .recharts-brush-slide {
  fill: #D3DADE;
  fill-opacity: 1; }

.chart .recharts-brush-traveller {
  transform: translateX(-8px); }
  .chart .recharts-brush-traveller rect {
    fill: #FFF;
    stroke: #EBEBEB;
    width: 12px; }
  .chart .recharts-brush-traveller line {
    stroke: #EBEBEB;
    transform: translateX(4px); }
  .chart .recharts-brush-traveller:first-of-type {
    transform: translateX(0); }

.chart .recharts-legend-wrapper {
  color: #676A6C; }

.chart .recharts-default-tooltip {
  background-color: #FFF;
  border-radius: 0.25rem;
  border: 1px solid #d1d1d1;
  padding: 0.5rem;
  white-space: nowrap; }
  .chart .recharts-default-tooltip .recharts-total-tooltip {
    margin: 0; }

.chart .chart-legend {
  padding: 1rem 0 0;
  list-style: none; }
  .chart .chart-legend li {
    margin-bottom: 0.5rem; }
  .chart .chart-legend .legend-color-sample {
    border-radius: 50%;
    display: inline-block;
    height: 0.8em;
    margin-right: 0.5em;
    width: 0.8em; }

.container {
  padding-left: 16px;
  padding-right: 16px; }

@media screen and (min-width: 760px) {
  .row {
    margin-left: calc(-1 * 20px / 2);
    margin-right: calc(-1 * 20px / 2); }
    .row.no-gutters {
      margin-left: 0;
      margin-right: 0; }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col,
  .col-auto, .container,
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: calc(20px / 2);
    padding-left: calc(20px / 2); } }

@media screen and (min-width: 1200px) {
  .row {
    margin-left: calc(-1 * 30px / 2);
    margin-right: calc(-1 * 30px / 2); }
    .row.no-gutters {
      margin-left: 0;
      margin-right: 0; }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col,
  .col-auto, .container,
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: calc(30px / 2);
    padding-left: calc(30px / 2); } }

.container-aspect-ratio {
  height: 0;
  position: relative; }

.container-16x9 {
  padding-top: calc(100% * 9 / 16); }

.container-9x16 {
  padding-top: calc(100% * 16 / 9); }

.container-1x1 {
  padding-top: 100%; }

.container-4x3 {
  padding-top: calc(100% * 3 / 4); }

.container-3x4 {
  padding-top: calc(100% * 4 / 3); }

.bg-primary {
  color: #FFF; }

.bg-secondary {
  color: #FFF; }

.bg-success {
  color: #FFF; }

.bg-info {
  color: #FFF; }

.bg-warning {
  color: #FFF; }

.bg-danger {
  color: #FFF; }

.bg-light {
  color: #444647; }

.bg-dark {
  color: #FFF; }

.bg-blue.gradient {
  background-image: linear-gradient(to top right, #404DC1, #A680E4); }

.bg-indigo.gradient {
  background-image: linear-gradient(to top right, #39147C, #407DB6); }

.bg-purple.gradient {
  background-image: linear-gradient(to top right, #AB6DC8, #FF74AE); }

.bg-pink.gradient {
  background-image: linear-gradient(to top right, #D56FAC, #FFA1BE); }

.bg-red.gradient,
.bg-danger.gradient {
  background-image: linear-gradient(to top right, #DD3838, #FFAE4F); }

.bg-orange.gradient,
.bg-warning.gradient {
  background-image: linear-gradient(to top right, #FC723C, #FFEB3C); }

.bg-yellow.gradient {
  background-image: linear-gradient(to top right, #F8E169, #ECF6AA); }

.bg-green.gradient,
.bg-primary.gradient,
.bg-success.gradient {
  background-image: linear-gradient(to top right, #5BA462, #5ADF67); }

.bg-teal.gradient {
  background-image: linear-gradient(to top right, #64C9B7, #92FCC9); }

.bg-cyan.gradient,
.bg-info.gradient {
  background-image: linear-gradient(to top right, #38A0B9, #5EB5C6); }

.bg-light.gradient {
  background-image: linear-gradient(to top right, #F9F9F9, #FFF); }

.bg-dark.gradient {
  background-image: linear-gradient(to top right, #333, #000); }

.fade-enter {
  opacity: 0; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.25s; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.25s; }

.fade-up-enter {
  opacity: 0;
  transform: translateY(20px); }

.fade-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.25s, transform 0.25s ease-in-out; }

.fade-up-exit {
  opacity: 1;
  transform: translateY(0); }

.fade-up-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.25s, transform 0.25s ease-in-out; }

.fade-down-enter {
  opacity: 0;
  transform: translateY(-20px); }

.fade-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.25s, transform 0.25s ease-in-out; }

.fade-down-exit {
  opacity: 1;
  transform: translateY(0); }

.fade-down-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.25s, transform 0.25s ease-in-out; }

.fade-left-enter {
  opacity: 0;
  transform: translateX(20px); }

.fade-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.25s, transform 0.25s ease-in-out; }

.fade-left-exit {
  opacity: 1;
  transform: translateX(0); }

.fade-left-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.25s, transform 0.25s ease-in-out; }

.fade-right-enter {
  opacity: 0;
  transform: translateX(-20px); }

.fade-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.25s, transform 0.25s ease-in-out; }

.fade-right-exit {
  opacity: 1;
  transform: translateX(0); }

.fade-right-exit-active {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.25s, transform 0.25s ease-in-out; }

.unstyled-button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: inherit; }

.sidebar {
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  height: 100vh;
  left: 0;
  padding-top: 60px;
  position: fixed;
  top: 0;
  transform: translateX(calc(-100% - 20px));
  transition: transform 0.3s, width 0.3s;
  width: 236px;
  z-index: 99; }
  .theme-light .sidebar {
    background: white; }
  .theme-dark .sidebar {
    background: #232329; }
  .theme-lightondark .sidebar {
    background: #232329; }
  .sidebar.sidebar--show {
    transform: translateX(0); }
    .sidebar.sidebar--show .sidebar__back {
      display: block; }
  .sidebar li {
    display: block; }

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none; }

.sidebar__content {
  padding-top: 15px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .sidebar__content > div:last-child {
    width: 4px !important; }
    .sidebar__content > div:last-child div {
      transition: height 0.3s;
      opacity: 0.52; }

.sidebar__block {
  padding: 0 0 11px; }
  .theme-light .sidebar__block {
    border-bottom: 1px solid #F9F9F9; }
  .theme-dark .sidebar__block {
    border-bottom: 1px solid #EBEBEB; }
  .theme-lightondark .sidebar__block {
    border-bottom: 1px solid #A3A3A3; }
  .sidebar__block:last-child {
    border: none; }

.sidebar__link {
  height: 56px;
  width: 100%;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  text-decoration: none;
  align-items: center; }
  .theme-light .sidebar__link {
    color: #A3A3A3; }
  .theme-dark .sidebar__link {
    color: white; }
  .theme-lightondark .sidebar__link {
    color: white; }
  .sidebar__link:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: #38A0B9;
    opacity: 0;
    transition: all 0.3s; }
  .sidebar__link p {
    position: absolute;
    left: 48px;
    width: 160px;
    transition: left 0.3s;
    top: 50%;
    transform: translateY(-50%); }
  .sidebar__link.active, .sidebar__link:hover {
    text-decoration: none;
    background-color: #38373F; }
    .sidebar__link.active:before, .sidebar__link:hover:before {
      opacity: 1; }

.sidebar__link-title {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.007em;
  line-height: 16px;
  position: relative;
  display: flex; }

.sidebar__link-icon {
  align-self: center;
  color: #38A0B9;
  font-size: 16px;
  height: 1em;
  line-height: 1;
  margin-right: 15px; }

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 0 0 11px; }
  .sidebar__submenu ul {
    padding-left: 0; }
  .sidebar__submenu .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0; }
    .sidebar__submenu .sidebar__submenu .sidebar__link {
      padding: 11px 20px 11px 53px; }
      .sidebar__submenu .sidebar__submenu .sidebar__link p {
        left: 53px; }
  .theme-light .sidebar__submenu {
    background-color: white; }
  .theme-dark .sidebar__submenu {
    background-color: #232329; }
  .theme-lightondark .sidebar__submenu {
    background-color: #232329; }
  .sidebar__submenu .sidebar__link {
    display: block;
    height: 32px;
    padding-left: 48px; }

.sidebar__category-icon {
  position: absolute;
  right: 15px;
  font-size: 10px;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: #EBEBEB; }

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: #DD3838;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px; }
  .sidebar__link-badge span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center; }

.sidebar__wrapper--desktop {
  display: none; }

.sidebar__category-wrap.sidebar__category-wrap--open .sidebar__category-icon {
  transform: rotate(180deg); }

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: #DD3838; }

@media screen and (min-width: 570px) {
  .sidebar {
    transform: translateX(0); }
    .sidebar.sidebar--no-desktop {
      transform: translateX(calc(-100% - 20px)); }
      .sidebar.sidebar--no-desktop.sidebar--show {
        transform: translateX(0); }
      .sidebar.sidebar--no-desktop .sidebar__wrapper--mobile {
        display: block; }
    .sidebar.sidebar--collapse {
      width: 60px;
      overflow: visible; }
      .sidebar.sidebar--collapse .sidebar__content {
        width: 60px;
        transition: width 0.3s;
        overflow: visible; }
      .sidebar.sidebar--collapse .sidebar__submenu {
        padding: 11px 0;
        transition: 0s; }
      .sidebar.sidebar--collapse .collapse,
      .sidebar.sidebar--collapse .collapse:not(.show) {
        display: block;
        width: 0 !important; } }
    @media screen and (min-width: 570px) and (hover: hover) {
      .theme-light .sidebar.sidebar--collapse .sidebar__category-wrap:hover .sidebar__category {
        background-color: white; }
      .theme-dark .sidebar.sidebar--collapse .sidebar__category-wrap:hover .sidebar__category {
        background-color: #232329; }
      .theme-lightondark .sidebar.sidebar--collapse .sidebar__category-wrap:hover .sidebar__category {
        background-color: #232329; }
      .sidebar.sidebar--collapse .sidebar__category-wrap:hover .sidebar__link {
        width: 240px; }
        .theme-light .sidebar.sidebar--collapse .sidebar__category-wrap:hover .sidebar__link:hover {
          background-color: white; }
        .theme-dark .sidebar.sidebar--collapse .sidebar__category-wrap:hover .sidebar__link:hover {
          background-color: #1C1C21; }
        .theme-lightondark .sidebar.sidebar--collapse .sidebar__category-wrap:hover .sidebar__link:hover {
          background-color: #1C1C21; }
      .sidebar.sidebar--collapse .sidebar__category-wrap:hover:before {
        opacity: 1; }
      .sidebar.sidebar--collapse .sidebar__category-wrap:hover .collapse,
      .sidebar.sidebar--collapse .sidebar__category-wrap:hover .collapse:not(.show) {
        width: 180px !important; } }

@media screen and (min-width: 570px) {
      .sidebar.sidebar--collapse .sidebar__submenu-wrap {
        position: absolute;
        left: 100%;
        width: 180px;
        transition: 0.3s;
        overflow: hidden; }
        .sidebar.sidebar--collapse .sidebar__submenu-wrap .sidebar__link {
          width: 181px;
          padding-left: 15px; }
          .sidebar.sidebar--collapse .sidebar__submenu-wrap .sidebar__link p {
            left: 15px;
            -webkit-animation: none;
                    animation: none; }
        .sidebar.sidebar--collapse .sidebar__submenu-wrap .sidebar__submenu-wrap {
          position: relative;
          left: 0; }
          .sidebar.sidebar--collapse .sidebar__submenu-wrap .sidebar__submenu-wrap .sidebar__link {
            padding-left: 30px; }
      .sidebar.sidebar--collapse .sidebar__link {
        width: 100%; }
        .sidebar.sidebar--collapse .sidebar__link p {
          position: absolute;
          left: 70px;
          width: 160px; } }
      @media screen and (min-width: 570px) and (hover: hover) {
        .sidebar.sidebar--collapse .sidebar__link:hover {
          width: 240px; } }

@media screen and (min-width: 570px) {
      .sidebar.sidebar--collapse .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s; }
  .sidebar__wrapper--desktop {
    display: block; }
  .sidebar__wrapper--mobile {
    display: none; } }

@media screen and (min-width: 992px) {
  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none; } }

.topbar {
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  z-index: 101;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05); }
  .theme-light .topbar {
    background: white;
    color: #A3A3A3; }
  .theme-dark .topbar {
    background: #1C1C21;
    color: white; }
  .theme-lightondark .topbar {
    background: #1C1C21;
    color: white; }

.topbar-wrapper {
  position: relative;
  display: flex;
  height: 60px; }

.topbar__button {
  width: 60px;
  height: 60px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s; }
  .theme-light .topbar__button:hover {
    background: #F9F9F9; }
  .theme-dark .topbar__button:hover {
    background: #333; }
  .theme-lightondark .topbar__button:hover {
    background: #232329; }
  .topbar__button.topbar__button--desktop {
    display: none; }

.topbar__button-icon {
  margin: auto;
  transition: all 0.3s;
  width: 16px; }

.topbar__logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 38px;
  margin: 10px auto 12px 0;
  width: 145px;
  align-self: flex-end; }
  .theme-light .topbar__logo {
    background-image: url(/static/media/stormkit-logo-black.8e3bf1db.svg); }
  .theme-dark .topbar__logo {
    background-image: url(/static/media/stormkit-logo-white.41788809.svg); }
  .theme-lightondark .topbar__logo {
    background-image: url(/static/media/stormkit-logo-white.41788809.svg); }
  @media screen and (min-width: 570px) {
    .topbar__logo {
      align-self: flex-start;
      background-position: left center;
      margin: 10px 0 16px 10px; } }

.topbar__right {
  position: absolute;
  right: 0;
  display: flex;
  height: 100%;
  margin-right: 15px; }

.topbar-left {
  position: absolute;
  left: 0;
  display: flex;
  height: 100%;
  width: 50%; }

.topbar__avatar {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding-left: 15px;
  background-color: transparent; }
  .theme-light .topbar__avatar:hover, .theme-light .topbar__avatar:focus, .theme-light .topbar__avatar:active, .theme-light .topbar__avatar:focus:active {
    background-color: #F9F9F9; }
  .theme-dark .topbar__avatar:hover, .theme-dark .topbar__avatar:focus, .theme-dark .topbar__avatar:active, .theme-dark .topbar__avatar:focus:active {
    background-color: #333; }
  .theme-lightondark .topbar__avatar:hover, .theme-lightondark .topbar__avatar:focus, .theme-lightondark .topbar__avatar:active, .theme-lightondark .topbar__avatar:focus:active {
    background-color: #232329; }
  .topbar__avatar:focus {
    outline: none; }
  .topbar__avatar:before {
    display: none; }

.topbar__avatar-img,
.topbar__avatar-name,
.topbar__icon {
  margin: auto 0; }

.topbar__avatar-img {
  border-radius: 50%;
  height: 26px;
  width: 26px; }
  @media screen and (min-width: 570px) {
    .topbar__avatar-img {
      height: 36px;
      width: 36px; } }

.topbar__avatar-name {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: none;
  margin-left: 10px; }
  .theme-light .topbar__avatar-name {
    color: #A3A3A3; }
  .theme-dark .topbar__avatar-name {
    color: white; }
  .theme-lightondark .topbar__avatar-name {
    color: white; }

.topbar__icon {
  color: #38A0B9;
  height: 18px;
  margin: auto 0 auto 0.5em; }
  @media screen and (min-width: 570px) {
    .topbar__icon {
      margin: auto 0 auto 8px; } }

.topbar__menu {
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0; }
  .theme-light .topbar__menu {
    background: white; }
  .theme-dark .topbar__menu {
    background: #232329; }
  .theme-lightondark .topbar__menu {
    background: #232329; }
  .topbar__menu button {
    padding: 0; }
    .theme-light .topbar__menu button:hover {
      background-color: #F9F9F9; }
    .theme-dark .topbar__menu button:hover {
      background-color: #333; }
    .theme-lightondark .topbar__menu button:hover {
      background-color: #232329; }
  .topbar__menu *:focus {
    outline: none; }

.topbar__menu-wrap {
  position: absolute;
  right: 0;
  min-width: 100%;
  z-index: 101; }

.topbar__link {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 32px;
  width: 100%;
  padding: 9px 20px;
  position: relative;
  transition: all 0.3s; }
  .theme-light .topbar__link {
    color: #A3A3A3; }
  .theme-dark .topbar__link {
    color: white; }
  .theme-lightondark .topbar__link {
    color: white; }
  .topbar__link:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: #38A0B9;
    opacity: 0;
    transition: all 0.3s; }
  .theme-light .topbar__link:hover {
    background-color: white; }
  .theme-dark .topbar__link:hover {
    background-color: #1C1C21; }
  .theme-lightondark .topbar__link:hover {
    background-color: #1C1C21; }
  .topbar__link:hover:before {
    opacity: 1; }

.topbar__link-title {
  margin: 0;
  min-width: 200px;
  font-size: 14px;
  line-height: 16px; }

.topbar__link-icon {
  color: #38A0B9;
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px; }

.topbar__menu-divider {
  border-top: 1px solid #A3A3A3;
  margin: 15px 0; }

.topbar__profile {
  margin-bottom: 0;
  margin-left: 20px;
  position: relative; }

.topbar__collapse {
  position: relative;
  display: none; }
  .topbar__collapse.topbar__collapse--language {
    min-width: 70px;
    display: block; }
    .topbar__collapse.topbar__collapse--language > button {
      padding: 0 4px;
      width: 100%; }
  @media screen and (min-width: 568px) {
    .topbar__collapse {
      display: block; } }

.topbar__collapse-content {
  width: 270px;
  position: absolute;
  right: 0;
  bottom: 20px;
  transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 101; }
  .theme-light .topbar__collapse-content {
    background: white; }
  .theme-dark .topbar__collapse-content {
    background: #232329; }
  .theme-lightondark .topbar__collapse-content {
    background: #232329; }
  .topbar__collapse-content.topbar__collapse-content--language {
    max-width: 70px;
    padding: 10px 0;
    width: 100%; }
  @media screen and (max-width: 768px) {
    .topbar__collapse-content {
      left: 50%;
      transform: translate(-50%, 100%); } }
  @media screen and (min-width: 520px) {
    .topbar__collapse-content {
      width: 330px; } }

.topbar__language-btn {
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s; }
  .topbar__language-btn:hover {
    color: #5BA462; }

.topbar__language-btn-title {
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0; }
  .theme-light .topbar__language-btn-title {
    color: #A3A3A3; }
  .theme-dark .topbar__language-btn-title {
    color: white; }
  .theme-lightondark .topbar__language-btn-title {
    color: white; }
  .topbar__language-btn-title:not(:last-child) {
    margin-right: 5px; }
  .topbar__language-btn-title img {
    height: 11px;
    width: 16px;
    margin-right: 4px; }

.topbar__back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none; }

.topbar__collapse-title-wrap {
  padding: 20px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .theme-light .topbar__collapse-title-wrap {
    border-bottom: 1px solid #F9F9F9; }
  .theme-dark .topbar__collapse-title-wrap {
    border-bottom: 1px solid #EBEBEB; }
  .theme-lightondark .topbar__collapse-title-wrap {
    border-bottom: 1px solid #A3A3A3; }

.topbar__collapse-item {
  padding: 12px 55px 12px 70px;
  display: flex;
  position: relative;
  height: 62px;
  align-items: center;
  flex-wrap: wrap; }
  .theme-light .topbar__collapse-item {
    border-bottom: 1px solid #F9F9F9; }
  .theme-dark .topbar__collapse-item {
    border-bottom: 1px solid #EBEBEB; }
  .theme-lightondark .topbar__collapse-item {
    border-bottom: 1px solid #A3A3A3; }

.topbar__collapse-img-wrap {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 15px; }

.topbar__collapse-message {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: #EBEBEB; }
  .topbar__collapse-message.topbar__collapse-message--mail {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.topbar__collapse-name {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: #5BA462; }

.topbar__collapse-date {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 10px;
  color: #EBEBEB;
  margin-top: 2px; }

.topbar__collapse-link {
  display: block;
  padding: 10px;
  text-transform: uppercase;
  color: #5BA462;
  transition: 0.3s;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px; }
  .topbar__collapse-link:hover {
    color: #4c8a52; }

.topbar__collapse-title {
  font-size: 14px;
  line-height: 16px; }

.topbar__collapse-button {
  color: #C5D2D6;
  border: none;
  padding: 0;
  text-align: right;
  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
  background: transparent; }
  .topbar__collapse-button:hover {
    color: #5BA462; }

.topbar__btn {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s; }
  .theme-light .topbar__btn {
    color: #A3A3A3; }
  .theme-dark .topbar__btn {
    color: white; }
  .theme-lightondark .topbar__btn {
    color: white; }
  .theme-light .topbar__btn:hover {
    background-color: #F9F9F9; }
  .theme-dark .topbar__btn:hover {
    background-color: #333; }
  .theme-lightondark .topbar__btn:hover {
    background-color: #232329; }
  .topbar__btn svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #8D8BB5; }
  .topbar__btn.topbar__btn--new .topbar__btn-new-label {
    position: absolute;
    right: 9px;
    top: 20px; }
    .topbar__btn.topbar__btn--new .topbar__btn-new-label > div {
      position: relative; }
      .topbar__btn.topbar__btn--new .topbar__btn-new-label > div:before {
        background-color: rgba(224, 83, 111, 0.2);
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        -webkit-animation: beforePulse 1.5s infinite;
                animation: beforePulse 1.5s infinite; }
      .topbar__btn.topbar__btn--new .topbar__btn-new-label > div:after {
        height: 7px;
        width: 7px;
        background-color: #E0536F;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%); }

@-webkit-keyframes beforePulse {
  from {
    width: 7px;
    height: 7px; }
  25% {
    width: 13px;
    height: 13px; }
  to {
    width: 7px;
    height: 7px; } }

@keyframes beforePulse {
  from {
    width: 7px;
    height: 7px; }
  25% {
    width: 13px;
    height: 13px; }
  to {
    width: 7px;
    height: 7px; } }

.topbar__nav {
  width: 100%;
  display: none;
  height: 100%;
  justify-content: center;
  align-items: center; }

.topbar .topbar__nav-dropdown-toggle {
  height: 60px;
  background: transparent;
  border-radius: 0;
  border: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; }
  .theme-light .topbar .topbar__nav-dropdown-toggle {
    color: #A3A3A3; }
  .theme-dark .topbar .topbar__nav-dropdown-toggle {
    color: white; }
  .theme-lightondark .topbar .topbar__nav-dropdown-toggle {
    color: white; }
  .topbar .topbar__nav-dropdown-toggle:before {
    display: none; }
  .theme-light .topbar .topbar__nav-dropdown-toggle:hover, .theme-light .topbar .topbar__nav-dropdown-toggle:focus, .theme-light .topbar .topbar__nav-dropdown-toggle:active, .theme-light .topbar .topbar__nav-dropdown-toggle:focus:active {
    background-color: #F9F9F9; }
  .theme-dark .topbar .topbar__nav-dropdown-toggle:hover, .theme-dark .topbar .topbar__nav-dropdown-toggle:focus, .theme-dark .topbar .topbar__nav-dropdown-toggle:active, .theme-dark .topbar .topbar__nav-dropdown-toggle:focus:active {
    background-color: #333; }
  .theme-lightondark .topbar .topbar__nav-dropdown-toggle:hover, .theme-lightondark .topbar .topbar__nav-dropdown-toggle:focus, .theme-lightondark .topbar .topbar__nav-dropdown-toggle:active, .theme-lightondark .topbar .topbar__nav-dropdown-toggle:focus:active {
    background-color: #232329; }
  .topbar .topbar__nav-dropdown-toggle svg {
    fill: #EBEBEB;
    margin-left: 3px;
    height: 16px;
    width: 16px;
    margin-right: 0; }

.topbar__nav-dropdown-menu {
  width: 240px;
  border-top: 2px solid #5BA462; }
  .topbar__nav-dropdown-menu button {
    padding: 0; }

.topbar__link-badge {
  width: 26px;
  height: 14px;
  background-color: #DD3838;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px; }
  .topbar__link-badge span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center; }

.topbar__nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 10px 25px;
  transition: 0.3s;
  font-size: 14px; }
  .theme-light .topbar__nav-link {
    color: #A3A3A3; }
  .theme-dark .topbar__nav-link {
    color: white; }
  .theme-lightondark .topbar__nav-link {
    color: white; }
  .theme-light .topbar__nav-link:hover {
    background-color: #F9F9F9; }
  .theme-dark .topbar__nav-link:hover {
    background-color: #333; }
  .theme-lightondark .topbar__nav-link:hover {
    background-color: #232329; }

.topbar__category-wrap {
  position: relative; }
  .topbar__category-wrap:hover .topbar__submenu {
    opacity: 1;
    width: auto;
    height: auto; }

.topbar__category-icon {
  position: absolute;
  right: 20px;
  font-size: 10px;
  line-height: 14px; }
  .theme-light .topbar__category-icon {
    color: #EBEBEB; }
  .theme-dark .topbar__category-icon {
    color: #AB6DC8; }
  .theme-lightondark .topbar__category-icon {
    color: #404DC1; }

.topbar__submenu {
  position: absolute;
  right: 1px;
  top: 0;
  transform: translateX(100%);
  transition: 0.3s;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden; }
  .theme-light .topbar__submenu {
    background-color: #F9F9F9; }
  .theme-dark .topbar__submenu {
    background-color: #333; }
  .theme-lightondark .topbar__submenu {
    background-color: #232329; }
  .theme-light .topbar__submenu .topbar__link:hover {
    background-color: white; }
  .theme-dark .topbar__submenu .topbar__link:hover {
    background-color: #232329; }
  .theme-lightondark .topbar__submenu .topbar__link:hover {
    background-color: #232329; }

.topbar__search {
  display: none;
  margin: auto 0;
  padding: 0;
  position: relative; }

.topbar__search-field {
  width: 0;
  transition: all 0.3s;
  opacity: 0;
  margin: auto auto auto 0;
  border: none;
  border-radius: 13px;
  height: 26px;
  padding-left: 10px;
  padding-right: 46px; }
  .theme-light .topbar__search-field {
    background-color: white; }
  .theme-dark .topbar__search-field {
    background-color: #333; }
  .theme-lightondark .topbar__search-field {
    background-color: #333; }
  .topbar__search-field.topbar__search-field--open {
    width: 200px;
    opacity: 1;
    margin-left: 10px; }
    .topbar__search-field.topbar__search-field--open + button {
      right: 10px; }
  .topbar__search-field:focus {
    outline: none; }

.topbar__search-btn {
  height: 26px;
  width: 26px;
  border-radius: 13px;
  border: none;
  padding: 0;
  background: transparent;
  position: absolute;
  right: 0; }
  .theme-light .topbar__search-btn:hover {
    background-color: white; }
  .theme-dark .topbar__search-btn:hover {
    background-color: #333; }
  .theme-lightondark .topbar__search-btn:hover {
    background-color: #333; }

@media screen and (min-width: 570px) {
  .topbar__avatar-name {
    display: block; }
  .topbar__menu {
    width: 100%;
    left: 0 !important; } }

@media screen and (min-width: 570px) {
  .topbar__button.topbar__button--desktop {
    display: block; }
  .topbar__button.topbar__button--mobile {
    display: none; }
  .topbar.topbar--navigation .topbar__button.topbar__button--mobile {
    display: block; } }

@media screen and (min-width: 768px) {
  .topbar__search {
    display: flex; } }

@media screen and (min-width: 992px) {
  .topbar__nav {
    display: flex; }
  .topbar.topbar--navigation .topbar__button.topbar__button--mobile {
    display: none; }
  .topbar.topbar--navigation .topbar__avatar-name {
    display: none; }
  .topbar.topbar--navigation .topbar__profile {
    margin-left: 0; } }

@media screen and (min-width: 1200px) {
  .topbar.topbar--navigation .topbar__avatar-name {
    display: block; } }

@media screen and (min-width: 1580px) {
  .topbar__nav-dropdown-toggle,
  .topbar__nav-link {
    width: 240px; } }

.dropzone {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  align-items: center;
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  min-height: 80px;
  position: relative;
  width: 100%;
  height: auto !important; }
  @media (prefers-reduced-motion: reduce) {
    .dropzone {
      transition: none; } }
  .theme-light .dropzone {
    background-color: #F9F9F9;
    border: 1px solid #F2F4F7; }
  .theme-dark .dropzone {
    background-color: #1C1C21;
    border: 1px solid #D3DADE; }
  .theme-lightondark .dropzone {
    background-color: white;
    border: 1px solid #A3A3A3; }
  .dropzone.thumbnail {
    height: 128px;
    overflow: hidden;
    width: 128px; }
    .dropzone.thumbnail .dropzone-drop-here {
      font-size: 11px;
      line-height: 1.27273;
      padding: 0 8px; }
    .dropzone.thumbnail .remove {
      bottom: 12px;
      height: auto;
      left: 50%;
      top: auto;
      transform: translateX(-50%); }
  .dropzone .dropzone-input {
    cursor: pointer;
    display: flex;
    height: 100%;
    margin: auto;
    width: 100%; }
  .dropzone .dropzone-drop-here {
    color: #A3A3A3;
    font-size: 1rem;
    margin: auto;
    padding: 0.375rem 1rem;
    text-align: center; }
    @media screen and (min-width: 760px) {
      .dropzone .dropzone-drop-here {
        font-size: 0.875rem; } }
    .dropzone .dropzone-drop-here .dropzone-file-icons svg {
      color: #D3DADE;
      font-size: 64px;
      margin: 0 12px 8px; }
  .dropzone .files-wrapper {
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0 0 12px;
    width: calc(100% + 30px); }
  .dropzone .file-preview.rounded-circle {
    overflow: hidden; }
  .dropzone .progress-bar-container {
    position: absolute;
    width: calc(100% - 8px);
    bottom: 0;
    left: 4px; }
  .dropzone .dropzone-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .dropzone.form-control, .dropzone.upload {
    width: 100%; }
    .dropzone.form-control .upload-thumbnail, .dropzone.upload .upload-thumbnail {
      width: 50%; }

.folder-list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 0.5rem 0.25rem; }
  .theme-light .folder-list-item {
    border-bottom: 1px solid #F9F9F9; }
  .theme-dark .folder-list-item {
    border-bottom: 1px solid #1C1C21; }
  .theme-lightondark .folder-list-item {
    border-bottom: 1px solid white; }
  .theme-light .folder-list-item.active {
    background-color: #F9F9F9; }
  .theme-dark .folder-list-item.active {
    background-color: #1C1C21; }
  .theme-lightondark .folder-list-item.active {
    background-color: white; }
  .folder-list-item.active .folder-title {
    color: #444647; }
  .folder-list-item .asset-icon {
    color: #A3A3A3;
    font-size: 1.14286em;
    transform: translateY(-1px); }
    .folder-list-item .asset-icon.folder-icon {
      color: rgba(248, 225, 105, 0.8); }
  .folder-list-item .folder-title {
    color: #676A6C;
    flex: 100% 1;
    padding: 0 0.5rem; }
    .folder-list-item .folder-title:hover {
      color: #444647;
      text-decoration: underline; }
  .folder-list-item .btn-icon {
    border-radius: 1em;
    line-height: 1;
    margin-right: 0.25rem;
    padding: 0.25rem;
    transform: rotateX(0);
    transition: transform 0.4s ease-in-out;
    will-change: transform; }
    .folder-list-item .btn-icon.open {
      transform: rotateX(180deg); }
    .folder-list-item .btn-icon .lnr {
      height: 1em;
      line-height: inherit;
      width: 1em; }

.folder-list-container {
  margin-bottom: 16px; }
  @media screen and (min-width: 760px) {
    .folder-list-container {
      margin-bottom: 20px; } }
  @media screen and (min-width: 1200px) {
    .folder-list-container {
      margin-bottom: 30px; } }
  .folder-list-container > .folder-list {
    padding: 0; }
    .folder-list-container > .folder-list .folder-list {
      padding-left: 1rem; }
  .theme-light .folder-list-container {
    border-top: 1px solid #F9F9F9; }
  .theme-dark .folder-list-container {
    border-top: 1px solid #1C1C21; }
  .theme-lightondark .folder-list-container {
    border-top: 1px solid white; }
  .folder-list-container ul > li:first-child {
    border-top: 0; }

.asset-toolbar .toolbar-item {
  padding-bottom: 16px; }
  @media screen and (min-width: 760px) {
    .asset-toolbar .toolbar-item {
      padding-bottom: 20px; } }
  @media screen and (min-width: 1200px) {
    .asset-toolbar .toolbar-item {
      padding-bottom: 30px; } }

@media screen and (min-width: 992px) {
  .asset-detail-view {
    max-height: calc(100vh - 2rem - 4rem); } }

.asset-detail-view .asset-container {
  align-items: center;
  background-color: #F9F9F9;
  display: flex;
  justify-content: center;
  max-height: inherit; }
  .asset-detail-view .asset-container .asset {
    height: auto;
    max-height: inherit;
    max-width: 100%;
    width: auto; }

.asset-detail-view .asset-info {
  background-color: white;
  max-height: inherit;
  padding-bottom: 60px;
  position: relative; }
  .asset-detail-view .asset-info .info-container {
    max-height: 100%;
    overflow-y: auto; }
  .asset-detail-view .asset-info .info-section {
    border-top: 1px solid #d1d1d1;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 16px;
    padding-right: 16px; }
    @media screen and (min-width: 760px) {
      .asset-detail-view .asset-info .info-section {
        padding-left: 20px;
        padding-right: 20px; } }
    @media screen and (min-width: 1200px) {
      .asset-detail-view .asset-info .info-section {
        padding-left: 30px;
        padding-right: 30px; } }
    .asset-detail-view .asset-info .info-section:first-of-type {
      border-top: 0; }
    .asset-detail-view .asset-info .info-section.info-header {
      padding-bottom: 16px; }
      @media screen and (min-width: 760px) {
        .asset-detail-view .asset-info .info-section.info-header {
          padding-bottom: 20px; } }
      @media screen and (min-width: 1200px) {
        .asset-detail-view .asset-info .info-section.info-header {
          padding-bottom: 30px; } }

.asset-detail-view .breadcrumb-item {
  color: #5BA462; }
  .asset-detail-view .breadcrumb-item a {
    opacity: 1; }
    .asset-detail-view .breadcrumb-item a:hover {
      text-decoration: underline; }

.asset-detail-view .asset-actions {
  display: flex;
  justify-content: flex-start; }
  .asset-detail-view .asset-actions .btn {
    margin-bottom: 0.5rem; }

.asset-detail-view .asset-detail-pagination {
  background-color: inherit;
  border-top: 1px solid #d1d1d1;
  bottom: 0;
  height: 60px;
  position: absolute;
  width: 100%; }
  .asset-detail-view .asset-detail-pagination .pagination {
    display: flex;
    width: 100%; }
  .asset-detail-view .asset-detail-pagination .page-item {
    align-items: center;
    display: flex;
    font-weight: 300;
    height: 60px;
    justify-content: center; }
  .asset-detail-view .asset-detail-pagination .current-position {
    align-self: stretch;
    flex: 100% 1;
    text-align: center; }
  .asset-detail-view .asset-detail-pagination .page-link {
    border: 0;
    color: #444647;
    font-size: 1.25rem;
    height: 60px;
    padding: 0;
    width: 60px; }
    .asset-detail-view .asset-detail-pagination .page-link[aria-label='Next'] {
      border-left: 1px solid #d1d1d1; }
    .asset-detail-view .asset-detail-pagination .page-link[aria-label='Previous'] {
      border-right: 1px solid #d1d1d1; }

.modal .asset-detail-modal .modal-body {
  padding: 0; }

@charset "UTF-8";

.panel {
  margin-bottom: 16px; }
  @media screen and (min-width: 760px) {
    .panel {
      margin-bottom: 20px; } }
  @media screen and (min-width: 1200px) {
    .panel {
      margin-bottom: 30px; } }
  .panel .card-header {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .panel .card-header.gradient {
      color: rgba(255, 255, 255, 0.9); }
    .panel .card-header .stat {
      height: 31px; }
      .panel .card-header .stat .name {
        font-size: 14px;
        margin-right: 4px; }
      .panel .card-header .stat .number,
      .panel .card-header .stat .percentage {
        font-size: 24px;
        line-height: 1.33333; }
      .panel .card-header .stat .number {
        font-weight: 700; }
      .panel .card-header .stat .percentage {
        font-weight: 300; }
        .panel .card-header .stat .percentage:before {
          content: ' \2022\A0'; }
  .panel.chart-container .card-body {
    padding-bottom: 6.4px; }
    @media screen and (min-width: 760px) {
      .panel.chart-container .card-body {
        padding-bottom: 8px; } }
    @media screen and (min-width: 1200px) {
      .panel.chart-container .card-body {
        padding-bottom: 12px; } }

.asset-card,
.card.asset-card {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  overflow: hidden;
  display: flex;
  width: 100%;
  text-align: left;
  align-items: normal;
  padding: 0; }
  .asset-card.card-body,
  .card.asset-card.card-body {
    height: auto; }
  .asset-card .thumbnail,
  .card.asset-card .thumbnail {
    text-align: center;
    border-bottom: 1px solid rgba(209, 209, 209, 0.5);
    padding-bottom: 56.25%;
    height: 0;
    position: relative; }
    .asset-card .thumbnail .image-container,
    .card.asset-card .thumbnail .image-container {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }
      .asset-card .thumbnail .image-container img,
      .card.asset-card .thumbnail .image-container img {
        max-height: 100%;
        max-width: 100%;
        width: auto; }
    .asset-card .thumbnail .asset-icon,
    .card.asset-card .thumbnail .asset-icon {
      font-size: 5.5rem;
      height: 5.5rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 5.5rem; }
      .asset-card .thumbnail .asset-icon.folder-icon,
      .card.asset-card .thumbnail .asset-icon.folder-icon {
        color: #F8E169; }
  .asset-card .card-body,
  .card.asset-card .card-body {
    min-height: 61px;
    padding: 0.5rem 0.625rem; }
    @media screen and (min-width: 760px) {
      .asset-card .card-body,
      .card.asset-card .card-body {
        min-height: 66px;
        padding: 0.5rem 0.75rem; } }
    @media screen and (min-width: 1200px) {
      .asset-card .card-body,
      .card.asset-card .card-body {
        min-height: 78px;
        padding: 0.75rem 1rem; } }
    .asset-card .card-body .title,
    .card.asset-card .card-body .title {
      font-size: 0.75rem;
      margin-bottom: 2px; }
      @media screen and (min-width: 760px) {
        .asset-card .card-body .title,
        .card.asset-card .card-body .title {
          font-size: 0.875rem; } }
      @media screen and (min-width: 1200px) {
        .asset-card .card-body .title,
        .card.asset-card .card-body .title {
          font-size: 1rem; } }
    .asset-card .card-body small,
    .card.asset-card .card-body small {
      color: #A3A3A3;
      display: block;
      font-size: 0.625rem; }
      @media screen and (min-width: 760px) {
        .asset-card .card-body small,
        .card.asset-card .card-body small {
          font-size: 0.6875rem; } }
      @media screen and (min-width: 1200px) {
        .asset-card .card-body small,
        .card.asset-card .card-body small {
          font-size: 0.75rem; } }

.form-row {
  font-size: 1rem; }
  @media screen and (min-width: 760px) {
    .form-row {
      font-size: 0.875rem; } }

.form__form-group.toggle-container.toggle-left .form-row > div {
  max-width: 40px;
  width: 40px; }

.form__form-group.toggle-container.toggle-left .form-row > label {
  max-width: calc(100% - 40px); }

.form__form-group-error,
.form__form-group-error.invalid-feedback {
  background: #fae1e1;
  border: 1px solid #DD3838;
  border-radius: 0.25rem;
  left: 0;
  max-width: 100%;
  padding: 0.375rem 0.75rem; }
  .form__form-group-error:before, .form__form-group-error:after,
  .form__form-group-error.invalid-feedback:before,
  .form__form-group-error.invalid-feedback:after {
    content: '';
    left: 4px;
    position: absolute;
    z-index: 0; }
  .form__form-group-error:after,
  .form__form-group-error.invalid-feedback:after {
    z-index: 1; }

.form__form-group-input-wrap--error-below {
  position: relative; }
  .form__form-group-input-wrap--error-below .form-control,
  .form__form-group-input-wrap--error-below [class^='css-'] {
    transition: margin-bottom 0.3s ease-in-out;
    will-change: margin-bottom; }
  .form__form-group-input-wrap--error-below .form__form-group-error {
    top: 100%;
    margin: 4px 0 0; }
    .form__form-group-input-wrap--error-below .form__form-group-error:before {
      border: 8px solid transparent;
      border-bottom: 8px solid #DD3838;
      top: -17px; }
    .form__form-group-input-wrap--error-below .form__form-group-error:after {
      border: 8px solid transparent;
      border-bottom: 8px solid #fae1e1;
      top: -16px; }
  .form__form-group-input-wrap--error-below .is-invalid:nth-last-child(2),
  .form__form-group-input-wrap--error-below .is-invalid + .validation + .help-text {
    margin-bottom: 26px; }
    @media screen and (min-width: 760px) {
      .form__form-group-input-wrap--error-below .is-invalid:nth-last-child(2),
      .form__form-group-input-wrap--error-below .is-invalid + .validation + .help-text {
        margin-bottom: 17px; } }

.form__form-group-input-wrap--error-above {
  position: relative; }
  .form__form-group-input-wrap--error-above .form-control,
  .form__form-group-input-wrap--error-above [class^='css-'] {
    transition: margin-bottom 0.3s ease-in-out;
    will-change: margin-bottom; }
  .form__form-group-input-wrap--error-above .form__form-group-error {
    bottom: 100%;
    margin: 0 0 4px; }
    .form__form-group-input-wrap--error-above .form__form-group-error:before {
      border: 8px solid transparent;
      border-top: 8px solid #DD3838;
      bottom: -17px; }
    .form__form-group-input-wrap--error-above .form__form-group-error:after {
      border: 8px solid transparent;
      border-top: 8px solid #fae1e1;
      bottom: -16px; }
  .form__form-group-input-wrap--error-above .is-invalid {
    margin-top: 26px; }
    @media screen and (min-width: 760px) {
      .form__form-group-input-wrap--error-above .is-invalid {
        margin-top: 17px; } }

.asset-field .dropzone,
.asset-field .dropzone.dropzone--single,
.asset-field .dropzone.dropzone--multiple {
  height: auto;
  min-height: 6rem;
  margin-bottom: 1rem; }
  @media screen and (min-width: 760px) {
    .asset-field .dropzone,
    .asset-field .dropzone.dropzone--single,
    .asset-field .dropzone.dropzone--multiple {
      margin-bottom: 0; } }

.asset-field .select-from-assets {
  border-color: #A3A3A3;
  height: auto;
  min-height: 6rem; }

.checkbox-dropdown-container {
  display: flex;
  max-width: 100%; }

.checkbox-dropdown {
  max-width: 100%; }
  .checkbox-dropdown.show .dropdown-trigger {
    border-bottom-left-radius: 0.1px;
    border-bottom-right-radius: 0.1px; }
  .checkbox-dropdown .dropdown-trigger {
    border-color: #A3A3A3;
    min-width: 16.25rem;
    max-width: 100%;
    position: relative;
    text-align: left;
    width: auto;
    padding-right: calc(0.75rem + 32px); }
    .checkbox-dropdown .dropdown-trigger .trigger-title {
      margin: 0 -0.75rem;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 12px;
      scrollbar-width: none;
      white-space: nowrap; }
      .checkbox-dropdown .dropdown-trigger .trigger-title::-webkit-scrollbar {
        display: none; }
    .checkbox-dropdown .dropdown-trigger .chevron-container {
      align-items: center;
      border-left: 1px solid #A3A3A3;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 32px; }
  .checkbox-dropdown .dropdown-menu {
    border-color: #A3A3A3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%; }
  .checkbox-dropdown .custom-checkbox {
    align-items: center;
    background-color: #FFF;
    border: 1px solid #A3A3A3;
    border-radius: 0.25rem;
    color: #FFF;
    display: inline-flex;
    font-size: 10px;
    height: 16px;
    justify-content: center;
    margin-right: 8px;
    width: 16px; }
    .checkbox-dropdown .custom-checkbox.checked {
      background-color: #38A0B9;
      border-color: #38A0B9; }

.checkbox-btn {
  display: flex;
  cursor: pointer; }
  .checkbox-btn.disabled {
    pointer-events: none;
    cursor: default; }
    .checkbox-btn.disabled .checkbox-btn__checkbox-custom {
      transition: 0s; }
      .theme-light .checkbox-btn.disabled .checkbox-btn__checkbox-custom {
        background-color: #F9F9F9;
        border-color: #F2F4F7; }
      .theme-dark .checkbox-btn.disabled .checkbox-btn__checkbox-custom {
        background-color: #333;
        border-color: #D3DADE; }
      .theme-lightondark .checkbox-btn.disabled .checkbox-btn__checkbox-custom {
        background-color: #232329;
        border-color: #A3A3A3; }
      .checkbox-btn.disabled .checkbox-btn__checkbox-custom svg {
        color: #A3A3A3; }
    .checkbox-btn.disabled .checkbox-btn__label {
      color: #A3A3A3; }
  .checkbox-btn.checkbox-btn--colored .checkbox-btn__checkbox-custom {
    border-color: #38A0B9;
    background-color: #38A0B9; }
    .checkbox-btn.checkbox-btn--colored .checkbox-btn__checkbox-custom svg {
      color: #FFF; }
  .checkbox-btn.checkbox-btn--colored.disabled {
    opacity: 0.4; }
    .checkbox-btn.checkbox-btn--colored.disabled .checkbox-btn__checkbox-custom {
      border-color: #38A0B9;
      background-color: #38A0B9; }
  .checkbox-btn.checkbox-btn--colored-click .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
    border-color: #38A0B9;
    background-color: #38A0B9; }
    .checkbox-btn.checkbox-btn--colored-click .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom svg {
      color: #FFF; }
  .theme-light .checkbox-btn.checkbox-btn--colored-click.disabled .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
    background-color: #F9F9F9;
    border-color: #F2F4F7; }
  .theme-dark .checkbox-btn.checkbox-btn--colored-click.disabled .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
    background-color: #333;
    border-color: #D3DADE; }
  .theme-lightondark .checkbox-btn.checkbox-btn--colored-click.disabled .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
    background-color: #232329;
    border-color: #A3A3A3; }
  .checkbox-btn.checkbox-btn--colored-click.disabled .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom svg {
    color: #A3A3A3; }
  .checkbox-btn.checkbox-btn--button {
    background: #38A0B9;
    min-width: 150px;
    color: #FFF;
    height: 24px;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 0 6px;
    width: 100%; }
    .checkbox-btn.checkbox-btn--button .checkbox-btn__label {
      color: #FFF;
      margin: auto auto auto 0;
      padding: 0; }
    .checkbox-btn.checkbox-btn--button .checkbox-btn__checkbox-custom {
      display: none; }
    .checkbox-btn.checkbox-btn--button .checkbox-btn__label-svg {
      margin: auto 4px auto auto;
      height: 16px;
      line-height: 1; }
      .checkbox-btn.checkbox-btn--button .checkbox-btn__label-svg svg {
        color: #FFF;
        width: 14px;
        height: 14px; }
      .checkbox-btn.checkbox-btn--button .checkbox-btn__label-svg .checkbox-btn__label-check {
        display: none; }
    .checkbox-btn.checkbox-btn--button .checkbox-btn__checkbox:checked ~ .checkbox-btn__label-svg .checkbox-btn__label-check {
      display: block; }
    .checkbox-btn.checkbox-btn--button .checkbox-btn__checkbox:checked ~ .checkbox-btn__label-svg .checkbox-btn__label-uncheck {
      display: none; }
    .checkbox-btn.checkbox-btn--button:hover {
      background: #2f859a; }
      .checkbox-btn.checkbox-btn--button:hover .checkbox-btn__label {
        color: #FFF; }
    .checkbox-btn.checkbox-btn--button.disabled {
      opacity: 0.4; }
      .checkbox-btn.checkbox-btn--button.disabled .checkbox-btn__label {
        color: #FFF; }
  .checkbox-btn .checkbox-btn__checkbox {
    display: none; }
  .checkbox-btn .checkbox-btn__checkbox-custom {
    border-radius: 3px;
    border: 1px solid #A3A3A3;
    height: 24px;
    position: relative;
    width: 24px; }
    .checkbox-btn .checkbox-btn__checkbox-custom svg {
      color: #38A0B9;
      height: 18px;
      left: 2px;
      opacity: 0;
      position: absolute;
      top: 2px;
      transition: all 0.3s;
      width: 18px; }
  .checkbox-btn .checkbox-btn__label {
    line-height: 24px;
    padding-left: 0.5em; }
  .checkbox-btn .checkbox-btn__checkbox-custom,
  .checkbox-btn .checkbox-btn__label {
    display: inline-block;
    vertical-align: middle;
    transition: all 0.3s; }
  .checkbox-btn .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom svg {
    opacity: 1; }

.datetime-field .datetime-row {
  margin-left: -8px;
  margin-right: -8px; }

.datetime-field .datetime-col {
  padding-left: 8px;
  padding-right: 8px; }

@media screen and (max-width: 570px) {
  .datetime-field .timezone-col {
    margin-top: 16px; } }

.datetime-field .flatpickr-input {
  padding-right: 0.75rem; }

.rdt {
  position: relative; }

.rdtOpen .rdtPicker {
  display: block; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 280px;
  padding: 12px;
  margin-top: -2px;
  z-index: 99999 !important;
  background: #FFF;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.03), 0 0 10px 0 rgba(0, 0, 1, 0.02); }
  .rdtPicker table {
    border-collapse: separate;
    margin: 0;
    width: 100%; }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center; }
  .rdtPicker .rdtDays td,
  .rdtPicker .rdtDays th {
    border-radius: 16px;
    height: 32px;
    width: 32px; }
  .rdtPicker td {
    cursor: pointer; }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover {
    background: #EBEBEB;
    cursor: pointer; }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #A3A3A3; }
  .rdtPicker td.rdtToday {
    position: relative; }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #d1d1d1;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px; }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #38A0B9;
    color: #FFF; }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #FFF; }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #A3A3A3;
    cursor: not-allowed; }
  .rdtPicker td span.rdtOld {
    color: #A3A3A3; }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #A3A3A3;
    cursor: not-allowed; }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default; }
  .rdtPicker th.rdtSwitch {
    font-size: 16px;
    line-height: 1.375;
    font-weight: normal;
    letter-spacing: 0.03em;
    width: 100px; }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    color: #38A0B9;
    font-size: 28px;
    line-height: 1;
    margin: 0 4px;
    vertical-align: top; }
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #A3A3A3;
    cursor: not-allowed; }
  .rdtPicker thead tr:first-child th {
    cursor: pointer; }
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer; }
  .rdtPicker button:hover {
    background: #EBEBEB; }
  .rdtPicker thead button {
    width: 100%;
    height: 100%; }
  .rdtPicker tbody tr:last-child {
    padding-bottom: 8px; }

.rdtTimeToggle,
.rdtSwitch {
  text-align: center;
  height: 32px;
  width: auto;
  color: #008099;
  padding: 4px 8px;
  position: relative; }
  .rdtTimeToggle:hover:after,
  .rdtSwitch:hover:after {
    background-color: rgba(0, 0, 0, 0.1); }
  .rdtTimeToggle:after,
  .rdtSwitch:after {
    border: 1px solid #38A0B9;
    border-radius: 0.25rem;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0; }

.rdtTimeToggle {
  padding: 10px 8px 6px; }
  .rdtTimeToggle:after {
    top: 4px; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
  border-radius: 0.25rem; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #EBEBEB; }

.rdtCounters {
  align-items: center;
  display: flex;
  font-feature-settings: 'tnum';
  justify-content: center;
  padding: 8px 0; }

.rdtCounters > div {
  float: left; }

.rdtCounter {
  display: flex;
  flex-direction: column; }

.rdtCounter .rdtBtn {
  border-radius: 16px;
  color: #38A0B9;
  cursor: pointer;
  display: block;
  height: 32px;
  line-height: 32px;
  margin: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 32px;
  -webkit-touch-callout: none; }

.rdtCounter .rdtBtn:hover {
  background: #EBEBEB; }

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

.rdtTime td {
  cursor: default; }



.fieldset {
  margin-bottom: 20px;
  padding: 0; }
  .fieldset .trigger {
    width: 100%; }
    .fieldset .trigger .trigger-icon {
      position: absolute;
      right: 0;
      transition: transform 0.25s ease-in-out;
      transform: translate(-50%, -50%) rotate(0deg);
      top: 50%;
      height: 0.7em;
      width: 0.613em; }
  .fieldset.opened .trigger-icon {
    transform: translate(-50%, -50%) rotate(180deg);
    transform-origin: center; }
  .fieldset:last-child {
    margin-bottom: 0; }
  .fieldset .collapsible legend,
  .fieldset .collapsible .legend {
    padding-right: 40px; }
    @media screen and (min-width: 760px) {
      .fieldset .collapsible legend,
      .fieldset .collapsible .legend {
        padding-right: 60px; } }
  .fieldset legend,
  .fieldset .legend {
    border-bottom: 1px solid #A3A3A3;
    font-size: 20px;
    line-height: 1.3;
    margin: 0 0 20px;
    padding: 0 0 6px;
    position: relative;
    z-index: 2; }
    @media screen and (min-width: 760px) {
      .fieldset legend,
      .fieldset .legend {
        font-size: calc(20 / 16 * 1rem);
        line-height: 1.25;
        padding: 0 0 7px; } }
    .fieldset legend.legend-sm,
    .fieldset .legend.legend-sm {
      font-size: 1rem; }
    .fieldset legend .btn,
    .fieldset .legend .btn {
      margin-top: 4px; }
    .fieldset legend .tooltip-container,
    .fieldset .legend .tooltip-container {
      display: block;
      flex: 1 1; }
    .fieldset legend input,
    .fieldset legend input.form-control,
    .fieldset .legend input,
    .fieldset .legend input.form-control {
      background: transparent;
      border-radius: 0.1px;
      border: 0;
      font-size: inherit;
      height: auto;
      line-height: inherit;
      padding: 0; }
      .fieldset legend input.input-icon-left,
      .fieldset legend input.form-control.input-icon-left,
      .fieldset .legend input.input-icon-left,
      .fieldset .legend input.form-control.input-icon-left {
        padding-left: 1.25em; }
      .fieldset legend input + .form__form-group-error,
      .fieldset legend input.form-control + .form__form-group-error,
      .fieldset .legend input + .form__form-group-error,
      .fieldset .legend input.form-control + .form__form-group-error {
        left: 8px;
        top: 26px; }
        @media screen and (min-width: 760px) {
          .fieldset legend input + .form__form-group-error,
          .fieldset legend input.form-control + .form__form-group-error,
          .fieldset .legend input + .form__form-group-error,
          .fieldset .legend input.form-control + .form__form-group-error {
            left: 10px; } }
        @media screen and (min-width: 1200px) {
          .fieldset legend input + .form__form-group-error,
          .fieldset legend input.form-control + .form__form-group-error,
          .fieldset .legend input + .form__form-group-error,
          .fieldset .legend input.form-control + .form__form-group-error {
            left: 15px; } }
    .fieldset legend .form__form-group-icon,
    .fieldset .legend .form__form-group-icon {
      background: transparent;
      border: 0;
      bottom: 6px;
      height: auto;
      padding: 0;
      top: 0; }
      @media screen and (min-width: 760px) {
        .fieldset legend .form__form-group-icon,
        .fieldset .legend .form__form-group-icon {
          bottom: 7px; } }
  .fieldset.padding-top {
    padding-top: 16px; }
    @media screen and (min-width: 760px) {
      .fieldset.padding-top {
        padding-top: 20px; } }
    @media screen and (min-width: 1200px) {
      .fieldset.padding-top {
        padding-top: 30px; } }

.tooltip.bs-tooltip-bottom .arrow {
  left: 50% !important;
  margin-left: -7px !important; }

@charset "UTF-8";

.activity {
  margin-bottom: 8px;
  display: flex; }
  @media screen and (min-width: 760px) {
    .activity {
      margin-bottom: 10px; } }
  @media screen and (min-width: 1200px) {
    .activity {
      margin-bottom: 15px; } }
  .activity .thumbnail {
    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.3);
    height: 36px;
    margin-right: 10px;
    margin-top: 1px;
    width: 36px; }
  .activity .name,
  .activity .timestamp {
    display: inline;
    font-size: 1em; }
  .activity .name {
    font-weight: 700; }
  .activity .action {
    color: #444647; }
    .activity .action:after {
      content: ' \2022   ';
      font-weight: 700; }
  .activity .more-info {
    color: #676A6C;
    margin-top: 0.5em; }

.form-actions {
  margin-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: margin-bottom 0.2s ease-in-out; }
  .uploader-visible .form-actions {
    margin-bottom: 28px; }
  .form-actions .tab-content .tab-pane {
    padding-top: 20px; }
  .form-actions .form-group {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #d1d1d1; }
    @media screen and (min-width: 760px) {
      .form-actions .form-group {
        margin-bottom: 20px;
        padding-bottom: 20px; } }
    @media screen and (min-width: 1200px) {
      .form-actions .form-group {
        margin-bottom: 30px;
        padding-bottom: 30px; } }
  .form-actions .form__form-group {
    padding-top: 0; }
    .theme-light .form-actions .form__form-group {
      border: 0; }
    .theme-dark .form-actions .form__form-group {
      border: 0; }
    .theme-lightondark .form-actions .form__form-group {
      border: 0; }
    .form-actions .form__form-group:first-child .form__form-group-label {
      padding-top: 0; }
  .form-actions .action-buttons:first-child {
    padding-top: 10px; }
  .form-actions .action-buttons .btn {
    margin-bottom: 16px; }
    @media screen and (min-width: 760px) {
      .form-actions .action-buttons .btn {
        margin-bottom: 20px; } }
    @media screen and (min-width: 1200px) {
      .form-actions .action-buttons .btn {
        margin-bottom: 30px; } }
  .form-actions .form-action-preview-container {
    margin: 0 auto;
    max-width: 40vh; }
  .form-actions .sharing-options {
    border-top: 1px solid #d1d1d1;
    margin: 20px 0;
    padding-top: 20px; }
    .form-actions .sharing-options .toggle-container {
      align-items: center;
      display: flex;
      margin-bottom: 20px; }
  .form-actions .deeplink-container {
    color: #A3A3A3;
    margin-top: 20px;
    text-align: center; }
    .form-actions .deeplink-container .trigger {
      margin-bottom: 4px;
      text-align: center;
      text-decoration: underline; }
      .form-actions .deeplink-container .trigger .svg-inline--fa {
        margin-right: 0.4em; }

.date-picker {
  margin-bottom: 20px;
  transition: margin-bottom 0.2s ease-in-out; }
  .date-picker.has-error {
    margin-bottom: 37px; }
  .date-picker .validation {
    margin: -10px auto 0;
    left: 50%;
    transform: translateX(-50%); }
    .date-picker .validation:before, .date-picker .validation:after {
      content: normal;
      display: none; }

.PresetDateRangePicker_panel {
  padding: 0 22px 11px; }

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #38A0B9;
  color: #38A0B9;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer; }

.PresetDateRangePicker_button:active {
  outline: 0; }

.PresetDateRangePicker_button__selected {
  color: #FFF;
  background: #38A0B9; }

.SingleDatePickerInput {
  display: inline-block;
  background-color: #FFF; }

.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #FFF; }

.SingleDatePickerInput__rtl {
  direction: rtl; }

.SingleDatePickerInput__disabled {
  background-color: #F9F9F9; }

.SingleDatePickerInput__block {
  display: block; }

.SingleDatePickerInput__showClearDate {
  padding-right: 30px; }

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #FFF;
  border-radius: 50%; }

.SingleDatePickerInput_clearDate__small {
  padding: 6px; }

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden; }

.SingleDatePickerInput_clearDate_svg {
  fill: #ADB5BD;
  height: 12px;
  width: 15px;
  vertical-align: middle; }

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px; }

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px; }

.SingleDatePickerInput_calendarIcon_svg {
  fill: #ADB5BD;
  height: 15px;
  width: 14px;
  vertical-align: middle; }

.SingleDatePicker {
  position: relative;
  display: inline-block; }

.SingleDatePicker__block {
  display: block; }

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #FFF;
  position: absolute; }

.SingleDatePicker_picker__rtl {
  direction: rtl; }

.SingleDatePicker_picker__directionLeft {
  left: 0; }

.SingleDatePicker_picker__directionRight {
  right: 0; }

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #FFF; }

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2; }

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: #8a8a8a;
  text-decoration: none; }

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #A3A3A3; }

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px; }

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0; }

.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2; }

.DayPickerKeyboardShortcuts_show:before {
  content: '';
  display: block;
  position: absolute; }

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__bottomRight:before {
  border-top: 26px solid transparent;
  border-right: 33px solid #38A0B9;
  bottom: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__bottomRight:hover:before {
  border-right: 33px solid #3540a3; }

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__topRight:before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #38A0B9;
  top: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__topRight:hover:before {
  border-right: 33px solid #3540a3; }

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0; }

.DayPickerKeyboardShortcuts_show__topLeft:before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #38A0B9;
  top: 0;
  left: 0; }

.DayPickerKeyboardShortcuts_show__topLeft:hover:before {
  border-left: 33px solid #3540a3; }

.DayPickerKeyboardShortcuts_showSpan {
  color: #FFF;
  position: absolute; }

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px; }

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px; }

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px; }

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #FFF;
  border: 1px solid #FFF;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left; }

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0; }

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px; }

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2; }

.DayPickerKeyboardShortcuts_close:active {
  outline: 0; }

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #D3DADE; }

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #ADB5BD; }

.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center; }

.CalendarDay:active {
  outline: 0; }

.CalendarDay__defaultCursor {
  cursor: default; }

.CalendarDay__default {
  border: 1px solid #F9F9F9;
  color: #444647;
  background: #FFF; }

.CalendarDay__default:hover {
  background: #F9F9F9;
  border: 1px solid #F9F9F9;
  color: inherit; }

.CalendarDay__hovered_offset {
  background: #EBEBEB;
  border: 1px double #F9F9F9;
  color: inherit; }

.CalendarDay__outside {
  border: 0;
  background: #FFF;
  color: #A3A3A3; }

.CalendarDay__outside:hover {
  border: 0; }

.CalendarDay__blocked_minimum_nights {
  background: #FFF;
  border: 1px solid #EBEBEB;
  color: #D3DADE; }

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #FFF;
  color: #D3DADE; }

.CalendarDay__highlighted_calendar {
  background: #FFEB3C;
  color: #444647; }

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #FC723C;
  color: #444647; }

.CalendarDay__selected_span {
  background: #5EB5C6;
  border: 1px double #5EB5C6;
  color: #FFF; }

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #5EB5C6;
  border: 1px double #5EB5C6;
  color: #FFF; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #38A0B9;
  border: 1px double #38A0B9;
  color: #FFF; }

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #5EB5C6;
  border: 1px double #5EB5C6;
  color: #FFF; }

.CalendarDay__hovered_span:active {
  background: #59acbc;
  border: 1px double #59acbc;
  color: #FFF; }

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #D3DADE;
  border: 1px solid #D3DADE;
  color: #ADB5BD; }

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #FFF;
  border: 1px solid #F9F9F9;
  color: #D3DADE; }

.CalendarDay__hovered_start_first_possible_end {
  background: #F9F9F9;
  border: 1px double #F9F9F9; }

.CalendarDay__hovered_start_blocked_min_nights {
  background: #F9F9F9;
  border: 1px double #F9F9F9; }

.CalendarMonth {
  background: #FFF;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0; }

.CalendarMonth_verticalSpacing {
  border-collapse: separate; }

.CalendarMonth_caption {
  color: #444647;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: top;
  caption-side: initial; }

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px; }

.CalendarMonthGrid {
  background: #FFF;
  text-align: left;
  z-index: 0; }

.CalendarMonthGrid__animating {
  z-index: 1; }

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px; }

.CalendarMonthGrid__vertical {
  margin: 0 auto; }

.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll; }

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%; }

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none; }

.CalendarMonthGrid_month__hidden {
  visibility: hidden; }

.DayPickerNavigation {
  position: relative;
  z-index: 2; }

.DayPickerNavigation__horizontal {
  height: 0; }

.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0; }

.DayPickerNavigation__verticalScrollableDefault {
  position: relative; }

.DayPickerNavigation__bottom {
  height: auto; }

.DayPickerNavigation__bottomDefault {
  display: flex;
  justify-content: space-between; }

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  margin: 0; }

.DayPickerNavigation_button__default {
  border: 1px solid #F9F9F9;
  background-color: #FFF;
  color: #444647; }

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #D3DADE; }

.DayPickerNavigation_button__default:active {
  background: #F9F9F9; }

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #F9F9F9; }

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #F9F9F9; }

.DayPickerNavigation_button__disabled:active {
  background: 0 0; }

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px; }

.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin: -10px 22px 30px; }

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px; }

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px; }

.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #FFF;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%; }

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0; }

.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%; }

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #ADB5BD;
  display: block; }

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #444647; }

.DayPickerNavigation_svg__disabled {
  fill: #F9F9F9; }

.DayPicker {
  background: #FFF;
  margin: 0 auto;
  position: relative;
  text-align: left; }

.DayPicker__horizontal {
  background: #FFF; }

.DayPicker__verticalScrollable {
  height: 100%; }

.DayPicker__hidden {
  visibility: hidden; }

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px; }

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%; }

.DayPicker_portal__vertical {
  position: static;
  position: initial; }

.DayPicker_focusRegion {
  outline: 0; }

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top; }

.DayPicker_weekHeaders {
  position: relative; }

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px; }

.DayPicker_weekHeader {
  color: #444647;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left; }

.DayPicker_weekHeader__vertical {
  left: 50%; }

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #FFF;
  background: #FFF;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center; }

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px; }

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center; }

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px; }

.DayPicker_transitionContainer__horizontal {
  transition: height 0.2s ease-in-out; }

.DayPicker_transitionContainer__vertical {
  width: 100%; }

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll; }

.DateInput {
  margin: 0;
  padding: 0;
  background: #FFF;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle; }

.DateInput__small {
  width: 97px; }

.DateInput__block {
  width: 100%; }

.DateInput__disabled {
  background: #F9F9F9;
  color: #FFF; }

.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #444647;
  background-color: #FFF;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0; }

.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px; }

.DateInput_input__regular {
  font-weight: auto; }

.DateInput_input__readOnly {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.DateInput_input__focused {
  outline: 0;
  background: #FFF;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #3540a3;
  border-left: 0; }

.DateInput_input__disabled {
  background: #F9F9F9;
  font-style: italic; }

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2; }

.DateInput_fangShape {
  fill: #FFF; }

.DateInput_fangStroke {
  stroke: #FFF;
  fill: transparent; }

.DateRangePickerInput {
  background-color: #FFF;
  display: inline-block; }

.DateRangePickerInput__disabled {
  background: #F9F9F9; }

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #FFF; }

.DateRangePickerInput__rtl {
  direction: rtl; }

.DateRangePickerInput__block {
  display: block; }

.DateRangePickerInput__showClearDates {
  padding-right: 30px; }

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #444647; }

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #444647;
  height: 24px;
  width: 24px; }

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }

.DateRangePickerInput_clearDates__small {
  padding: 6px; }

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #FFF;
  border-radius: 50%; }

.DateRangePickerInput_clearDates__hide {
  visibility: hidden; }

.DateRangePickerInput_clearDates_svg {
  fill: #ADB5BD;
  height: 12px;
  width: 15px;
  vertical-align: middle; }

.DateRangePickerInput_clearDates_svg__small {
  height: 9px; }

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px; }

.DateRangePickerInput_calendarIcon_svg {
  fill: #ADB5BD;
  height: 15px;
  width: 14px;
  vertical-align: middle; }

.DateRangePicker {
  position: relative;
  display: inline-block; }

.DateRangePicker__block {
  display: block; }

.DateRangePicker_picker {
  z-index: 1;
  background-color: #FFF;
  position: absolute; }

.DateRangePicker_picker__rtl {
  direction: rtl; }

.DateRangePicker_picker__directionLeft {
  left: 0; }

.DateRangePicker_picker__directionRight {
  right: 0; }

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.DateRangePicker_picker__fullScreenPortal {
  background-color: #FFF; }

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2; }

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b6c1c8;
  text-decoration: none; }

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #D3DADE; }

.list-field legend {
  border-top: 0;
  border-bottom: 1px solid white;
  font-size: 1rem;
  font-weight: 700;
  padding: 16px 40px 16px 0; }
  @media screen and (min-width: 760px) {
    .list-field legend {
      font-size: 14px; } }

.list-field .btn-add-field {
  margin-top: 20px; }

.list-field .single-field {
  border-bottom: 1px solid white;
  position: relative; }
  .list-field .single-field .input-container {
    padding-right: 40px; }
  @media screen and (max-width: 760px) {
    .list-field .single-field .btn.icon {
      bottom: 12px;
      margin: 0;
      top: auto; } }

.dnd .list-group-item {
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0);
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 0.25rem;
  padding-right: 36px;
  position: relative; }
  .dnd .list-group-item.active {
    border-color: #5BA462;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .dnd .list-group-item .icon {
    margin-right: 0.5em; }
    .dnd .list-group-item .icon.drag-handle {
      color: #A3A3A3; }
  .dnd .list-group-item .remove-btn {
    border: 0;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
    .dnd .list-group-item .remove-btn .mdi-icon {
      margin: 0; }



.select-options {
  margin: 0 4px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent; }
  .select-options:focus {
    outline: none; }

div.rs__control {
  border-color: #A3A3A3;
  border-radius: 0.25rem;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: 1rem;
  height: auto;
  min-height: 30px;
  min-width: 122px;
  width: 100%; }
  @media screen and (min-width: 760px) {
    div.rs__control {
      font-size: 0.875rem;
      min-height: 30px; } }
  div.rs__control:hover {
    border-color: #A3A3A3; }
  div.rs__control--is-focused {
    box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.25); }
  div.rs__control:not(:last-child) {
    border-bottom-left-radius: 0.1px;
    border-bottom-right-radius: 0.1px; }
  div.rs__control .rs__input {
    display: block;
    height: 16px;
    justify-self: center;
    margin: 0 2px;
    padding: 0; }
    div.rs__control .rs__input input {
      height: 16px; }
      div.rs__control .rs__input input:focus {
        border: 0;
        box-shadow: none; }
  div.rs__control .rs__clear-indicator {
    color: #A3A3A3;
    padding-bottom: 0;
    padding-top: 0; }
  div.rs__control .rs__dropdown-indicator {
    color: #A3A3A3;
    padding-bottom: 0;
    padding-top: 0; }
  div.rs__control .rs__indicator-separator {
    margin: 0;
    background-color: #A3A3A3; }
  div.rs__control .rs__placeholder {
    color: #A3A3A3;
    position: static;
    transform: none; }
  div.rs__control .rs__value-container {
    min-height: 30px;
    overflow: visible;
    overflow: initial;
    padding: 2px 10px; }
  div.rs__control .rs__value-container--is-multi {
    padding: 2px 5px; }
  div.rs__control .rs__single-value {
    display: block;
    justify-self: center;
    margin: 0 2px 0 0;
    position: static;
    top: auto;
    transform: none; }
  div.rs__control .rs__multi-value {
    align-items: center;
    background-color: #49C6E8;
    border-radius: 0.2rem;
    color: white;
    display: flex;
    font-size: inherit;
    height: 22px;
    margin: 2px 4px 2px 0;
    overflow: hidden; }
    div.rs__control .rs__multi-value + [class^='css-']:last-child {
      padding: 0 !important;
      margin: 0 !important;
      max-height: 18px;
      display: flex;
      align-items: center; }
  div.rs__control .rs__multi-value__label {
    color: inherit;
    font-size: inherit;
    line-height: 1;
    padding: 0 10px; }
  div.rs__control .rs__multi-value__remove {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4px 5px; }
    div.rs__control .rs__multi-value__remove:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: inherit; }

div.rs__menu {
  border: 0;
  box-shadow: none;
  margin: -1px 0 0;
  padding: 0;
  z-index: 3; }
  div.rs__menu .rs__menu-list {
    border: 1px solid #A3A3A3;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
    margin: 0;
    padding: 0; }
  div.rs__menu .rs__option {
    border: 0;
    color: #444647;
    cursor: pointer;
    font-size: inherit; }
    div.rs__menu .rs__option:hover, div.rs__menu .rs__option--is-focused {
      background-color: #EBF5FF;
      color: #444647; }
  div.rs__menu .rs__no-options-message {
    background-color: #F9F9F9;
    color: #A3A3A3;
    cursor: pointer;
    font-size: inherit;
    padding: 20px; }

.radio-btn {
  display: inline-block;
  cursor: pointer;
  padding-right: 20px; }
  .radio-btn:last-child {
    padding-right: 0; }
  .radio-btn:hover .radio-btn__radio-custom {
    border-color: #5BA462; }
  .radio-btn:hover .radio-btn__label {
    color: #5BA462; }
  .radio-btn.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.4; }
  .radio-btn.radio-btn--colored .radio-btn__radio-custom {
    border: 2px solid #5BA462; }
  .radio-btn.radio-btn--colored-click .radio-btn__radio:checked + .radio-btn__radio-custom {
    background: #5BA462; }
    .radio-btn.radio-btn--colored-click .radio-btn__radio:checked + .radio-btn__radio-custom:before {
      background: #FFF; }
  .radio-btn.radio-btn--colored-click.disabled .radio-btn__radio:checked + .radio-btn__radio-custom {
    background: transparent; }
    .radio-btn.radio-btn--colored-click.disabled .radio-btn__radio:checked + .radio-btn__radio-custom:before {
      background: #5BA462; }
  .radio-btn.radio-btn--button {
    background: #5BA462;
    min-width: 150px;
    color: #FFF;
    height: 24px;
    border-radius: 4px;
    transition: all 0.3s;
    display: flex;
    padding: 0 6px;
    width: 100%; }
    .radio-btn.radio-btn--button .radio-btn__radio-custom {
      display: none; }
    .radio-btn.radio-btn--button .radio-btn__label-svg {
      margin: auto 4px auto auto;
      height: 16px;
      line-height: 1; }
      .radio-btn.radio-btn--button .radio-btn__label-svg svg {
        fill: #FFF;
        width: 14px;
        height: 14px; }
      .radio-btn.radio-btn--button .radio-btn__label-svg .radio-btn__label-check {
        display: none; }
    .radio-btn.radio-btn--button .radio-btn__radio:checked ~ .radio-btn__label-svg .radio-btn__label-check {
      display: block; }
    .radio-btn.radio-btn--button .radio-btn__radio:checked ~ .radio-btn__label-svg .radio-btn__label-uncheck {
      display: none; }
    .radio-btn.radio-btn--button .radio-btn__label {
      margin: auto auto auto 0;
      padding: 0;
      color: #FFF; }
    .radio-btn.radio-btn--button:hover {
      background: #4c8a52; }
      .radio-btn.radio-btn--button:hover .radio-btn__label {
        color: #FFF; }
  .radio-btn .radio-btn__radio {
    display: none; }
  .radio-btn .radio-btn__radio-custom {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: relative;
    transition: all 0.3s; }
    .theme-light .radio-btn .radio-btn__radio-custom {
      border: 1px solid #EBEBEB; }
    .theme-dark .radio-btn .radio-btn__radio-custom {
      border: 1px solid #AB6DC8; }
    .theme-lightondark .radio-btn .radio-btn__radio-custom {
      border: 1px solid #404DC1; }
  .radio-btn .radio-btn__radio:checked + .radio-btn__radio-custom {
    border-color: #5BA462; }
  .radio-btn .radio-btn__label {
    line-height: 18px;
    padding-left: 9px;
    transition: all 0.3s; }
  .radio-btn .radio-btn__radio-custom,
  .radio-btn .radio-btn__label {
    display: inline-block;
    vertical-align: middle; }
  .radio-btn .radio-btn__radio:checked + .radio-btn__radio-custom:before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    top: calc(50% - 3px);
    left: calc(50% - 3px);
    background: #5BA462;
    border-radius: 50%; }

.list-field legend {
  border-top: 0;
  border-bottom: 1px solid white;
  font-size: 1rem;
  font-weight: 700;
  padding: 16px 40px 16px 0; }
  @media screen and (min-width: 760px) {
    .list-field legend {
      font-size: 14px; } }

.list-field .btn-add-field {
  margin-top: 20px; }

.list-field .single-field {
  border-bottom: 1px solid white;
  position: relative; }
  .list-field .single-field .drag-handle {
    color: #A3A3A3; }
  .list-field .single-field .input-container {
    padding-right: 40px; }
  @media screen and (max-width: 760px) {
    .list-field .single-field .btn.icon {
      bottom: 12px;
      margin: 0;
      top: auto; } }

.dnd .list-group-item {
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0);
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 0.25rem;
  padding-right: 36px;
  position: relative; }
  .dnd .list-group-item.active {
    border-color: #5BA462;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .dnd .list-group-item .icon {
    margin-right: 0.5em; }
    .dnd .list-group-item .icon.drag-handle {
      color: #A3A3A3; }
  .dnd .list-group-item .remove-btn {
    border: 0;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
    .dnd .list-group-item .remove-btn .mdi-icon {
      margin: 0; }

.text-editor-container {
  background-color: white;
  border-radius: 0.2rem;
  border: solid 1px #A3A3A3;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 9px;
  margin-top: 9px;
  position: relative; }
  .text-editor-container .text-editor-toolbar {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0; }
    .theme-light .text-editor-container .text-editor-toolbar {
      border-bottom: solid 1px #F2F4F7; }
    .theme-dark .text-editor-container .text-editor-toolbar {
      border-bottom: solid 1px #D3DADE; }
    .theme-lightondark .text-editor-container .text-editor-toolbar {
      border-bottom: solid 1px #A3A3A3; }
    .text-editor-container .text-editor-toolbar > div {
      height: 40px;
      border: none;
      cursor: pointer;
      margin: 0; }
      .text-editor-container .text-editor-toolbar > div img {
        height: 13px; }
        .theme-light .text-editor-container .text-editor-toolbar > div img {
          fill: #A3A3A3; }
        .theme-dark .text-editor-container .text-editor-toolbar > div img {
          fill: white; }
        .theme-lightondark .text-editor-container .text-editor-toolbar > div img {
          fill: #444647; }
    .text-editor-container .text-editor-toolbar .rdw-option-active {
      box-shadow: none; }
      .theme-light .text-editor-container .text-editor-toolbar .rdw-option-active {
        background-color: #F9F9F9; }
      .theme-dark .text-editor-container .text-editor-toolbar .rdw-option-active {
        background-color: #EBEBEB; }
      .theme-lightondark .text-editor-container .text-editor-toolbar .rdw-option-active {
        background-color: #A3A3A3; }
  .text-editor-container .text-editor-area {
    border: 0;
    cursor: text; }
  .text-editor-container .DraftEditor-root {
    width: 100%;
    min-height: 180px; }
  .text-editor-container .public-DraftEditor-content {
    padding: 6px 10px; }
    .theme-light .text-editor-container .public-DraftEditor-content {
      color: #A3A3A3; }
    .theme-dark .text-editor-container .public-DraftEditor-content {
      color: white; }
    .theme-lightondark .text-editor-container .public-DraftEditor-content {
      color: #444647; }
  .text-editor-container .draftJsMentionPlugin__input__1Wxng {
    width: 100%;
    padding: 10px; }
  .text-editor-container .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: #EBEBEB; }
  .text-editor-container .public-DraftStyleDefault-block {
    margin: 0.5em 0; }
  .text-editor-container .rdw-option-wrapper,
  .text-editor-container .rdw-dropdown-wrapper {
    border: none;
    border-radius: 0;
    transition: background 0.3s; }
    .text-editor-container .rdw-option-wrapper:hover,
    .text-editor-container .rdw-dropdown-wrapper:hover {
      box-shadow: none; }
      .theme-light .text-editor-container .rdw-option-wrapper:hover, .theme-light
      .text-editor-container .rdw-dropdown-wrapper:hover {
        background: #F9F9F9; }
      .theme-dark .text-editor-container .rdw-option-wrapper:hover, .theme-dark
      .text-editor-container .rdw-dropdown-wrapper:hover {
        background: #1C1C21; }
      .theme-lightondark .text-editor-container .rdw-option-wrapper:hover, .theme-lightondark
      .text-editor-container .rdw-dropdown-wrapper:hover {
        background: white; }
    .theme-light .text-editor-container .rdw-option-wrapper img, .theme-light
    .text-editor-container .rdw-dropdown-wrapper img {
      -webkit-filter: invert(0%);
              filter: invert(0%); }
    .theme-dark .text-editor-container .rdw-option-wrapper img, .theme-dark
    .text-editor-container .rdw-dropdown-wrapper img {
      -webkit-filter: invert(100%);
              filter: invert(100%); }
    .theme-lightondark .text-editor-container .rdw-option-wrapper img, .theme-lightondark
    .text-editor-container .rdw-dropdown-wrapper img {
      -webkit-filter: invert(0%);
              filter: invert(0%); }
  .text-editor-container .rdw-option-wrapper[title='Bold'] {
    padding: 7px 5px; }
    .text-editor-container .rdw-option-wrapper[title='Bold'] img {
      height: 12px;
      width: auto; }
  .text-editor-container .rdw-option-wrapper[title='Italic'] {
    padding: 5px; }
    .text-editor-container .rdw-option-wrapper[title='Italic'] img {
      height: 16px;
      width: auto; }
  .text-editor-container .rdw-option-wrapper[title='Underline'] {
    padding: 6px 5px 5px; }
    .text-editor-container .rdw-option-wrapper[title='Underline'] img {
      height: 17px;
      width: auto; }
  .theme-light .text-editor-container .rdw-dropdown-wrapper {
    color: #A3A3A3; }
  .theme-dark .text-editor-container .rdw-dropdown-wrapper {
    color: white; }
  .theme-lightondark .text-editor-container .rdw-dropdown-wrapper {
    color: #444647; }
  .text-editor-container .rdw-dropdown-wrapper .rdw-dropdown-carettoclose {
    right: 0; }
    .theme-light .text-editor-container .rdw-dropdown-wrapper .rdw-dropdown-carettoclose {
      border-bottom-color: #F9F9F9; }
    .theme-dark .text-editor-container .rdw-dropdown-wrapper .rdw-dropdown-carettoclose {
      border-bottom-color: #EBEBEB; }
    .theme-lightondark .text-editor-container .rdw-dropdown-wrapper .rdw-dropdown-carettoclose {
      border-bottom-color: #A3A3A3; }
  .text-editor-container .rdw-dropdown-wrapper .rdw-dropdown-carettoopen {
    right: 0; }
    .theme-light .text-editor-container .rdw-dropdown-wrapper .rdw-dropdown-carettoopen {
      border-top-color: #A3A3A3; }
    .theme-dark .text-editor-container .rdw-dropdown-wrapper .rdw-dropdown-carettoopen {
      border-top-color: white; }
    .theme-lightondark .text-editor-container .rdw-dropdown-wrapper .rdw-dropdown-carettoopen {
      border-top-color: #444647; }
  .text-editor-container .rdw-dropdown-optionwrapper {
    border-radius: 0.25rem;
    overflow: auto; }
    .theme-light .text-editor-container .rdw-dropdown-optionwrapper {
      background-color: white;
      border-color: #F9F9F9; }
    .theme-dark .text-editor-container .rdw-dropdown-optionwrapper {
      background-color: #232329;
      border-color: #EBEBEB; }
    .theme-lightondark .text-editor-container .rdw-dropdown-optionwrapper {
      background-color: #F9F9F9;
      border-color: #A3A3A3; }
    .text-editor-container .rdw-dropdown-optionwrapper:hover {
      box-shadow: none; }
  .text-editor-container .rdw-dropdownoption-default {
    transition: background-color 0.3s; }
    .theme-light .text-editor-container .rdw-dropdownoption-default:hover {
      background-color: #F9F9F9; }
    .theme-dark .text-editor-container .rdw-dropdownoption-default:hover {
      background-color: #1C1C21; }
    .theme-lightondark .text-editor-container .rdw-dropdownoption-default:hover {
      background-color: white; }
    .theme-light .text-editor-container .rdw-dropdownoption-default.rdw-dropdownoption-active {
      background-color: #F9F9F9; }
    .theme-dark .text-editor-container .rdw-dropdownoption-default.rdw-dropdownoption-active {
      background-color: #1C1C21; }
    .theme-lightondark .text-editor-container .rdw-dropdownoption-default.rdw-dropdownoption-active {
      background-color: white; }
  .text-editor-container button:hover {
    box-shadow: none; }
    .theme-light .text-editor-container button:hover {
      background-color: #F9F9F9; }
    .theme-dark .text-editor-container button:hover {
      background-color: #1C1C21; }
    .theme-lightondark .text-editor-container button:hover {
      background-color: white; }
  .text-editor-container button[disabled] {
    pointer-events: none; }
  .text-editor-container .rdw-link-modal {
    height: auto; }
    .text-editor-container .rdw-link-modal .rdw-link-modal-label,
    .text-editor-container .rdw-link-modal .rdw-link-modal-target-option {
      font-size: 1rem; }
    .text-editor-container .rdw-link-modal .rdw-link-modal-input {
      border-color: #A3A3A3;
      border-radius: 0.25rem;
      padding: 0.2rem 0.75rem;
      font-size: 0.75rem;
      line-height: 1.5; }
    .text-editor-container .rdw-link-modal input[type='checkbox'] {
      display: inline-block;
      width: auto; }
    .text-editor-container .rdw-link-modal .rdw-link-modal-buttonsection {
      margin: 0 0 0.5rem; }
    .text-editor-container .rdw-link-modal .rdw-link-modal-btn {
      margin: 0 0.5rem 0 0; }

.toggle-btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
  margin: 3px 0 3px 4px;
  padding: 4px 0; }
  @media screen and (min-width: 760px) {
    .toggle-btn {
      margin: 0; } }
  .toggle-btn.disabled {
    cursor: default;
    opacity: 0.65; }
    .toggle-btn.disabled .toggle-input-btn {
      cursor: default; }
  .toggle-btn .toggle-input {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute; }
  .toggle-btn .toggle-input-btn {
    background-color: #CED4DA;
    border-radius: 22px;
    border: 0;
    cursor: pointer;
    display: block;
    height: 22px;
    margin-bottom: 0;
    margin-left: 0;
    position: relative;
    text-indent: -9999px;
    width: 42px; }
    .toggle-btn .toggle-input-btn:after {
      background-color: #FFF;
      border-radius: 50%;
      box-shadow: 0 1px 6px rgba(153, 153, 153, 0.4);
      content: '';
      height: 20px;
      left: 1px;
      position: absolute;
      top: 1px;
      transition: 0.3s;
      transition: left 0.2s ease-in-out;
      width: 20px; }
    .toggle-btn .toggle-input-btn:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 164, 98, 0.5);
      outline: 0; }
  .toggle-btn .toggle-input:checked ~ .toggle-input-btn {
    background: #5BA462; }
    .toggle-btn .toggle-input:checked ~ .toggle-input-btn:after {
      left: calc(100% - 21px); }

.labeled-toggle-container {
  align-items: center;
  display: flex;
  justify-content: center; }
  .labeled-toggle-container .toggle-label {
    margin: 0 12px 2px 0; }

.chip {
  align-items: center;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  margin-right: 6px; }
  .form-control .chip:first-child {
    margin-left: -6px; }
  .form-control .chip:last-child {
    margin-right: -6px; }
  .chip .chip-name {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.07692;
    padding: 3px 6px; }
  .chip .btn-icon {
    background-color: rgba(68, 70, 71, 0.2);
    border-bottom-right-radius: 0.1px;
    border-top-right-radius: 0.1px;
    color: #FFF;
    font-size: 14px;
    height: 20px;
    min-width: 0;
    padding: 0;
    width: 20px; }
    .chip .btn-icon:hover, .chip .btn-icon:active {
      background-color: rgba(68, 70, 71, 0.3);
      color: #FFF; }

.content-container {
  color: #232329; }
  .content-container.fluid {
    max-width: 100%; }
  .content-container .header-contents {
    justify-content: space-between; }

@media screen and (min-width: 760px) {
  .table-toolbar {
    align-items: center; } }

.table-toolbar > [class*='col'] {
  margin-bottom: 16px; }
  @media screen and (min-width: 760px) {
    .table-toolbar > [class*='col'] {
      margin-bottom: 20px; } }
  @media screen and (min-width: 1200px) {
    .table-toolbar > [class*='col'] {
      margin-bottom: 30px; } }

@media screen and (min-width: 760px) {
  .table-toolbar .checkbox-dropdown-container {
    max-width: 260px; } }

.table-toolbar.button-group .btn {
  margin-bottom: 6.4px;
  margin-right: 6.4px; }
  @media screen and (min-width: 760px) {
    .table-toolbar.button-group .btn {
      margin-bottom: 8px;
      margin-right: 8px; } }
  @media screen and (min-width: 1200px) {
    .table-toolbar.button-group .btn {
      margin-bottom: 12px;
      margin-right: 12px; } }
  .table-toolbar.button-group .btn:last-child {
    margin-right: 0; }

@media screen and (min-width: 760px) {
  .table-toolbar .table-toolbar-button {
    margin-left: auto; } }

.table-toolbar .table-toolbar-button .d-none {
  display: inline-block !important; }

.table-toolbar .table-toolbar-button .btn-margin {
  margin: 0 5px; }

.filtered-tabs {
  overflow: auto;
  width: 100%; }
  .filtered-tabs .tabs-wrap {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #A3A3A3; }
  .filtered-tabs .nav-tabs {
    flex-wrap: nowrap;
    border-bottom: none; }
    .filtered-tabs .nav-tabs li {
      white-space: nowrap; }
      .filtered-tabs .nav-tabs li .nav-link {
        background-color: transparent;
        cursor: pointer;
        margin: 0 4px;
        transition: background-color 0.3s, border-color 0.3s; }
        .theme-light .filtered-tabs .nav-tabs li .nav-link {
          color: #A3A3A3; }
        .theme-dark .filtered-tabs .nav-tabs li .nav-link {
          color: white; }
        .theme-lightondark .filtered-tabs .nav-tabs li .nav-link {
          color: #444647; }
        .filtered-tabs .nav-tabs li .nav-link:hover, .filtered-tabs .nav-tabs li .nav-link:focus, .filtered-tabs .nav-tabs li .nav-link:active {
          background-color: rgba(68, 70, 71, 0.05);
          border-color: #d1d1d1;
          outline: none; }
        .filtered-tabs .nav-tabs li .nav-link.active, .filtered-tabs .nav-tabs li .nav-link.active:focus, .filtered-tabs .nav-tabs li .nav-link.active:hover {
          background-color: transparent;
          border-color: #d1d1d1; }
          .theme-light .filtered-tabs .nav-tabs li .nav-link.active, .theme-light .filtered-tabs .nav-tabs li .nav-link.active:focus, .theme-light .filtered-tabs .nav-tabs li .nav-link.active:hover {
            border-bottom-color: white; }
          .theme-dark .filtered-tabs .nav-tabs li .nav-link.active, .theme-dark .filtered-tabs .nav-tabs li .nav-link.active:focus, .theme-dark .filtered-tabs .nav-tabs li .nav-link.active:hover {
            border-bottom-color: #232329; }
          .theme-lightondark .filtered-tabs .nav-tabs li .nav-link.active, .theme-lightondark .filtered-tabs .nav-tabs li .nav-link.active:focus, .theme-lightondark .filtered-tabs .nav-tabs li .nav-link.active:hover {
            border-bottom-color: #F9F9F9; }
      .filtered-tabs .nav-tabs li.disabled .nav-link {
        color: #EBEBEB; }
  .filtered-tabs.bordered-top .nav-tabs li .nav-link {
    border-top: 2px solid transparent;
    border-radius: 0; }
    .filtered-tabs.bordered-top .nav-tabs li .nav-link.active, .filtered-tabs.bordered-top .nav-tabs li .nav-link:focus, .filtered-tabs.bordered-top .nav-tabs li .nav-link:hover {
      border-top: 2px solid #5BA462; }
  .filtered-tabs.bordered-bottom .nav-tabs li .nav-link {
    border-color: transparent;
    border-left: 0;
    border-right: 0;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    margin: 0; }
    .filtered-tabs.bordered-bottom .nav-tabs li .nav-link.active, .filtered-tabs.bordered-bottom .nav-tabs li .nav-link.active:focus, .filtered-tabs.bordered-bottom .nav-tabs li .nav-link.active:hover {
      border-radius: 0;
      border-color: transparent;
      border-bottom: 3px solid #5BA462; }
  .filtered-tabs.fieldset .nav-tabs li .nav-link {
    background-color: transparent;
    border-bottom: 3px solid transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #A3A3A3;
    font-size: 16px;
    line-height: 1.3;
    margin: 0;
    margin-right: 8px;
    padding: 0 0 4px;
    position: relative; }
    @media screen and (min-width: 760px) {
      .filtered-tabs.fieldset .nav-tabs li .nav-link {
        margin-right: 10px; } }
    @media screen and (min-width: 1200px) {
      .filtered-tabs.fieldset .nav-tabs li .nav-link {
        margin-right: 15px; } }
    @media screen and (min-width: 760px) {
      .filtered-tabs.fieldset .nav-tabs li .nav-link {
        font-size: calc(16 / 16 * 1rem);
        line-height: 1.25;
        padding: 0 0 5px; } }
    .filtered-tabs.fieldset .nav-tabs li .nav-link:focus, .filtered-tabs.fieldset .nav-tabs li .nav-link:hover {
      border-radius: 0; }
      .theme-light .filtered-tabs.fieldset .nav-tabs li .nav-link:focus, .theme-light .filtered-tabs.fieldset .nav-tabs li .nav-link:hover {
        border-bottom: 3px solid #F9F9F9; }
      .theme-dark .filtered-tabs.fieldset .nav-tabs li .nav-link:focus, .theme-dark .filtered-tabs.fieldset .nav-tabs li .nav-link:hover {
        border-bottom: 3px solid #EBEBEB; }
      .theme-lightondark .filtered-tabs.fieldset .nav-tabs li .nav-link:focus, .theme-lightondark .filtered-tabs.fieldset .nav-tabs li .nav-link:hover {
        border-bottom: 3px solid #A3A3A3; }
    .filtered-tabs.fieldset .nav-tabs li .nav-link.active, .filtered-tabs.fieldset .nav-tabs li .nav-link.active:focus, .filtered-tabs.fieldset .nav-tabs li .nav-link.active:hover {
      border-radius: 0; }
      .theme-light .filtered-tabs.fieldset .nav-tabs li .nav-link.active, .theme-light .filtered-tabs.fieldset .nav-tabs li .nav-link.active:focus, .theme-light .filtered-tabs.fieldset .nav-tabs li .nav-link.active:hover {
        color: #A3A3A3;
        border-bottom: 3px solid #F9F9F9; }
      .theme-dark .filtered-tabs.fieldset .nav-tabs li .nav-link.active, .theme-dark .filtered-tabs.fieldset .nav-tabs li .nav-link.active:focus, .theme-dark .filtered-tabs.fieldset .nav-tabs li .nav-link.active:hover {
        color: white;
        border-bottom: 3px solid #EBEBEB; }
      .theme-lightondark .filtered-tabs.fieldset .nav-tabs li .nav-link.active, .theme-lightondark .filtered-tabs.fieldset .nav-tabs li .nav-link.active:focus, .theme-lightondark .filtered-tabs.fieldset .nav-tabs li .nav-link.active:hover {
        color: #444647;
        border-bottom: 3px solid #A3A3A3; }
  .filtered-tabs.filtered-tabs-sm .nav-tabs li .nav-link {
    font-size: 0.95rem;
    padding: 16px 4px 2px 4px;
    margin-right: 8px; }
    .filtered-tabs.filtered-tabs-sm .nav-tabs li .nav-link .chip.bg-light.chip-sm {
      background-color: #D3DADE !important;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 0; }
      .filtered-tabs.filtered-tabs-sm .nav-tabs li .nav-link .chip.bg-light.chip-sm .chip-name {
        font-size: 11px; }

.filtered-tabs--mobile {
  display: none; }
  .filtered-tabs--mobile select.form-control {
    max-width: 300px; }
  @media screen and (max-width: 992px) {
    .filtered-tabs--mobile {
      display: block; } }

@media screen and (max-width: 992px) {
  .filtered-tabs--desktop {
    display: none; } }

.pagination-bottom {
  padding-left: 16px; }
  @media screen and (min-width: 760px) {
    .pagination-bottom {
      padding-left: 20px; } }
  @media screen and (min-width: 1200px) {
    .pagination-bottom {
      padding-left: 30px; } }

.data-table-pagination {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  transition: margin-bottom 0.2s ease-in-out; }
  .data-table-pagination > .col-auto:last-of-type {
    padding: 0; }
  .uploader-visible .data-table-pagination {
    margin-bottom: 28px; }
  .data-table-pagination > .col-auto {
    margin-top: 16px; }
    @media screen and (min-width: 760px) {
      .data-table-pagination > .col-auto {
        margin-top: 20px; } }
    @media screen and (min-width: 1200px) {
      .data-table-pagination > .col-auto {
        margin-top: 30px; } }
  .data-table-pagination .page-settings,
  .data-table-pagination .page-jump-container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .data-table-pagination .page-jump {
    width: 64px;
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  @media screen and (max-width: 760px) {
    .data-table-pagination .next,
    .data-table-pagination .previous {
      order: 2; } }

.ReactTable {
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 760px) {
    .ReactTable {
      margin-left: -20px;
      margin-right: -20px; } }
  @media screen and (min-width: 1200px) {
    .ReactTable {
      margin-left: -30px;
      margin-right: -30px; } }
  .ReactTable * {
    box-sizing: border-box; }
  .ReactTable .rt-table {
    padding-left: 16px;
    padding-right: 16px;
    align-items: stretch;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    flex: auto 1;
    overflow-x: auto;
    width: 100%; }
    @media screen and (min-width: 760px) {
      .ReactTable .rt-table {
        padding-left: 20px;
        padding-right: 20px; } }
    @media screen and (min-width: 1200px) {
      .ReactTable .rt-table {
        padding-left: 30px;
        padding-right: 30px; } }
  .ReactTable .rt-thead {
    background-color: #ecefef;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .ReactTable .rt-thead.-headerGroups {
      background: rgba(68, 70, 71, 0.03); }
    .ReactTable .rt-thead.-filters {
      display: none; }
    .ReactTable .rt-thead .rt-th,
    .ReactTable .rt-thead .rt-td {
      box-shadow: inset 0 0 0 0 transparent;
      line-height: normal;
      padding: 12px;
      position: relative;
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
      .ReactTable .rt-thead .rt-th.-cursor-pointer .sort-icon,
      .ReactTable .rt-thead .rt-td.-cursor-pointer .sort-icon {
        opacity: 0.5;
        transition: opacity 0.15s; }
    .ReactTable .rt-thead .rt-th {
      padding: 2px 12px 3px; }
    .ReactTable .rt-thead .column-name .sort-icon {
      opacity: 0; }
    .ReactTable .rt-thead .rt-th.-sort-asc .column-name .sort-icon.fa-caret-up,
    .ReactTable .rt-thead .rt-td.-sort-asc .column-name .sort-icon.fa-caret-up,
    .ReactTable .rt-thead .rt-th.-sort-desc .column-name .sort-icon.fa-caret-up,
    .ReactTable .rt-thead .rt-td.-sort-desc .column-name .sort-icon.fa-caret-up {
      display: inline-block;
      opacity: 1;
      transition: transform 0.25s ease; }
      .ReactTable .rt-thead .rt-th.-sort-asc .column-name .sort-icon.fa-caret-up .sort-icon.fa-caret-down,
      .ReactTable .rt-thead .rt-td.-sort-asc .column-name .sort-icon.fa-caret-up .sort-icon.fa-caret-down,
      .ReactTable .rt-thead .rt-th.-sort-desc .column-name .sort-icon.fa-caret-up .sort-icon.fa-caret-down,
      .ReactTable .rt-thead .rt-td.-sort-desc .column-name .sort-icon.fa-caret-up .sort-icon.fa-caret-down {
        opacity: 0; }
    .ReactTable .rt-thead .rt-th.-sort-asc .column-name .fa-caret-down,
    .ReactTable .rt-thead .rt-td.-sort-asc .column-name .fa-caret-down,
    .ReactTable .rt-thead .rt-th.-sort-desc .column-name .fa-caret-down,
    .ReactTable .rt-thead .rt-td.-sort-desc .column-name .fa-caret-down {
      opacity: 0; }
    .ReactTable .rt-thead .rt-th.-sort-desc .column-name .sort-icon,
    .ReactTable .rt-thead .rt-td.-sort-desc .column-name .sort-icon {
      transform: translateY(10%) rotate(180deg); }
    .ReactTable .rt-thead .rt-th.-cursor-pointer,
    .ReactTable .rt-thead .rt-td.-cursor-pointer {
      cursor: pointer; }
    .ReactTable .rt-thead .rt-th:focus {
      outline: none; }
    .ReactTable .rt-thead .rt-resizable-header {
      overflow: visible; }
    .ReactTable .rt-thead .rt-resizable-header:last-child {
      overflow: hidden; }
    .ReactTable .rt-thead .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis; }
    .ReactTable .rt-thead .rt-header-pivot:after,
    .ReactTable .rt-thead .rt-header-pivot:before {
      border: solid transparent;
      content: ' ';
      height: 0;
      left: 100%;
      pointer-events: none;
      position: absolute;
      top: 50%;
      width: 0; }
    .ReactTable .rt-thead .rt-header-pivot:after {
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #fff;
      border-width: 8px;
      margin-top: -8px; }
    .ReactTable .rt-thead .rt-header-pivot:before {
      border-color: rgba(102, 102, 102, 0);
      border-left-color: #f7f7f7;
      border-width: 10px;
      margin-top: -10px; }
  .ReactTable .rt-tbody {
    display: flex;
    flex-direction: column;
    flex: 99999 1 auto;
    overflow: auto; }
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba(68, 70, 71, 0.05); }
  .ReactTable .rt-tbody .rt-tr-group:last-child {
    border-bottom: 0; }
  .ReactTable .rt-tbody .rt-expandable {
    cursor: pointer;
    text-overflow: clip; }
  .ReactTable .rt-tr-group {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto; }
  .ReactTable .rt-tr {
    display: inline-flex;
    flex: 1 0 auto; }
  .ReactTable .rt-th,
  .ReactTable .rt-td {
    align-items: center;
    display: flex;
    flex: 1 0;
    padding: 12px;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
    word-break: break-word; }
    .ReactTable .rt-th.date,
    .ReactTable .rt-td.date {
      justify-content: center; }
    .ReactTable .rt-th .count,
    .ReactTable .rt-td .count {
      word-break: normal; }
  .ReactTable .table-actions .btn {
    margin-right: 12px; }
  .ReactTable .rt-td.table-actions .btn-icon {
    margin-right: 12px; }
    .ReactTable .rt-td.table-actions .btn-icon svg {
      height: 1.375em; }
  .ReactTable .rt-th.-hidden,
  .ReactTable .rt-td.-hidden {
    border: 0 !important;
    min-width: 0 !important;
    opacity: 0 !important;
    padding: 0 !important;
    width: 0 !important; }
  .ReactTable .rt-expander {
    color: transparent;
    display: inline-block;
    margin: 0 10px;
    margin: 0;
    position: relative; }
  .ReactTable .rt-expander:after {
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    border-top: 7px solid rgba(68, 70, 71, 0.8);
    content: '';
    cursor: pointer;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 0; }
  .ReactTable .rt-expander.-open:after {
    transform: translate(-50%, -50%) rotate(0); }
  .ReactTable .rt-resizer {
    bottom: 0;
    cursor: col-resize;
    display: inline-block;
    position: absolute;
    right: -18px;
    top: 0;
    width: 36px;
    z-index: 10; }
  .ReactTable .rt-tfoot {
    box-shadow: 0 0 15px 0 rgba(68, 70, 71, 0.15);
    display: flex;
    flex-direction: column;
    flex: 1 0 auto; }
  .theme-light .ReactTable.-striped .rt-tr.-odd {
    background: #F9F9F9; }
  .theme-dark .ReactTable.-striped .rt-tr.-odd {
    background: #1C1C21; }
  .theme-lightondark .ReactTable.-striped .rt-tr.-odd {
    background: white; }
  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(68, 70, 71, 0.05); }
  .ReactTable .rt-noData {
    order: 1;
    color: #FC723C;
    display: block;
    left: 50%;
    padding: 20px;
    pointer-events: none;
    position: relative;
    transform: translateX(-50%);
    transition: all 0.3s ease;
    z-index: 1;
    text-align: center;
    width: 100%; }
  .ReactTable .pagination-bottom {
    order: 2;
    padding-right: 16px; }
    @media screen and (min-width: 760px) {
      .ReactTable .pagination-bottom {
        padding-right: 20px; } }
    @media screen and (min-width: 1200px) {
      .ReactTable .pagination-bottom {
        padding-right: 30px; } }
  .ReactTable .-loading {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    display: block;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease;
    z-index: -1; }
  .ReactTable .-loading > div {
    color: rgba(68, 70, 71, 0.6);
    display: block;
    font-size: 14px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-52%);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 100%; }
  .ReactTable .-loading.-active {
    opacity: 1;
    pointer-events: all;
    z-index: 2; }
  .ReactTable .-loading.-active > div {
    transform: translateY(50%); }
  .ReactTable .rt-resizing .rt-th,
  .ReactTable .rt-resizing .rt-td {
    cursor: col-resize;
    transition: none !important;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.data-table-tooltip-icon {
  width: 0.875em !important;
  margin-right: 4px; }

.scheduling .checkbox-btn {
  margin-bottom: 20px; }

.scheduling .form__form-group {
  margin-bottom: 20px;
  padding: 0; }
  .scheduling .form__form-group label {
    padding-top: 0; }

.page-title {
  font-weight: 500;
  font-size: 1.875rem;
  letter-spacing: 0.01em;
  line-height: 1.06667;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  @media screen and (min-width: 760px) {
    .page-title {
      line-height: 1.26667; } }

@charset "UTF-8";

.alert {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 1100; }
  .alert.rc-notification-notice {
    border-color: rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
    padding: 0.75rem; }
  .alert.fade {
    opacity: 1; }
  .alert .alert-content {
    align-self: center;
    word-break: break-word; }
  .alert p {
    margin-bottom: 0.3em; }
  .alert p:last-of-type {
    margin-bottom: 0; }
  .alert .alert-icon {
    display: flex;
    font-size: 1.75rem;
    margin: 4px 12px 4px 0;
    opacity: 0.7; }
  .alert .rc-notification-notice-close {
    align-items: center;
    align-self: stretch;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 100;
    margin: -12px -12px -12px 12px;
    min-height: 60px;
    justify-content: center;
    justify-self: flex-end;
    line-height: 18px;
    opacity: 1;
    text-shadow: none;
    width: 60px; }
    .alert .rc-notification-notice-close:focus, .alert .rc-notification-notice-close:active, .alert .rc-notification-notice-close:active:focus {
      outline: none; }
    .alert .rc-notification-notice-close:focus, .alert .rc-notification-notice-close:hover, .alert .rc-notification-notice-close:hover:focus, .alert .rc-notification-notice-close:not(:disabled):not(.disabled):hover, .alert .rc-notification-notice-close:not(:disabled):not(.disabled):focus {
      background-color: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.8);
      opacity: 1; }

.rc-notification {
  height: 0;
  left: auto !important;
  max-width: calc(100vw - 40px);
  min-width: 250px;
  position: fixed;
  right: 20px;
  top: 20px !important;
  z-index: 1100; }
  @media screen and (min-width: 570px) {
    .rc-notification {
      min-width: 300px; } }

.rc-notification-notice-content {
  display: flex;
  flex: 1 1; }

.rc-notification-notice-close-x:after {
  content: '\D7'; }

.rc-notification-fade-enter {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
          animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.rc-notification-fade-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
          animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.rc-notification-fade-enter.rc-notification-fade-enter-active {
  -webkit-animation-name: rcNotificationFadeIn;
          animation-name: rcNotificationFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running; }
  .rc-notification-fade-enter.rc-notification-fade-enter-active.right-up {
    -webkit-animation-name: rcNotificationRightFadeIn;
            animation-name: rcNotificationRightFadeIn; }
  .rc-notification-fade-enter.rc-notification-fade-enter-active.left-up {
    -webkit-animation-name: rcNotificationLeftFadeIn;
            animation-name: rcNotificationLeftFadeIn; }

.rc-notification-fade-leave.rc-notification-fade-leave-active {
  -webkit-animation-name: rcDialogFadeOut;
          animation-name: rcDialogFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running; }

@-webkit-keyframes rcNotificationFadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes rcNotificationFadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@-webkit-keyframes rcNotificationLeftFadeIn {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes rcNotificationLeftFadeIn {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-webkit-keyframes rcNotificationRightFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes rcNotificationRightFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-webkit-keyframes rcDialogFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(-100%);
    opacity: 0; } }

@keyframes rcDialogFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(-100%);
    opacity: 0; } }

.upload-summary-enter {
  transform: translateY(100%); }

.upload-summary-enter-active {
  transform: translateY(0);
  transition: transform 0.35s ease-out; }

.upload-summary-exit {
  transform: translateY(0); }

.upload-summary-exit-active {
  transform: translateY(100%);
  transition: transform 0.35s ease-in; }

.upload-summary {
  bottom: 0;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.03), 0 0 10px 0 rgba(0, 0, 1, 0.02);
  max-width: 500px;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 2; }
  @media screen and (min-width: 500px) {
    .upload-summary {
      border-top-left-radius: 0.25rem; } }
  .upload-summary .upload-summary-header {
    align-items: center;
    background-color: #232329;
    color: #FFF;
    display: flex;
    padding: 10px 16px; }
    @media screen and (min-width: 1200px) {
      .upload-summary .upload-summary-header {
        padding: 14px 20px; } }
    .upload-summary .upload-summary-header .cancel-btn {
      border-radius: 16px;
      font-size: 16px;
      height: 32px;
      left: 24px;
      position: absolute;
      text-align: center;
      top: 48%;
      transform: translate(-50%, -50%);
      width: 32px; }
      .upload-summary .upload-summary-header .cancel-btn:hover {
        background-color: rgba(255, 255, 255, 0.15); }
    .upload-summary .upload-summary-header h4 {
      flex: 1 1;
      line-height: 1.25; }
    .upload-summary .upload-summary-header .collapse-icon-progress {
      height: 32px;
      padding: 2px;
      position: absolute;
      right: 10px;
      width: 32px; }
      @media screen and (min-width: 1200px) {
        .upload-summary .upload-summary-header .collapse-icon-progress {
          height: 40px;
          width: 40px; } }
      .upload-summary .upload-summary-header .collapse-icon-progress .trigger-icon {
        align-items: center;
        display: flex;
        font-size: 10px;
        height: 24px;
        justify-content: center;
        left: 50%;
        padding-bottom: 1px;
        position: absolute;
        top: 50%;
        width: 24px; }
        @media screen and (min-width: 1200px) {
          .upload-summary .upload-summary-header .collapse-icon-progress .trigger-icon {
            font-size: 16px; } }
        .upload-summary .upload-summary-header .collapse-icon-progress .trigger-icon.no-rotate {
          transform: translate(-50%, -50%) rotate(0deg); }
  .upload-summary.has-cancel .upload-summary-header {
    padding-left: 44px; }
  .upload-summary .collapse-content {
    background-color: #FFF;
    padding: 0; }
  .upload-summary .file-list-container {
    max-height: calc(100vh - 60px);
    overflow: auto;
    padding: 8px 0; }
    @media screen and (min-height: 400px) {
      .upload-summary .file-list-container {
        max-height: 302px; } }
    @media screen and (min-width: 1200px) {
      .upload-summary .file-list-container {
        padding: 10px 0; } }
  .upload-summary .upload-preview-row {
    align-items: center;
    display: flex;
    padding: 8px 16px;
    position: relative; }
    @media screen and (min-width: 1200px) {
      .upload-summary .upload-preview-row {
        padding: 10px 20px; } }
    .upload-summary .upload-preview-row:last-child {
      margin-bottom: 0; }
    .upload-summary .upload-preview-row .file-preview {
      align-items: center;
      background-color: #D3DADE;
      border-radius: 0.25rem;
      border: 0;
      display: flex;
      height: 64px;
      justify-content: center;
      margin: 0 16px 0 0;
      min-width: 64px;
      overflow: hidden;
      width: 64px; }
      .upload-summary .upload-preview-row .file-preview.image-component.blur-fill .background {
        -webkit-filter: brightness(1.2) blur(40px);
                filter: brightness(1.2) blur(40px); }
      .upload-summary .upload-preview-row .file-preview .placeholder-icon {
        font-size: 32px; }
    .upload-summary .upload-preview-row .file-info-container {
      align-items: center;
      display: flex;
      flex: 1 1;
      height: 64px;
      min-width: 1px;
      position: relative; }
    .upload-summary .upload-preview-row .file-info {
      max-width: 100%; }
    .upload-summary .upload-preview-row .file-name {
      margin-bottom: -2px; }
    .upload-summary .upload-preview-row .file-name,
    .upload-summary .upload-preview-row .file-size {
      max-width: 100%;
      overflow: hidden;
      padding-right: 42px;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .upload-summary .upload-preview-row .status-icon {
      font-size: 24px;
      margin-top: -12px;
      position: absolute;
      right: 12px;
      top: 50%; }
      @media screen and (min-width: 1200px) {
        .upload-summary .upload-preview-row .status-icon {
          right: 20px; } }
  .upload-summary .uploading-bar {
    bottom: 2px;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    width: 100%; }

.error-message-page {
  padding: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 90px); }
  @media screen and (min-width: 760px) {
    .error-message-page {
      padding: 10px; } }
  @media screen and (min-width: 1200px) {
    .error-message-page {
      padding: 15px; } }
  .error-message-page .alert {
    align-items: center;
    flex-direction: column; }
  .error-message-page .alert-icon {
    margin: 0 auto; }
  .error-message-page .alert-content {
    margin: 16px auto;
    text-align: center; }
  .error-message-page .btn-retry {
    margin-bottom: 12px;
    opacity: 0.9; }
  .error-message-page a.go-home {
    color: #444647;
    text-decoration: underline; }

.error-404 {
  align-items: center;
  background: #F3F7FC;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 16px;
  width: 100vw;
  max-width: 100%; }
  @media screen and (min-width: 992px) {
    .error-404 {
      background: url(/static/media/404-background.f40388e8.jpg) center no-repeat/cover; } }
  .error-404 .row {
    flex: 1 1; }
    @media screen and (max-width: 760px) {
      .error-404 .row {
        justify-content: center; } }
  .error-404 .message {
    background-color: #FCFBFA;
    border-radius: 0.4rem;
    box-shadow: 0 80px 200px rgba(0, 0, 0, 0.1);
    padding: 32px 48px;
    text-align: center; }
    @media screen and (min-width: 760px) {
      .error-404 .message {
        padding: 40px 60px; } }
    @media screen and (min-width: 1920px) {
      .error-404 .message {
        padding: 60px 100px; } }
    .error-404 .message .header-image {
      margin: 0 auto 40px; }
    .error-404 .message p {
      margin-bottom: 2em; }
    .error-404 .message .btn {
      min-width: 67%; }

