@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.filtered-tabs {
  overflow: auto;
  width: 100%;

  .tabs-wrap {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $border-color;
  }

  .nav-tabs {
    flex-wrap: nowrap;
    border-bottom: none;

    li {
      white-space: nowrap;

      .nav-link {
        background-color: transparent;
        cursor: pointer;
        margin: 0 4px;
        transition: background-color 0.3s, border-color 0.3s;

        @include themify($themes) {
          color: themed('cardText');
        }

        &:hover,
        &:focus,
        &:active {
          background-color: rgba($text-color, 0.05);
          border-color: $border-color-light;
          outline: none;
        }

        &.active,
        &.active:focus,
        &.active:hover {
          background-color: transparent;
          border-color: $border-color-light;

          @include themify($themes) {
            border-bottom-color: themed('cardBackground');
          }
        }
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
    }
  }

  &.bordered-top .nav-tabs li .nav-link {
    border-top: 2px solid transparent;
    border-radius: 0;

    &.active,
    &:focus,
    &:hover {
      border-top: 2px solid $color-accent;
    }
  }

  &.bordered-bottom .nav-tabs li .nav-link {
    border-color: transparent;
    border-left: 0;
    border-right: 0;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    margin: 0;

    &.active {
      &,
      &:focus,
      &:hover {
        border-radius: 0;
        border-color: transparent;
        border-bottom: 3px solid $color-accent;
      }
    }
  }

  &.fieldset {
    .nav-tabs {

      li .nav-link {
        background-color: transparent;
        border-bottom: 3px solid transparent;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        color: $text-color-muted;
        font-size: 16px;
        line-height: (26/20);
        margin: 0;

        @include gutter-sizing('margin-right', 0.5);
        padding: 0 0 4px;
        position: relative;

        @media screen and (min-width: $bp-md) {
          font-size: $h4-font-size;
          line-height: (30/24);
          padding: 0 0 5px;
        }

        &:focus,
        &:hover {
          border-radius: 0;
          @include themify($themes) {
            border-bottom: 3px solid themed('colorBorder');
          }
        }

        &.active,
        &.active:focus,
        &.active:hover {
          @include themify($themes) {
            color: themed('cardText');
            border-bottom: 3px solid themed('colorBorder');
          }
          border-radius: 0;
        }
      }
    }
  }

  &.filtered-tabs-sm {
    .nav-tabs li .nav-link {
      font-size: 0.95rem;
      // stylelint-disable-next-line shorthand-property-no-redundant-values
      padding: 16px 4px 2px 4px;
      margin-right: 8px;

      .chip.bg-light.chip-sm {
        background-color: $background-gray-light-3 !important;
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;

        .chip-name {
          font-size: 11px;
        }
      }
    }
  }
}

.filtered-tabs--mobile {
  display: none;

  select.form-control {
    max-width: 300px;
  }

  @media screen and (max-width: $bp-lg) {
    display: block;
  }
}

.filtered-tabs--desktop {
  @media screen and (max-width: $bp-lg) {
    display: none;
  }
}
