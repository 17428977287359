@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.alert {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 1100;

  &.rc-notification-notice {
    // more specific so we don't need to use !important to overwrite Bootstrap styles
    border-color: rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
    padding: 0.75rem;
  }

  &.fade {
    opacity: 1;
  }

  .alert-content {
    align-self: center;
    word-break: break-word;
  }

  p {
    margin-bottom: 0.3em;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .alert-icon {
    display: flex;
    font-size: rem(28);
    margin: 4px 12px 4px 0;
    opacity: 0.7;
  }

  .rc-notification-notice-close {
    align-items: center;
    align-self: stretch;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 100;
    margin: -12px -12px -12px 12px;
    min-height: 60px;
    justify-content: center;
    justify-self: flex-end;
    line-height: 18px;
    opacity: 1;
    text-shadow: none;
    width: 60px;

    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }

    &:focus,
    &:hover,
    &:hover:focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
      background-color: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
    }
  }
}

// rc-notification

.rc-notification {
  height: 0;
  left: auto !important;
  max-width: calc(100vw - 40px);
  min-width: 250px;
  position: fixed;
  right: 20px;
  top: 20px !important;
  z-index: 1100;

  @media screen and (min-width: $bp-sm) {
    min-width: 300px;
  }
}

.rc-notification-notice-content {
  display: flex;
  flex: 1;
}

.rc-notification-notice-close-x:after {
  content: '×';
}

.rc-notification-fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.rc-notification-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.rc-notification-fade-enter.rc-notification-fade-enter-active {
  animation-name: rcNotificationFadeIn;
  animation-play-state: running;

  &.right-up {
    animation-name: rcNotificationRightFadeIn;
  }

  &.left-up {
    animation-name: rcNotificationLeftFadeIn;
  }
}

.rc-notification-fade-leave.rc-notification-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}

@keyframes rcNotificationFadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rcNotificationLeftFadeIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rcNotificationRightFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rcDialogFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
