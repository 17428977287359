@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.toggle-btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
  margin: 3px 0 3px 4px;
  padding: 4px 0;

  @media screen and (min-width: $bp-md) {
    margin: 0;
  }

  &.disabled {
    cursor: default;
    opacity: $btn-disabled-opacity;

    .toggle-input-btn {
      cursor: default;
    }
  }

  .toggle-input {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  .toggle-input-btn {
    background-color: $gray-400;
    border-radius: 22px;
    border: 0;
    cursor: pointer;
    display: block;
    height: 22px;
    margin-bottom: 0;
    margin-left: 0;
    position: relative;
    text-indent: -9999px;
    width: 42px;

    &:after {
      background-color: #FFF;
      border-radius: 50%;
      box-shadow: 0 1px 6px rgba(153, 153, 153, 0.4);
      content: '';
      height: 20px;
      left: 1px;
      position: absolute;
      top: 1px;
      transition: 0.3s;
      transition: left 0.2s ease-in-out;
      width: 20px;
    }

    &:focus {
      @include focus-shadow($color-accent);
      outline: 0;
    }
  }

  .toggle-input:checked ~ .toggle-input-btn {
    background: $color-accent;

    &:after {
      left: calc(100% - 21px);
      // transform: translateX(-100%);
    }
  }
}

// Probably a good idea to move later but useful for some toggles

.labeled-toggle-container {
  align-items: center;
  display: flex;
  justify-content: center;

  .toggle-label {
    margin: 0 12px 2px 0;
  }
}
