// General Layout Styles

.container__wrap {
  padding-left: 0;
  padding-top: ($topbar-height + 8px);
  padding-bottom: $grid-gutter-width;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
    color: themed('colorText');
  }

  @media screen and (min-width: $bp-sm) {
    padding-left: ($sidebar-expanded-width + ($grid-gutter-width / 2));
    padding-right: ($grid-gutter-width / 2);
  }

  @media screen and (min-width: $bp-md) {
    padding-left: ($sidebar-expanded-width + ($grid-gutter-width-md / 2));
    padding-top: ($topbar-height + 13px);
  }

  @media screen and (min-width: $bp-xl) {
    padding-left: ($sidebar-expanded-width + ($grid-gutter-width-xl / 2));
  }
}

.layout {
  &.layout--collapse {
    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: $bp-sm) {
        padding-left: ($sidebar-collapsed-width + ($grid-gutter-width / 2));
      }

      @media screen and (min-width: $bp-md) {
        padding-left: ($sidebar-collapsed-width + ($grid-gutter-width-md / 2));
      }

      @media screen and (min-width: $bp-xl) {
        padding-left: ($sidebar-collapsed-width + ($grid-gutter-width-xl / 2));
      }
    }
  }

  &.layout--top-navigation {
    & + .container__wrap {
      @media screen and (min-width: $bp-sm) {
        padding-left: 0;
      }
    }
  }
}
