body {
  font-size: 14px;
}

.hr-separator {
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 1;
  padding: 7px 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  color: $text-color-medium;

  &:after {
    border-bottom: 1px solid $hr-border-color;
    content: '';
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
  }

  .label {
    padding: 0 0.75em;
    position: relative;
    z-index: 2;
  }
}

.thumbnail-container {
  position: relative;

  &.blank-slate {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 0.5;
    padding: 4px;
    text-align: center;
  }

  .duration {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    bottom: 2px;
    color: white;
    font-size: 11px;
    left: 2px;
    line-height: 1;
    padding: 2px 6px;
    position: absolute;
  }
}

.modal-image {
  margin: auto;
  max-width: 600px;
}

button.modal-close {
  background: $white;
  border-radius: 50px;
  height: 32px;
  right: 10px;
  position: absolute;
  top: 10px;
  width: 32px;

  span {
    color: $black;
    position: relative;
    top: -2px;
  }
}
