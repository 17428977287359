@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.table-toolbar {
  @media screen and (min-width: $bp-md) {
    align-items: center;
  }

  > [class*='col'] {
    @include gutter-sizing('margin-bottom');
  }

  @media screen and (min-width: $bp-md) {
    .checkbox-dropdown-container {
      max-width: 260px;
    }
  }

  &.button-group {
    .btn {
      @include gutter-sizing(['margin-bottom', 'margin-right'], 0.4);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .table-toolbar-button {
    @media screen and (min-width: $bp-md) {
      margin-left: auto;
    }

    .d-none {
      display: inline-block !important;
    }

    .btn-margin {
      margin: 0 5px;
    }
  }
}
