// Bootstrap declaration overwrites to add Inter variable font support
// Remove rules only if you're removing Inter from those elements,
// eg. if you want to change the button font family.
// Font feature settings sample: https://rsms.me/inter/lab/?feat-tnum=1&feat-frac=1&feat-cv08=1&feat-dlig=1&feat-ss01=1&size=18&letterSpacing=-1&lineHeight=24

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.btn,
body,
.form-control,
.custom-select,
.custom-file-label {
  font-feature-settings: 'tnum', 'ss01', 'cv08', 'calt', 'liga', 'kern';
  letter-spacing: -0.01em;

  @supports (font-variation-settings: normal) {
    font-family: 'Inter var', -apple-system, blinkmacsystemfont, 'Segoe UI',
      roboto, 'Helvetica Neue', arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
}
// END Bootstrap declaration overwrites

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;

  .subhead {
    font-size: 0.8em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0;
}

.typography-card {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }
}

p {
  font-size: $font-size-base;
  line-height: $line-height-base;

  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
    line-height: (20/14);
  }
}

pre {
  margin: 0;

  .react-syntax-highlighter-line-number {
    opacity: 0.5;
  }
}

.highlight {
  background-color: $color-accent;
  color: #FFF;
}

.page-subhead {
  margin: 0 0 20px;

  &.subhead {
    font-size: 14px;
    color: $text-color-muted-on-dark;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}

.page-heading {
  margin-bottom: 16px;

  @media screen and (min-width: $bp-md) {
    margin-bottom: 26px;
  }
}

.section-heading {
  border-bottom: 1px solid $border-color;
  margin: 0 0 20px;
  padding-bottom: 6px;
}
