@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.text-editor-container {
  background-color: white;
  border-radius: $border-radius-sm;
  border: solid 1px $color-border;
  font-size: $input-font-size;
  line-height: $input-btn-line-height;
  margin-bottom: 9px;
  margin-top: 9px;
  position: relative;

  .text-editor-toolbar {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;

    @include themify($themes) {
      border-bottom: solid 1px themed('colorFieldsBorder');
    }

    & > div {
      height: 40px;
      border: none;
      cursor: pointer;
      margin: 0;

      img {
        height: 13px;

        @include themify($themes) {
          fill: themed('cardText');
        }
      }
    }

    .rdw-option-active {
      box-shadow: none;

      @include themify($themes) {
        background-color: themed('colorBorder');
      }
    }
  }

  .text-editor-area {
    border: 0;
    cursor: text;
  }

  .DraftEditor-root {
    width: 100%;
    min-height: 180px;
  }

  .public-DraftEditor-content {
    padding: 6px 10px;

    @include themify($themes) {
      color: themed('cardText');
    }
  }

  .draftJsMentionPlugin__input__1Wxng {
    width: 100%;
    padding: 10px;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: $color-additional;
  }

  .public-DraftStyleDefault-block {
    margin: 0.5em 0;
  }

  .rdw-option-wrapper,
  .rdw-dropdown-wrapper {
    border: none;
    border-radius: 0;
    transition: background 0.3s;

    &:hover {
      box-shadow: none;

      @include themify($themes) {
        background: themed('cardColorHover');
      }
    }

    img {
      @include themify($themes) {
        filter: themed('imgInvert');
      }
    }
  }

  .rdw-option-wrapper[title='Bold'] {
    padding: 7px 5px;

    img {
      height: 12px;
      width: auto;
    }
  }

  .rdw-option-wrapper[title='Italic'] {
    padding: 5px;

    img {
      height: 16px;
      width: auto;
    }
  }

  .rdw-option-wrapper[title='Underline'] {
    padding: 6px 5px 5px;

    img {
      height: 17px;
      width: auto;
    }
  }

  .rdw-dropdown-wrapper {
    @include themify($themes) {
      color: themed('cardText');
    }

    .rdw-dropdown-carettoclose {
      right: 0;

      @include themify($themes) {
        border-bottom-color: themed('colorBorder');
      }
    }

    .rdw-dropdown-carettoopen {
      right: 0;

      @include themify($themes) {
        border-top-color: themed('cardText');
      }
    }
  }

  .rdw-dropdown-optionwrapper {
    border-radius: $border-radius;
    overflow: auto;

    @include themify($themes) {
      background-color: themed('cardBackground');
      border-color: themed('colorBorder');
    }

    &:hover {
      box-shadow: none;
    }
  }

  .rdw-dropdownoption-default {
    transition: background-color 0.3s;

    &:hover {
      @include themify($themes) {
        background-color: themed('cardColorHover');
      }
    }

    &.rdw-dropdownoption-active {
      @include themify($themes) {
        background-color: themed('cardColorHover');
      }
    }
  }

  button {
    &:hover {
      box-shadow: none;

      @include themify($themes) {
        background-color: themed('cardColorHover');
      }
    }

    &[disabled] {
      pointer-events: none;
    }
  }

  .rdw-link-modal {
    height: auto;

    .rdw-link-modal-label,
    .rdw-link-modal-target-option {
      font-size: $input-font-size;
    }

    .rdw-link-modal-input {
      border-color: $border-color;
      border-radius: $border-radius;
      padding: $input-padding-y-sm $input-padding-x-sm;
      font-size: $input-font-size-sm;
      line-height: $input-line-height-sm;
    }

    input[type='checkbox'] {
      display: inline-block;
      width: auto;
    }

    .rdw-link-modal-buttonsection {
      margin: 0 0 ($spacer / 2);
    }

    .rdw-link-modal-btn {
      margin: 0 ($spacer / 2) 0 0;
    }
  }
}
