@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.fieldset {
  margin-bottom: 20px;
  padding: 0;

  .trigger {
    width: 100%;

    .trigger-icon {
      position: absolute;
      right: 0;
      transition: transform $transition-duration ease-in-out;
      transform: translate(-50%, -50%) rotate(0deg);
      top: 50%;
      height: 0.7em;
      width: 0.613em;
    }
  }

  &.opened {
    .trigger-icon {
      transform: translate(-50%, -50%) rotate(180deg);
      transform-origin: center;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .collapsible legend,
  .collapsible .legend {
    padding-right: 40px;

    @media screen and (min-width: $bp-md) {
      padding-right: 60px;
    }
  }

  legend,
  .legend {
    border-bottom: 1px solid $border-color;
    font-size: 20px;
    line-height: (26/20);
    margin: 0 0 20px;
    padding: 0 0 6px;
    position: relative;
    z-index: 2;

    @media screen and (min-width: $bp-md) {
      font-size: $h3-font-size;
      line-height: (30/24);
      padding: 0 0 7px;
    }

    &.legend-sm {
      font-size: $font-size-base;
    }

    .btn {
      margin-top: 4px;
    }

    .tooltip-container {
      display: block;
      flex: 1;
    }

    input,
    input.form-control {
      background: transparent;
      border-radius: 0.1px;
      border: 0;
      font-size: inherit;
      height: auto;
      line-height: inherit;
      padding: 0;

      &.input-icon-left {
        padding-left: 1.25em;
      }

      + .form__form-group-error {
        @include gutter-sizing([ 'left' ], 0.5);
        top: 26px;
      }
    }

    .form__form-group-icon {
      background: transparent;
      border: 0;
      bottom: 6px;
      height: auto;
      padding: 0;
      top: 0;

      @media screen and (min-width: $bp-md) {
        bottom: 7px;
      }
    }
  }

  &.padding-top {
    @include gutter-sizing('padding-top');
  }
}

.tooltip.bs-tooltip-bottom {
  .arrow {
    left: 50% !important;
    margin-left: -7px !important;
  }
}
