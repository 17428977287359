@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.sidebar {
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  height: 100vh;
  left: 0;
  padding-top: 60px;
  position: fixed;
  top: 0;
  transform: translateX(calc(-100% - 20px));
  transition: transform 0.3s, width 0.3s;
  width: $sidebar-expanded-width;
  z-index: 99;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  &.sidebar--show {
    transform: translateX(0);

    .sidebar__back {
      display: block;
    }
  }

  li {
    display: block;
  }
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}

.sidebar__content {
  padding-top: 15px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
}

.sidebar__block {
  padding: 0 0 11px;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

  &:last-child {
    border: none;
  }
}

.sidebar__link {
  height: 56px;
  width: 100%;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  text-decoration: none;
  align-items: center;

  @include themify($themes) {
    color: themed('furnitureTextColor');
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: $cyan;
    opacity: 0;
    transition: all 0.3s;
  }

  p {
    position: absolute;
    left: 48px;
    width: 160px;
    transition: left 0.3s;
    top: 50%;
    transform: translateY(-50%);
  }

  &.active,
  &:hover {
    text-decoration: none;
    background-color: #38373F;

    &:before {
      opacity: 1;
    }
  }
}

.sidebar__link-title {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.007em;
  line-height: 16px;
  position: relative;
  display: flex;
}

.sidebar__link-icon {
  align-self: center;
  color: $cyan;
  font-size: 16px;
  height: 1em;
  line-height: 1;
  margin-right: 15px;
}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 0 0 11px;

  ul {
    padding-left: 0;
  }

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .sidebar__link {
      padding: 11px 20px 11px 53px;

      p {
        left: 53px;
      }
    }
  }

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .sidebar__link {
    display: block;
    height: 32px;
    padding-left: 48px;
  }
}

.sidebar__category-icon {
  position: absolute;
  right: 15px;
  font-size: 10px;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: $color-gray;
}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {
  &.sidebar__category-wrap--open {
    .sidebar__category-icon {
      transform: rotate(180deg);
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: $color-red;
}

@media screen and (min-width: $bp-sm) {
  .sidebar {
    transform: translateX(0);

    &.sidebar--no-desktop {
      transform: translateX(calc(-100% - 20px));

      &.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {
      width: $sidebar-collapsed-width;
      overflow: visible;

      .sidebar__content {
        width: $sidebar-collapsed-width;
        transition: width 0.3s;
        overflow: visible;
      }

      .sidebar__submenu {
        padding: 11px 0;
        transition: 0s;
      }

      .collapse,
      .collapse:not(.show) {
        // always show the dropdown when hovering
        display: block;
        width: 0 !important;
      }

      @media (hover: hover) {
        .sidebar__category-wrap:hover {
          .sidebar__category {
            @include themify($themes) {
              background-color: themed('colorBackground');
            }
          }

          .sidebar__link {
            width: 240px;

            &:hover {
              // stylelint-disable-next-line max-nesting-depth
              @include themify($themes) {
                background-color: themed('topbarBackground');
              }
            }
          }

          &:before {
            opacity: 1;
          }

          .collapse,
          .collapse:not(.show) {
            width: 180px !important;
          }
        }
      }

      .sidebar__submenu-wrap {
        position: absolute;
        left: 100%;
        width: 180px;
        transition: 0.3s;
        overflow: hidden;

        .sidebar__link {
          width: 181px;
          padding-left: 15px;

          p {
            left: 15px;
            animation: none;
          }
        }

        .sidebar__submenu-wrap {
          position: relative;
          left: 0;

          .sidebar__link {
            padding-left: 30px;
          }
        }
      }

      .sidebar__link {
        width: 100%;

        p {
          position: absolute;
          left: 70px;
          width: 160px;
        }

        &:hover {
          @media (hover: hover) {
            width: 240px;
          }
        }
      }

      .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s;
      }
    } // End collapse styles
  }

  .sidebar__wrapper--desktop {
    display: block;
  }

  .sidebar__wrapper--mobile {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}
