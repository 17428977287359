@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.pagination-bottom {
  @include gutter-sizing(['padding-left'], 1);
}

.data-table-pagination {
  // @include gutter-sizing(['padding-left', 'padding-right']);
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  transition: margin-bottom 0.2s ease-in-out;

  & > .col-auto:last-of-type {
    padding: 0;
  }

  .uploader-visible & {
    margin-bottom: 28px;
  }

  > .col-auto {
    @include gutter-sizing('margin-top');
  }

  .page-settings,
  .page-jump-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-jump {
    width: 64px;
    margin-left: ($spacer / 2);
    margin-right: ($spacer / 2);
  }

  .next,
  .previous {
    @media screen and (max-width: $bp-md) {
      order: 2;
    }
  }
}
