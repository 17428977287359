@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.content-container {
  color: $background-gray-dark-2;

  &.fluid {
    max-width: 100%;
  }

  .header-contents {
    justify-content: space-between;
  }
}
