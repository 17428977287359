@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$picker-background: white;
$selected-color: $primary;
$block-background: saturate(mix($primary, $picker-background, 15%), 100%);
$range-background: mix($primary, $picker-background, 70%);
$grid-width: 308px;

.datetime-field {
  .datetime-row {
    margin-left: ($grid-gutter-width / -2);
    margin-right: ($grid-gutter-width / -2);
  }

  .datetime-col {
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }

  .timezone-col {
    @media screen and (max-width: $bp-sm) {
      margin-top: $grid-gutter-width;
    }
  }

  .flatpickr-input {
    padding-right: $input-padding-x; // overwrite :not(last-child) padding from Bootstrap
  }
}

// https://github.com/YouCanBookMe/react-datetime

.rdt {
  position: relative;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 280px;
  padding: 12px;
  margin-top: -2px;
  z-index: 99999 !important;
  background: $white;
  box-shadow: $box-shadow;

  table {
    border-collapse: separate;
    margin: 0;
    width: 100%;
  }

  td,
  th {
    text-align: center;
  }

  .rdtDays {
    td,
    th {
      border-radius: 16px;
      height: 32px;
      width: 32px;
    }
  }

  td {
    cursor: pointer;
  }

  td.rdtDay:hover,
  td.rdtHour:hover,
  td.rdtMinute:hover,
  td.rdtSecond:hover {
    background: $background-gray-light-2;
    cursor: pointer;
  }

  td.rdtOld,
  td.rdtNew {
    color: $text-muted;
  }

  td.rdtToday {
    position: relative;
  }

  td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid $border-color-light;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }

  td.rdtActive,
  td.rdtActive:hover {
    background-color: $cyan;
    color: $white;
  }

  td.rdtActive.rdtToday:before {
    border-bottom-color: $white;
  }

  td.rdtDisabled,
  td.rdtDisabled:hover {
    background: none;
    color: $text-muted;
    cursor: not-allowed;
  }

  td span.rdtOld {
    color: $text-muted;
  }

  td span.rdtDisabled,
  td span.rdtDisabled:hover {
    background: none;
    color: $text-muted;
    cursor: not-allowed;
  }

  .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }

  th.rdtSwitch {
    font-size: 16px;
    line-height: (22/16);
    font-weight: normal;
    letter-spacing: 0.03em;
    width: 100px;
  }

  th.rdtNext,
  th.rdtPrev {
    color: $cyan;
    font-size: 28px;
    line-height: 1;
    margin: 0 4px;
    vertical-align: top;
  }

  th.rdtDisabled,
  th.rdtDisabled:hover {
    background: none;
    color: $text-muted;
    cursor: not-allowed;
  }

  thead tr:first-child th {
    cursor: pointer;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  button:hover {
    background: $background-gray-light-2;
  }

  thead button {
    width: 100%;
    height: 100%;
  }

  tbody tr:last-child {
    padding-bottom: 8px;
  }
}

.rdtTimeToggle,
.rdtSwitch {
  text-align: center;
  height: 32px;
  width: auto;
  color: $link-color;
  padding: 4px 8px;
  position: relative;

  &:hover {
    &:after {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &:after {
    border: 1px solid $cyan;
    border-radius: $border-radius;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

.rdtTimeToggle {
  padding: 10px 8px 6px;

  &:after {
    top: 4px;
  }
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  user-select: none;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
  border-radius: $border-radius;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: $background-gray-light-2;
}

.rdtCounters {
  align-items: center;
  display: flex;
  font-feature-settings: 'tnum';
  justify-content: center;
  padding: 8px 0;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  display: flex;
  flex-direction: column;
}

.rdtCounter .rdtBtn {
  border-radius: 16px;
  color: $cyan;
  cursor: pointer;
  display: block;
  height: 32px;
  line-height: 32px;
  margin: auto;
  user-select: none;
  width: 32px;
  // stylelint-disable-next-line vendor-prefix
  -webkit-touch-callout: none;
}

.rdtCounter .rdtBtn:hover {
  background: $background-gray-light-2;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}
