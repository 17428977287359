@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';
@import '~bootstrap/scss/mixins/screen-reader';

.list-field {
  legend {
    border-top: 0;
    border-bottom: 1px solid white;
    font-size: $input-font-size;
    font-weight: $font-weight-bold;
    padding: 16px 40px 16px 0;

    @media screen and (min-width: $bp-md) {
      font-size: 14px;
    }
  }

  .btn-add-field {
    margin-top: 20px;
  }

  .single-field {
    border-bottom: 1px solid white;
    position: relative;

    .drag-handle {
      color: $text-muted;
    }

    .input-container {
      padding-right: 40px;
    }

    .btn.icon {
      @media screen and (max-width: $bp-md) {
        bottom: 12px;
        margin: 0;
        top: auto;
      }
    }
  }
}

.dnd {
  .list-group-item {
    border-radius: $border-radius;
    box-shadow: 0 0 0 0.2rem rgba($background-gray-light-1, 0);
    font-size: 12px;
    line-height: (18/12);
    margin-bottom: 0.25rem;
    padding-right: 36px;
    position: relative;

    &.active {
      border-color: $color-accent;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }

    .icon {
      margin-right: 0.5em;

      &.drag-handle {
        color: $text-muted;
      }
    }

    .remove-btn {
      border: 0;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;

      .mdi-icon {
        margin: 0;
      }
    }
  }
}
