@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.page-title {
  font-weight: 500;
  font-size: (30 / $rem-value * 1rem);
  letter-spacing: 0.01em;
  line-height: (32/30);
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: $bp-md) {
    line-height: (38/30);
  }
}
