@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.chip {
  align-items: center;
  border-radius: $border-radius;
  display: inline-flex;
  justify-content: center;
  margin-right: 6px;

  .form-control &:first-child {
    margin-left: -6px;
  }

  .form-control &:last-child {
    margin-right: -6px;
  }

  .chip-name {
    font-size: 13px;
    letter-spacing: 0;
    line-height: (14/13);
    padding: 3px 6px;
  }

  .btn-icon {
    background-color: rgba($text-color, 0.2);
    border-bottom-right-radius: 0.1px;
    border-top-right-radius: 0.1px;
    color: $white;
    font-size: 14px;
    height: 20px;
    min-width: 0;
    padding: 0;
    width: 20px;

    &:hover,
    &:active {
      background-color: rgba($text-color, 0.3);
      color: $white;
    }
  }
}
